import React from "react";
import { Outlet } from "react-router-dom";
import UserValidation from "../components/auth/user-validation";

/** 最上層頁面 */
export default function Root() {

  return (
    <UserValidation>
      {/* <div id="header">
        <NavBar />
      </div> */}
      {/* <div>root </div> */}
      <div id="detail">
        <Outlet /> {/* child router 內容顯示處 */}
      </div>
    </UserValidation>
  );
}
