import { InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Select,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useUserContext } from "../../components/auth/user-validation";
import { Outlet } from "react-router-dom";

import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const StudentPage = () => {
  const { userInfo } = useUserContext(); // 取得登入者身份資料
  const { i18n } = useTranslation("translation");

  useEffect(() => {
    if (userInfo) {
      const lang = userInfo.language?.split("-")[0] === "en" ? "en" : "zh-TW";
      console.log(`lng: ${lang}`);
      if (i18n.language !== lang) {
        i18n.changeLanguage(lang);
      }
    }
  }, [userInfo]);

  // const { userInfo, currentSemester } = useUserContext(); // 取得登入者身份資料

  // console.log({ currentSemester });

  return (
    <>
      <div className="bg-[#d2e5f4]">
        <div className="App max-w-md bg-slate-100 mx-auto">
          <Outlet />
        </div>
      </div>
    </>
  );
};
