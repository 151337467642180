import { Box, Button, Checkbox, Divider, Icon, Input, InputGroup, InputRightElement, Link, ListItem, Radio, RadioGroup, Stack, Text, UnorderedList, useColorModeValue, useDisclosure } from "@chakra-ui/react"
import { SchoolOtherSettingsHelper } from "../../dal/school_other_settings_helper";
import { useEffect, useState } from "react";
import { Util } from "../../dal/util";
import dayjs from 'dayjs';
import { useUserContext } from "../../components/auth/user-validation";
import { NotifyBoxModal } from "../../components/Modals/NotifyBoxModal";
import config  from '../../config';
import { FileStorageHelper } from "../../dal/file_storage_helper";
import { FullNotifyBoxModal } from "../../components/Modals/FullNotifyBoxModal";
import { useSchoolContext } from "../../dal/SchoolContext";

type SchoolSettingsState = {  
  need_parent_approval: boolean;
  absence_name: string;
  public_leave_name: string;
  last_update: string;
  date_range: number | null; // Allow date_range to be number or null
  leave_rule: string;
  id: string;
};


export const OtherPage = () => {
  const { isOpen: isNotifyOpen, onOpen: onNotifyOpen, onClose: onNotifyClose } = useDisclosure(); //提示訊息視窗控制
  const { isOpen: isFullNotifyOpen, onOpen: onFullNotifyOpen, onClose: onFullNotifyClose } = useDisclosure(); //提示訊息視窗控制(含取消按鈕)
  const [cancel, setCancel] = useState(false); //是否取消按鈕

  const [notifyMessage, setNotifyMessage] = useState(''); //提示訊息內容
  const { userInfo } = useUserContext();
  const { selectedSchool } = useSchoolContext(); // 學校資訊

  const color = useColorModeValue('gray.500', 'gray.400');
  const [dateRangeMode, setDateRangeMode] = useState('1')
  const [ruleName, setRuleName] = useState('');

  const [refresh, setRefresh] = useState(false); // 重新載入頁面用  

  const [selectedFile, setSelectedFile] = useState<File | null>(null); // 暫存使用者選擇的附件

  const [settings, setSettings] = useState<SchoolSettingsState>({
    need_parent_approval: true,
    absence_name: '',
    public_leave_name: '',
    last_update: '',
    date_range: null || -1,
    leave_rule: '',
    id: ''
  });

  const handleRadioChange = (value: string) => {
    if(value === '1')
    {
      setSettings(prev => ({ ...prev, need_parent_approval: true }));
    }
    else if(value === '2')
    {
      setSettings(prev => ({ ...prev, need_parent_approval: false }));
    }
    //setParentApproval(value);
  }

  const handleDateRangeMode = (value:string) => {
    if(value === '1')
    {
      setDateRangeMode('1');
      setSettings(prev => ({ ...prev, date_range: null }));
    }
    else if(value === '2')
    {
      setDateRangeMode('2');
      setSettings(prev => ({ ...prev, date_range: -1 }));
    }
    else if (value === '3')
      setDateRangeMode('3');          
  }

  const handleAttachFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const loadData = async () => {
    const currDsns = selectedSchool?.dsns || ''; // Set currDsns to an empty string if it is undefined
    const SchoolOtherSetting = await SchoolOtherSettingsHelper.getSchoolOtherSettingsByDSNS(currDsns);
    if(Object.keys(SchoolOtherSetting).length !== 0)
    {
      setSettings(prev => ({
        ...prev,
        need_parent_approval: SchoolOtherSetting.need_parent_approval === true,
        absence_name: SchoolOtherSetting.absence_name || '',
        public_leave_name: SchoolOtherSetting.public_leave_name || '',
        last_update: dayjs(SchoolOtherSetting.last_update).isValid() ? dayjs(SchoolOtherSetting.last_update).format('YYYY/MM/DD HH:mm:ss') : '',
        date_range: SchoolOtherSetting.date_range === null ? null : SchoolOtherSetting.date_range,
        leave_rule: SchoolOtherSetting.leave_rule || '',
        id: SchoolOtherSetting.id
      }));

      if(SchoolOtherSetting.leave_rule !== null)
      {
        const ruleName=await FileStorageHelper.getFileMetadata(SchoolOtherSetting.leave_rule);
        setRuleName(ruleName?.origin_name);
      }

      setDateRangeMode(SchoolOtherSetting.date_range === null ? '1' : SchoolOtherSetting.date_range === -1 ? '2' : '3');
      setSelectedFile(null);
    }
    else
    {
      setSettings(prev => ({
        ...prev,
        need_parent_approval: true,
        absence_name: '',
        public_leave_name: '',
        last_update: '',
        date_range: null,
        leave_rule: '',
        id: ''
      }));
      setDateRangeMode('1');
      setSelectedFile(null);
      setRuleName('');
    }
  }

  const handelFullNotifyCancel = () => {
    onFullNotifyClose();
  }

  const handleFullNotifyClose = () => {    
    onFullNotifyClose();
    setRuleName('');
    commitChanges();
  }

  useEffect(() => {
    if(refresh)
    {
      setCancel(false);
      setRefresh(false);
      loadData();
    }
  }, [refresh]);

  useEffect(() => {
    loadData();
  }, []);

  const checkPermission = () => {
    /* if(userInfo?.isSystemAdmin)
     {
       if((selectedSchool?.dsns===userInfo?.schoolDsns) && userInfo?.isAdmin)
       {
         return true;
       }
       else
       {
         return false;
       }
     }
     else
     {     
       return false;
     }*/
     if((selectedSchool?.dsns===userInfo?.schoolDsns) && userInfo?.isAdmin)
       {
         return true;
       }
       else
       {
         return false;
       }
 
   }
  useEffect(() => {
    loadData();    
  },[selectedSchool]);

  const commitChanges = async () => {
        //上傳附件檔產生附件資訊 
        let attInfo='';
        if(selectedFile !== null)
        {
          const fileInfo = await Util.uploadFile(selectedFile);
          console.log('fileInfo',fileInfo);
          attInfo =(fileInfo) ? config.storageURL +'/' +fileInfo?.fileid: '';
        }
        else
        {
          attInfo = settings.leave_rule;
        }
        if(settings.id==='') //新增
        {
          const result = await SchoolOtherSettingsHelper.addAllSettings(
            settings.need_parent_approval.toString(),
            settings.date_range,
            attInfo,      
            userInfo?.account,
            settings.absence_name,
            settings.public_leave_name
          );
        }
        else
        {
          const result = await SchoolOtherSettingsHelper.updateAllSettings(
            settings.need_parent_approval.toString(),
            settings.date_range,
            attInfo,      
            userInfo?.account,
            settings.absence_name,
            settings.public_leave_name
          );
        }
        setNotifyMessage('儲存成功');
        setSelectedFile(null);
        onNotifyOpen();
        setRefresh(true);
  }

  async function handleButtonClick(): Promise<void> {

    /** 檢查資料輸入 */
    if(dateRangeMode==='3' && (settings.date_range === null || settings.date_range === -1))
    {
      setNotifyMessage('請輸入限制天數');
      onNotifyOpen();
      return;
    }

    if(selectedFile !== null && ruleName !== '')
    {
      setNotifyMessage('將覆蓋原有規章');
      onFullNotifyOpen();
      return;
    }

    commitChanges();

   // console.log("儲存結果:" + result);
  }

  return (
    <>
      <Box borderWidth='1px' borderRadius='lg' p="6" h='87vh' overflow='hidden'>
        <div className="h-full overflow-y-auto w-[800px]">
          <div className="text-xl font-semibold align-middle">上傳請假規章
            {(settings.leave_rule !=='') && checkPermission() &&
            <Link href={settings.leave_rule} isExternal>
              <Icon w={8} h={8} ml={2} mb={2} viewBox='0 0 64 64'>
                <g><path d="m26.198 30.943 3.596 1.683a1 1 0 0 0 .848 0l3.595-1.683a6.943 6.943 0 0 0 4.287-6.144v-7.616a1 1 0 0 0-.995-1 11.262 11.262 0 0 1-5.985-1.712c-.514-.314-1.248-.979-1.87-.506a11.564 11.564 0 0 1-6.768 2.218 1 1 0 0 0-.995 1v7.616a6.943 6.943 0 0 0 4.287 6.144zm-2.287-12.802a13.127 13.127 0 0 0 6.307-2.146l.241.156a13.14 13.14 0 0 0 6.065 1.99V24.8a4.975 4.975 0 0 1-3.134 4.333l-3.172 1.485-3.172-1.485a4.975 4.975 0 0 1-3.135-4.333z" fill="#00b5d8" opacity="1" data-original="#000000"></path><path d="M26.757 26.668a1.007 1.007 0 0 0 1.633.374l6.342-6.118a1 1 0 0 0-1.388-1.44l-5.24 5.055-.767-2.08a1 1 0 0 0-1.877.692z" fill="#00b5d8" opacity="1" data-original="#000000"></path><path d="M53.265 7.264H18.174a7.047 7.047 0 0 0-7.04 7.04v32.458H5a1 1 0 0 0-1 1 8.984 8.984 0 0 0 8.974 8.974h28.352a7.21 7.21 0 0 0 7.202-7.202v-30.71H59a1 1 0 0 0 1-1V14a6.743 6.743 0 0 0-6.735-6.736zM12.974 54.736a6.985 6.985 0 0 1-6.903-5.974h28.053a7.475 7.475 0 0 0 2.227 5.974zm33.554-5.202c-.216 6.867-10.183 6.88-10.404 0v-1.772a1 1 0 0 0-1-1h-21.99V14.303a5.045 5.045 0 0 1 5.04-5.039H48.48A6.713 6.713 0 0 0 46.528 14v35.534zM58 16.824h-9.472V14A4.736 4.736 0 1 1 58 14z" fill="#00b5d8" opacity="1" data-original="#000000"></path><path d="M40.534 36.248H19.901a1 1 0 0 0 0 2h20.633a1 1 0 0 0 0-2zM40.534 40.554H19.901a1 1 0 0 0 0 2h20.633a1 1 0 0 0 0-2z" fill="#00b5d8" opacity="1" data-original="#000000"></path></g>
              </Icon>
            </Link>
            }
            <span className="text-l text-gray-500 ms-2">{ruleName}</span>            
          </div>
          <div className="flex items-center mt-4">
            <InputGroup className='!grid grid-cols-1 !w-[406px] pr-2 ms-4'>
              <Input isDisabled={!checkPermission()} type="file" placeholder='' className='w-auto pt-[6px] z-10' accept=".pdf" onChange={handleAttachFileChange} />
              <InputRightElement className='!right-4'>
                <Icon w={6} h={6} color='gray.500' viewBox='0 0 24 24'>
                  <path fill="currentColor" d="M11 16V7.85l-2.6 2.6L7 9l5-5l5 5l-1.4 1.45l-2.6-2.6V16zm-5 4q-.825 0-1.412-.587T4 18v-3h2v3h12v-3h2v3q0 .825-.587 1.413T18 20z" />
                </Icon>
              </InputRightElement>
            </InputGroup>
            <Text color='gray.500' className="text-sm">※ 限 pdf 檔</Text>
          </div>

          <div className="text-xl font-semibold mt-8">是否開放家長請假</div>
          <RadioGroup onChange={handleRadioChange} value={(settings.need_parent_approval)? '1':'2'}>
            <Stack spacing={5} direction='row' m={4} mb={3}>
              <Radio isDisabled={!checkPermission()} colorScheme='red' value='1' >是，開放家長請假</Radio>
              <Radio isDisabled={!checkPermission()} colorScheme='cyan' value='2'>否，由學生請假即可</Radio>
            </Stack>
          </RadioGroup>
          <Text color='gray.500' className="text-sm ms-4">※ 開放家長請假，假單可由學生或家長發起，但必須要由家長進行簽章，才能送出假單。</Text>

          <div className="text-xl font-semibold mt-8">設定開放曠課請假區間</div>
          <RadioGroup onChange={handleDateRangeMode} value={dateRangeMode}>
            <Stack spacing={5} direction='row' m={4} mb={6}>
              <Radio isDisabled={!checkPermission()} colorScheme='red' value='1'>不限制</Radio>
              <Radio isDisabled={!checkPermission()} colorScheme='cyan' value='2'>限制當學期內請假</Radio>
              <Radio isDisabled={!checkPermission()} colorScheme='cyan' value='3'>限制曠課日起</Radio>

              <div className="flex items-center -m-4">
              <Input
                type="number"
                value={(settings.date_range === null || settings.date_range === -1) ? '' : settings.date_range.toString()}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setSettings(prev => ({
                    ...prev,
                    date_range: newValue === '' ? null : Number(newValue)
                  }));
                }}
                isDisabled={!(dateRangeMode==='3') || !checkPermission()} // Enabled based on settings state
                className="!border-x-0 !border-t-0 !rounded-none !border-[#ffb0bf] !w-[60px] text-center !px-0"
              />
              <span>天內請假</span>
              </div>
            </Stack>
          </RadioGroup>

          <div className="text-xl font-semibold mt-8">缺曠類別對照</div>
          <UnorderedList spacing={2} pt={2} ps={5} mb={6}>
            <ListItem>曠課對照校務系統內名稱:
              <Input isDisabled={!checkPermission()} type="text" value={settings.absence_name} 
                onChange={(e)=>setSettings(
                prev => ({ ...prev, absence_name: e.target.value 
              })
              )} className="!border-x-0 !border-t-0 !rounded-none !border-[#ffb0bf] !w-[550px] text-left" />
            </ListItem>
            <div>※ 多個曠課類別，請用半形逗號(,)隔開</div>
        {/*    <ListItem>公假對照校務系統內名稱
              <Input type="text" value={settings.public_leave_name} onChange={
                (e)=>setSettings(
                  prev => ({ ...prev, public_leave_name: e.target.value 
                })
              )} 
              className="!border-x-0 !border-t-0 !rounded-none !border-[#ffb0bf] !w-[200px] text-center" />            
            </ListItem>*/}
          </UnorderedList>

          <Divider mt={12} />
          <div className="flex justify-between items-center mt-12">
            <div>最近一次修改時間：{settings.last_update}</div>
            {checkPermission() &&
            <Button variant='outline' colorScheme='cyan' onClick={() => handleButtonClick()}>儲存</Button>}
          </div>
        </div>
      </Box>
      <NotifyBoxModal isModalOpen={isNotifyOpen} title='資料提示' message={notifyMessage} onModalClose={onNotifyClose}/>
      <FullNotifyBoxModal isModalOpen={isFullNotifyOpen} title='資料提示' message={notifyMessage} onClose={handleFullNotifyClose} onCancel={handelFullNotifyCancel}/>
    </>
  )
}
