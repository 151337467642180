import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useBreakpointValue,
   } from "@chakra-ui/react";
   
   interface Props {
    isModalOpen: boolean;
    onModalClose: () => void;
    pdfUrl: string;
    title: string;
   }
   
   export const PdfModalHtml = ({ isModalOpen, onModalClose, pdfUrl, title }: Props) => {
    // Dynamically adjust the modal size based on the viewport width
    const modalSize = useBreakpointValue({ base: "full", sm: "lg", md: "xl", lg: "2xl" });
   
    // Dynamically adjust the PDF viewer height based on the viewport
    const pdfViewerHeight = useBreakpointValue({ base: "50vh", md: "50vh", lg: "65vh" });
   
    return (
     <Modal size={modalSize} closeOnOverlayClick={false} isOpen={isModalOpen} onClose={onModalClose}>
      <ModalOverlay />
      <ModalContent>
       <ModalHeader>{title}</ModalHeader>
       <ModalCloseButton />
   
       <ModalBody>
        {/* The PDF viewer width is set to 100% of the ModalBody, and its height adjusts dynamically */}
        <iframe
            src={pdfUrl}
            title={title}
            width="100%"
            style={{ height: pdfViewerHeight, minHeight: '300px'}}
          ></iframe>
       </ModalBody>
   
       <ModalFooter />
      </ModalContent>
     </Modal>
    );
   };