export class SchoolAdminHelper {

    /** 取得所有管理者 */
    static async getAllSchoolAdmin(dsns:string): Promise<any[]> {
        const resp = await fetch(`/service/school_admin/get_school_admin?schooldsns=${dsns}`);
        return resp.json();
    }
    /** 新增學校管理者 */
    static async addSchoolAdmin(account:string,name:string): Promise<any> {
        const resp = await fetch(`/service/school_admin/add_school_admin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ account,name,role_type:"admin" }),
        });
        return resp.json();
    }
    /** 刪除請假流程 */
    static async deleteSchoolAdmin(id:string): Promise<any> {
        const resp = await fetch(`/service/school_admin/delete_school_admin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id }),
        });
        return resp.json();
    }

}