import { Link } from "react-router-dom";

export default function NotSigninPage() {
  
  return (
    <div id="error-page" className="p-8 ">
      <h1>Oops!</h1>
      <p>您尚未登入.</p>
      <p>
        請前往 <Link to="https://1campus.net"> 1campus.net </Link> 登入。
      </p>
    </div>
  );
}