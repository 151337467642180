import { Link } from "react-router-dom";
import { useUserContext } from "../components/auth/user-validation";
import { useEffect } from "react";
import { DSAAbsenceHelper } from "../dal/dsa_absence_helper";
import { AbsenceTypeHelper } from "../dal/absence_type_helper";
import { SchoolPeriodHelper } from "../dal/school_period_helper";


export const IndexMenu = () => {

  const { userInfo } = useUserContext();

  useEffect(() => {
    getAbsenceTypes();
    getPeriods();
    getAbsenceSemesters();
    getAbsenceRecords();
    getHomeroomTeacher();
    getAllStudents();
    
    getAllowedAbsenceTypes();
    getAllowedPeriods();
  }, [])

  const getAbsenceTypes = async () => {
    const absTypes = await DSAAbsenceHelper.getAbsenceTypes();
    console.log( { absTypes });
  }

  const getPeriods = async () => {
    const periods = await DSAAbsenceHelper.getPeriods();
    console.log( { periods });
  }

  const getAbsenceSemesters = async () => {
    const semesters = await DSAAbsenceHelper.getAbsenceSemesters();
    console.log( { semesters });
  }

  const getAbsenceRecords = async () => {
    const abs_records = await DSAAbsenceHelper.getAbsenceRecords(112, 1);
    console.log( { abs_records });
  }

  const getHomeroomTeacher = async () => {
    const hrt = await DSAAbsenceHelper.getHomeroomTeacher();
    console.log( { hrt });
  }

  const getAllowedAbsenceTypes = async() => {
    const allowedAbsenceTypes = await AbsenceTypeHelper.getAllowedAbsenceTypes();
    console.log( { allowedAbsenceTypes });
  }
  const getAllowedPeriods = async() => {
    const allowedPeriods = await SchoolPeriodHelper.getAllowedPeriods();
    console.log( { allowedPeriods });
  }

  const getAllStudents = async() => {
    const students = await DSAAbsenceHelper.getSchoolStudents();
    console.log( { students });
  }

  return (
    <div className="flex flex-col items-center gap-4 mt-5">
      <div>Hi { userInfo?.name } ( {userInfo?.account},  {userInfo?.roleType})</div>
      <div className="text-lg">線上請假</div>
      <Link to="/student">家長/學生</Link>
      <Link to="/teacher">審核畫面</Link>
      <Link to="/manager">管理者</Link>
    </div>
  );
}