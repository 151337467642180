import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  Box, Flex, Input, List, ListItem, Table, Tbody, Tr, Td, TableContainer,
  Button, useColorModeValue, useDisclosure, Text
} from '@chakra-ui/react';
import { EditIcon, SearchIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { SchoolAdminHelper } from "../../dal/school_admin_helper";
import { DSAAbsenceHelper } from "../../dal/dsa_absence_helper";
import { NotifyBoxModal } from "../../components/Modals/NotifyBoxModal";
import { useSchoolContext } from "../../dal/SchoolContext";
import { useUserContext } from "../../components/auth/user-validation";

interface TeacherData {
  teacherName: string;
  teacherAcc: string;
}

interface SelectedAccount {
  account: string;
  name: string;
}

export const SetAdminPage: React.FC = () => {
  const color = useColorModeValue('gray.100', 'gray.700');
  const { isOpen: isNotifyOpen, onOpen: onNotifyOpen, onClose: onNotifyClose } = useDisclosure();

  const [searchTerm, setSearchTerm] = useState(''); //搜尋字串
  const [teachers, setTeachers] = useState<TeacherData[]>([]); //所有老師帳號
  const [selectedAccount, setSelectedAccount] = useState<SelectedAccount | null>(null); //選擇的審核帳號
  const [schoolAdmins, setSchoolAdmins] = useState<any[]>([]); //所有管理者帳號
  const [refresh, setRefresh] = useState(false); //重新載入
  const [notifyMessage, setNotifyMessage] = useState(''); //提示訊息內容
  const [isTeacherListOpen, setIsTeacherListOpen] = useState(false); //是否顯示老師列表

  const { userInfo } = useUserContext(); // 使用者資訊
  const { selectedSchool } = useSchoolContext(); // 學校資訊

  const inputRef = useRef<HTMLInputElement>(null);
  const listRef = useRef<HTMLUListElement>(null);

  const filteredTeachers = useMemo(() => {
    if (!searchTerm.trim()) {
      return teachers;
    }
    return teachers.filter((teacher) => {
      const searchString = `${teacher.teacherName}-${teacher.teacherAcc}`.toLowerCase();
      return searchString.includes(searchTerm.toLowerCase());
    });
  }, [teachers, searchTerm]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setSelectedAccount(null);
    setIsTeacherListOpen(true);
  };

  const handleSelectTeacher = async (teacher: TeacherData) => {
    const newSelectedAccount = { name: teacher.teacherName, account: teacher.teacherAcc };
    setSelectedAccount(newSelectedAccount);
    setSearchTerm(`${teacher.teacherName}-${teacher.teacherAcc}`);
    setIsTeacherListOpen(false);
    
    const existingAdmin = schoolAdmins.find(item => item.account === newSelectedAccount.account);
    if (existingAdmin) {
      setNotifyMessage('此帳號已是管理帳號');
      onNotifyOpen();
    } else {
      await SchoolAdminHelper.addSchoolAdmin(newSelectedAccount.account, newSelectedAccount.name);
      setNotifyMessage('帳號新增完畢');
      onNotifyOpen();
      setRefresh(true);
    }
    
    setSearchTerm('');
    setSelectedAccount(null);
  };

  const handleDeleteAccount = (id: string) => {
    SchoolAdminHelper.deleteSchoolAdmin(id);
    setNotifyMessage('帳號刪除完畢');
    onNotifyOpen();
    setRefresh(true);
  };

  const loadData = async () => {
    const allTeacher = await DSAAbsenceHelper.getSchoolTeachers(selectedSchool?.dsns ?? '');
    if (allTeacher !== undefined) {
      setTeachers(allTeacher.teacher.filter((item: TeacherData) => item.teacherAcc !== null));
      const schoolAdmins = await SchoolAdminHelper.getAllSchoolAdmin(selectedSchool?.dsns ?? '');
      setSchoolAdmins(schoolAdmins);
    } else {
      setTeachers([]);
      setSchoolAdmins([]);
    }
  };

  useEffect(() => {
    if (refresh) {
      loadData();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    loadData();
    const handleClickOutside = (event: MouseEvent) => {
      if (inputRef.current && !inputRef.current.contains(event.target as Node) &&
          listRef.current && !listRef.current.contains(event.target as Node)) {
        setIsTeacherListOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [selectedSchool]);

  const checkPermission = () => {
    return (selectedSchool?.dsns === userInfo?.schoolDsns) && userInfo?.isAdmin;
  };

  return (
    <>
      <Box borderWidth='1px' borderRadius='lg' p="6" h='87vh' overflow='hidden'>
        <div className="h-full overflow-y-auto">
          <div className="text-xl mb-4">管理者設定</div>
          <Box position="relative" width="100%" mb={4}>
            <Flex alignItems="center" position="relative">
              <Input
                ref={inputRef}
                placeholder="輸入教師姓名或帳號搜尋..."
                value={searchTerm}
                onChange={handleInputChange}
                onFocus={() => setIsTeacherListOpen(true)}
                pr="60px"
                isDisabled={!checkPermission()}
              />
              <Box position="absolute" right="35px" pointerEvents="none">
                <SearchIcon />
              </Box>
              <Box position="absolute" right="10px" pointerEvents="none">
                <ChevronDownIcon />
              </Box>
            </Flex>
            {isTeacherListOpen && (
              <List
                ref={listRef}
                position="absolute"
                top="100%"
                left="0"
                right="0"
                maxH="300px"
                overflowY="auto"
                bg="white"
                boxShadow="md"
                borderRadius="md"
                zIndex={1}
              >
                {filteredTeachers.map((teacher, index) => (
                  <ListItem
                    key={index}
                    p={1}
                    cursor="pointer"
                    _hover={{ bg: 'gray.100' }}
                    onClick={() => handleSelectTeacher(teacher)}
                  >
                    <Text>{teacher.teacherName}-{teacher.teacherAcc}</Text>
                  </ListItem>
                ))}
              </List>
            )}
          </Box>
          <TableContainer>
            <Table size='lg'>
              <Tbody className="procTb">
                <Tr borderTopColor={color} className="border-t">
                  <Td>編號</Td>
                  <Td className="border-l">帳號</Td>
                  <Td className="border-l">姓名</Td>
                  <Td className="border-l"></Td>
                </Tr>
                {schoolAdmins.map((item, index) => (
                  <Tr key={index}>
                    <Td>{index + 1}</Td>
                    <Td>{item.account}</Td>
                    <Td>{item.name}</Td>
                    <Td>
                      <Button
                        isDisabled={!checkPermission()}
                        rightIcon={<EditIcon color='gray.500' />}
                        className="border-b !rounded-none border-gray-500 w-[120px]"
                        variant='flushed'
                        onClick={() => handleDeleteAccount(item.id)}
                      >
                        <span className="mx-auto">刪除</span>
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </div>
      </Box>
      <NotifyBoxModal isModalOpen={isNotifyOpen} title='資料輸入提示' message={notifyMessage} onModalClose={onNotifyClose} />
    </>
  );
};