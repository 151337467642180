import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useBreakpointValue,
  Box,
} from '@chakra-ui/react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface Props {
  isModalOpen: boolean;
  onModalClose: () => void;
  pdfUrl: string;
  title: string;
}

export const PdfModal = ({ isModalOpen, onModalClose, pdfUrl, title }: Props) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [scale, setScale] = useState<number>(1);

  const modalSize = useBreakpointValue({ base: 'full', sm: 'lg', md: 'xl', lg: '2xl' });

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    calculateScale();
  };

  // Dynamically calculate the scale based on the modal size
  const calculateScale = () => {
    // Timeout to ensure the modal has rendered and we have the correct dimensions
    setTimeout(() => {
      const modalContentElement = document.querySelector('.pdfModalContent');
      if (modalContentElement) {
        const containerWidth = modalContentElement.clientWidth - 10; // Subtracting modal paddings
        const pageWidth = 600; // A4 width at 72 DPI
        const newScale = containerWidth / pageWidth;
        setScale(newScale);
      }
    }, 100);
  };

  useEffect(() => {
    window.addEventListener('resize', calculateScale);
    return () => {
      window.removeEventListener('resize', calculateScale);
    };
  }, []);

  return (
    <Modal size={modalSize} isOpen={isModalOpen} onClose={onModalClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent className="pdfModalContent">
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Box overflow="auto" maxHeight="75vh">
            <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={scale} />
              ))}
            </Document>
          </Box>
        </ModalBody>

        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};
