import React, { useEffect, useState } from "react";
import {
  Stack,
  Switch,
  Tag,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

/** 
 * 處理預先請假畫面中某一日期的節次選擇的元件
 * */
export default function LeaveDatePeriod({
  targetDate,   // 指定要請假的日期。
  allowPeriods, // 學校定義當下學期所定義的可請的假別。
  onSelected,   // 當選擇/取消節次時都會觸發，回傳日期與所有被選擇的節次
}: {
  targetDate: string;
  allowPeriods: any[];
  onSelected: (dt: string, periods: string[]) => void;
}) {
  const { t, i18n } = useTranslation("translation");
  
  // 各節次被選取狀況，使用 Dictionary 來儲存
  const [dicPeriods, setDicPeriods] = useState<{[id: string]: boolean}>({});

  // 處理全選按鈕的事件
  const handleSelectAll = (checked: boolean) => {
    const tempPeriods: { [id: string]: boolean } = { ...dicPeriods };
    allowPeriods.forEach((p) => {
      tempPeriods[p] = checked;
    });
    setDicPeriods(tempPeriods);
    notifyParent(tempPeriods);
  };

  // 處理選擇單一節次的事件
  const handleSelectPeriod = (p: string, checked:boolean) => {
    const tempPeriods: { [id: string]: boolean } = { ...dicPeriods };
    tempPeriods[p] = checked;
    setDicPeriods(tempPeriods);
    notifyParent(tempPeriods);
  }

  // 取得目前被選取的節次
  const getSelectedPeriods = (tempDicPeriods: {[id: string]: boolean}): string[] => {
    const result: string[] = [];
    allowPeriods.forEach((p) => {
      if (tempDicPeriods[p]) {
        result.push(p);
      }
    });
    return result ;
  }

  // 通知父親目前被選取的狀況
  const notifyParent = (tempDicPeriods: {[id: string]: boolean}) => {
    onSelected && onSelected( targetDate, getSelectedPeriods(tempDicPeriods));
  }

  // 當學校定義的可選取節次清單改變時，初始化各節次與被選取狀況
  useEffect(() => {
    const periods: { [id: string]: boolean } = {};
    allowPeriods.forEach((p) => {
      periods[p] = false;
    });
    setDicPeriods(periods);
  }, [allowPeriods]);

  return (
    <>
      <div className="!mt-4 mb-3">
        <Tag size="md" colorScheme="red" className="justify-center w-24 !mt-0">
          {targetDate}
        </Tag>
        { (getSelectedPeriods(dicPeriods).length < 1) ? (
            // 提醒尚未選取任何節次
            <span className="text-red-500 text-sm ms-4">{t("app.sessions_not_selected")}</span>
        ) : (
            // 顯示已選取節數
            <span className="text-red-500 text-sm ms-4">{i18n.language === "en" ? "" : t("app.selected")} {getSelectedPeriods(dicPeriods).length} {i18n.language === "en" ? `${t("app.session")} ${t("app.selected")}` : t("app.session")}</span>
        )
    }
      </div>
      <div className="!mt-2">
        <Stack align="center" direction="row" className="flex-wrap session">
            {/* 全選按鈕 */}
          <Switch
            size="lg"
            colorScheme="twitter"
            content="全選"
            className="switch-txt"
            onChange={(e) => handleSelectAll(e.target.checked)}
          />
            {/* 各節次按鈕 */}
          {allowPeriods.map((ap) => (
            <Switch
              key={ap}
              size="lg"
              colorScheme="twitter"
              content={ap}
              isChecked={ dicPeriods[ap]}
              className="switch-txt"
              onChange={ (e) => handleSelectPeriod(ap, e.target.checked )}
            />
          ))}
         
        </Stack>
      </div>
    </>
  );
}
