import { TableContainer, Table, Thead, Tr, Th, Tbody, Td, Tfoot, Box, InputGroup, InputRightElement, Badge, Center, Button, Select, InputLeftElement, Input, Checkbox, Stack, useDisclosure } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { SchoolOtherSettingsHelper } from "../../dal/school_other_settings_helper";
import { SchoolPeriodConfig } from "../../dal/value_object";
import dayjs from 'dayjs';
import { DSAAbsenceHelper } from "../../dal/dsa_absence_helper";
import { useUserContext } from '../../components/auth/user-validation';
import { set } from "date-fns";
import { NotifyBoxModal } from "../../components/Modals/NotifyBoxModal";
import { SchoolPeriodHelper } from "../../dal/school_period_helper";
import { useSchoolContext } from "../../dal/SchoolContext";

interface PeriodConfig { // 節次設定清單(顯示用)
  period: string;
  semester: boolean[];
}

/* 請假節次設定 */
export const TimePeriodPage = () => {
  const [periodConfig, setPeriodConfig] = useState<SchoolPeriodConfig[]>([]); // 是否顯示載入中的視窗    
  const [schoolPeriod, setSchoolPeriod] = useState<PeriodConfig[]>([]); // 節次設定清單
  const { userInfo } = useUserContext(); // 取得登入者身份資料

  const { isOpen: isNotifyOpen, onOpen: onNotifyOpen, onClose: onNotifyClose } = useDisclosure(); //提示訊息視窗控制
  const [notifyMessage, setNotifyMessage] = useState(''); //提示訊息內容

  const [refresh, setRefresh] = useState(false); //重新載入資料用

  const { selectedSchool } = useSchoolContext(); // 學校資訊

  const loadData = async () => {
    const currDsns = selectedSchool?.dsns || ''; // Set currDsns to an empty string if it is undefined
    const dsaPeriod = await DSAAbsenceHelper.getPeriodsByDSNS(currDsns); //校務系統中定義的節次清單  
    /** 依據校務節次清單建立設定清單 */
    if(dsaPeriod !== undefined && dsaPeriod.length > 0)
    {
      const _periodConfig: SchoolPeriodConfig[] = await SchoolPeriodHelper.getSchoolPeriod(currDsns); //取得系統中節次設定
      setPeriodConfig(_periodConfig);
      let tempSchoolPeriod: PeriodConfig[] = [];
      dsaPeriod.forEach((item: any) => {
        const tempPeriod={
          period: item.PeriodName,
          semester: [false, false, false, false],
        }
        // if(userInfo?.semester!==undefined){
        //   tempPeriod.semester[userInfo?.semester-1]=true;
        // }
        tempSchoolPeriod.push(tempPeriod);
      });
      // 與現有設定比對，有設定的節次打勾
      if(_periodConfig.length>0)
      {
        _periodConfig.forEach((item: any) => {
          item.periods.forEach((period: any) => {
            const periodIdx = tempSchoolPeriod.findIndex((ele) => ele.period === period);
            if ((periodIdx !== -1))
            {
                tempSchoolPeriod[periodIdx].semester[item.semester-1]=true;
            }         
          });
        })
      };  
      setSchoolPeriod(tempSchoolPeriod);
    }
    else
    {
      setPeriodConfig([]);
      setSchoolPeriod([]);
    }
  }
  // 處理節次設定變更 (checkbox)
  const handlePeriodChange = (idx: number, semester: number) => (e: any) => {
    const newPeriods = [...schoolPeriod];
    newPeriods[idx].semester[semester] = e.target.checked;
    setSchoolPeriod(newPeriods);
  }

  const handleSave = async () => {
    const promises: Promise<any>[] = []; // Array to hold all promises
    [1,2,3,4].forEach((sems) => {      
      const filterPeriods = schoolPeriod.filter((item) => item.semester[sems-1]);      
      if(filterPeriods.length > 0)
      {
        let periodArray:string[]=[];

        filterPeriods.forEach((item) => periodArray.push(item.period));        
        if(periodConfig.find(item=>item.semester===sems))
        {
          promises.push(SchoolPeriodHelper.updateSchoolPeriod(sems, periodArray));
        } 
        else
        {
          promises.push(SchoolPeriodHelper.addSchoolPeriod(sems, periodArray));
        }
      }
      else
      { //該學期沒有節次設定，刪除該學期設定
        if(periodConfig.find(item=>item.semester===sems))
        {
          promises.push(SchoolPeriodHelper.deleteSchoolPeriod(sems));
        }
      } 
    })

    await Promise.all(promises);
    setNotifyMessage('請假節次已更新');  
    onNotifyOpen();
    setRefresh(true);
    //loadData();
  }

  useEffect(() => {
    if (refresh) {
      loadData();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    loadData();
  }, []);

  const checkPermission = () => {
    /* if(userInfo?.isSystemAdmin)
     {
       if((selectedSchool?.dsns===userInfo?.schoolDsns) && userInfo?.isAdmin)
       {
         return true;
       }
       else
       {
         return false;
       }
     }
     else
     {     
       return false;
     }*/
     if((selectedSchool?.dsns===userInfo?.schoolDsns) && userInfo?.isAdmin)
       {
         return true;
       }
       else
       {
         return false;
       }
 
   }
  useEffect(() => {
    loadData();    
  },[selectedSchool]);

  return (
    <>
      <Box borderWidth='1px' borderRadius='lg' p="6" h='87vh' overflow='hidden'>
        <div className="h-full overflow-y-auto">
          <div className="text-xl mb-4">請假節次設定</div>

          <TableContainer>
            <Table size='lg' variant='striped' w={850} className="timeTb">
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>節次</Th>
                  <Th>上學期</Th>
            {/*      <Th>寒輔</Th> */}
                  <Th>下學期</Th>
            {/*      <Th>暑輔</Th> */}
                </Tr>
              </Thead>
              <Tbody>
                {
                  schoolPeriod.map((period, idx) =>
                     ( 
                        <Tr key={`${idx}-${period}`}>
                          <Td isNumeric>{idx+1}</Td>
                          <Td>{period.period}</Td>
                          <Td><Checkbox colorScheme='cyan' isChecked={period.semester[0]} onChange={handlePeriodChange(idx,0)} isDisabled={!checkPermission()}/></Td>
           {/*               <Td><Checkbox colorScheme='cyan' isChecked={period.semester[2]} onChange={handlePeriodChange(idx,2)} isDisabled={!checkPermission()}/></Td> */}
                          <Td><Checkbox colorScheme='cyan' isChecked={period.semester[1]} onChange={handlePeriodChange(idx,1)} isDisabled={!checkPermission()}/></Td>
           {/*              <Td><Checkbox colorScheme='cyan' isChecked={period.semester[3]} onChange={handlePeriodChange(idx,3)} isDisabled={!checkPermission()}/></Td> */}
                        </Tr>
                     ))

                }
              </Tbody>
            </Table>
          </TableContainer>
          <div className="flex justify-between items-center mt-6">
          <div>最近一次修改時間：{dayjs(periodConfig[0]?.last_update).format('YYYY/MM/DD HH:mm:ss')}</div>
          {checkPermission() &&
            <Button className="mr-3" variant='outline' colorScheme='cyan' onClick={() => handleSave()}>儲存</Button>}
          </div>
        </div>
      </Box>
      <NotifyBoxModal isModalOpen={isNotifyOpen} title='資料提示' message={notifyMessage} onModalClose={onNotifyClose}/>
    </>
  )
}
