import { AttachmentInfo, SchoolDate, UploadToken } from "./value_object";

/** 
 * 處理檔案上傳  
 * 參考：https://uploadcare.com/blog/how-to-upload-file-in-react/
 * 參考：https://github.com/ischoolinc/storage.1campus.net/blob/main/demo/README.md
 * */
export class FileStorageHelper {

    /** 上傳檔案 */
    static async uploadFile(sourceFile: File | null): Promise<AttachmentInfo | undefined> {
        if (!sourceFile) return undefined;

        // Check file size (5MB = 5 * 1024 * 1024 bytes)
        // const maxSize = 5 * 1024 * 1024; // 5MB in bytes
        // if (file.size > maxSize) {
        //     const ex: any = new Error(`File size exceeds the maximum limit of 5MB. Current file size: ${(file.size / (1024 * 1024)).toFixed(2)}MB`);
        //     ex.code = 413;
        //     throw ex;
        // }

        // 如果是圖檔且超過指定大小，就自動縮小檔案。否則就回傳原本檔案。
        const file = await FileStorageHelper.resizeImageFile(sourceFile);

        const formData = new FormData();
        formData.append('file', file!);

        const uploadToken: UploadToken = await FileStorageHelper.getUploadToken();

        const uploadUrl = `https://storage.1campus.net/file?upload_token=${uploadToken.token}`;

        const response = await fetch(uploadUrl, {
            method: 'POST',
            body: formData,
        });
        
        // fetch api 的錯誤不能使用 try ... catch 攔截，必須使用 response.ok 判斷。
        // 請參考：https://dev.to/dionarodrigues/fetch-api-do-you-really-know-how-to-handle-errors-2gj0
        if (response.ok) {
            const result = (await response.json())[0];
            result.type = file!.type ;   // 手動加上 mime type
            return result ;
        } else {
            const ex = await response.json();
            ex.code = response.status ;
            // console.log( { ex })
            throw ex ;
        }
            
    }

    /** 如果圖檔超過指定大小，則壓縮檔案到寬度 600px */
    static async resizeImageFile(file: File ) : Promise<File | undefined> {
        if (!file) { return file ;}
        if (FileStorageHelper.isImageFormat(file) && FileStorageHelper.exceedImageSize(file)) {
            console.log('file size is too large. ')
            const targetFile = await FileStorageHelper.shinkImage(file, 600);
            console.log(` convert file size from ${file.size} to ${targetFile?.size }`)
            return targetFile ;
        } else {
            return file ;
        }
    }

    /** 判斷是否超過設定大小 */
    static exceedImageSize(file: File): boolean {
        // Check file size (5MB = 5 * 1024 * 1024 bytes)
        const maxSize = 5 * 1024 * 1024; // 5MB in bytes
        return (file.size > maxSize) ;
    }


    /** 從選取檔案的 mime type 判斷是否是圖片 */
    static isImageFormat(file: File | undefined): boolean { 
        if (!file) { return false ;}
        return (file.type.indexOf('image/') > -1) ;
    }

    /** 以ID取得檔案的metadata */
    static async getFileMetadata(url: string): Promise<any> {
        const resp = await fetch(url+'/metadata');
        return await resp.json();
    }

    /** 取得上傳檔案用的 upload token */
    static async getUploadToken(): Promise<UploadToken> {
        const resp = await fetch('/service/file/upload_token');
        return await resp.json();
    }

    /** 把 image dataurl 轉換成 File 物件 */
    static convertDataURLtoFile(dataurl: string | undefined, filename: string): File | undefined {
        // data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/uk+td.....=
        if (!dataurl) { return undefined ; }

        const arr = dataurl.split(",");
        // 找 mime.
        const temp = arr[0];
        const mime_temp = temp.split(':')[1];
        let mime = mime_temp.split(';')[0];

        const bstr = atob(arr[arr.length - 1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], `${filename}.${mime[0]}`, { type: mime });
    }

    /** 縮小圖片檔案到指定大小， https://www.youtube.com/watch?v=bXf_UdyDzSA */
    static async shinkImage(file:File, maxWidth:number = 400): Promise<File | undefined> {

        return new Promise( (resolve) => {

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function(event) {
                if (event.target) {
                    const imgElement = document.createElement("img") as HTMLImageElement;
                    imgElement.src = event.target.result as string ;
                    
                    imgElement.onload = (e) => {
                        if (e.target) {
                            const canvas = document.createElement("canvas") as HTMLCanvasElement;
                            const imgTarget = e.target as HTMLImageElement
                            const scaleSize = maxWidth / (imgTarget).width ;
                            canvas.width = maxWidth ;
                            canvas.height = (imgTarget).height * scaleSize ;
                            const ctx = canvas.getContext("2d");
                            ctx?.drawImage(imgTarget, 0, 0, canvas.width, canvas.height);
                            const srcEncoded = ctx?.canvas.toDataURL("image/jpeg");
                            const finalFile = FileStorageHelper.convertDataURLtoFile(srcEncoded, file.name)
                            resolve(finalFile);
                        } else {
                            resolve(file);
                        }
                    }
                } else {
                    resolve(file);
                }
            }
        });
    }
}