import { useUserContext } from "../../components/auth/user-validation";
import { Link } from "react-router-dom";
import { useDisclosure, useColorMode } from "@chakra-ui/react"
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";


export const StudentMenu = () => {
  const { userInfo, currentSemester } = useUserContext(); // 取得登入者身份資料

  console.log({ currentSemester });
  const { t, i18n } = useTranslation("translation");

  const { colorMode, setColorMode } = useColorMode();
  useEffect(() => {
    setColorMode('light');
  }, []);



  return (
    <>
      <div className="max-w-md h-screen bg-slate-100 mx-auto pt-4">
        <div className="grid grid-cols-2 gap-4 text-slate-700 font-medium text-xl p-4 h-[448px] max-h-[100vw]">
          <Link to="absence" className="flex flex-col items-center justify-center rounded-xl cursor-pointer hover:opacity-90 bg-[#e3a1bc]">
            <img src="/assets/img/error.svg" alt="" className="w-10 mb-1" />
            <div>{t("app.absent_leave")}</div>
          </Link>
          <Link to="inadvance" className="flex flex-col items-center justify-center rounded-xl cursor-pointer hover:opacity-90 bg-[#7dc0c6]">
            <img src="/assets/img/plus.svg" alt="" className="w-10 mb-1" />
            <div>{t("app.planned_leave")}</div>
          </Link>
          <Link to="history" className="flex flex-col items-center justify-center rounded-xl cursor-pointer hover:opacity-90 bg-[#e4b253]">
            <img src="/assets/img/check.svg" alt="" className="w-10 mb-1" />
            <div>{t("app.leave_history")}</div>
          </Link>
          <div className="w-44"></div>
        </div>
      </div>

    </>
  );
};
