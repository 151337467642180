import React from 'react'
import { IndexMenu } from '.'

export default function MenuPage() {
  return (
    <div>
        <IndexMenu />
      
    </div>
  )
}
