import { useState } from "react"
import { Box, Button} from "@chakra-ui/react"
import { useTranslation } from "react-i18next";

export function ToggleString(toggleStr:any) {
  const { t, i18n } = useTranslation("translation");
  
  const [isExpanded, setIsExpanded] = useState(false); // 事由是否展開
  const toDisplayString = (input: any): string => {
    if (typeof input === 'string') {
      return input; // Return the string as-is
    } else if (input === null || input === undefined) {
      return ''; // Convert null or undefined to an empty string
    } else if (typeof input === 'object') {
      // Attempt to JSON serialize objects, with error handling
      try {
        const tmpJson=JSON.stringify(input, null, 2);
        const tmpJsonString:{
          toggleStr:string
        }=JSON.parse(tmpJson);
        return tmpJsonString.toggleStr; // Convert other types to string
      } catch {
        return '[object Object]'; // Fallback for circular references, etc.
      }
    } else {
      return String(input); // Convert other types to string
    }
  };
  const displayStr = toDisplayString(toggleStr);
  
  return (
    <Box>
      <Box as="span" whiteSpace="pre-wrap">
        {isExpanded ? displayStr : `${displayStr.slice(0, 22)}`}
      </Box>
      {displayStr.length > 22 && (
        <Button
          size="xs"
          color="black" // Text color
          marginLeft={2} // Margin left
          bg="yellow.100" // Background color
          _hover={{ bg: 'yellow.300' }} // Background color on hover
          borderColor="yellow.100" // Border color
          sx={{ textDecoration: 'underline' }} // Text decoration
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? t("app.collapse") : t("app.more")+'..'}
        </Button>
      )}
    </Box>
  );
}
