import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Checkbox, Divider, Input, InputGroup, InputLeftElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay,Select,Stack,Tab,TabList,TabPanel,TabPanels,Tabs,Tag,Textarea} from "@chakra-ui/react"
import dayjs from "dayjs";
import { Util } from "../../dal/util";
import config  from './../../config';
import { useEffect, useState } from "react";
import { Search2Icon } from "@chakra-ui/icons";
import { DSAAbsenceHelper } from "../../dal/dsa_absence_helper";
import { set } from "date-fns";
import { LeaveStudent, SchoolDate, SchoolOtherSettings } from "../../dal/value_object";
import { DateObject } from "react-multi-date-picker";
import { is } from "date-fns/locale";

interface props {
    selectedStu:any[];
    setSelectedStu:(data:LeaveStudent[])=>void;
    isModalOpen:boolean;
    onModalClose:()=>void;
}

interface classStu {
    classID: number,
    gradeYear: number,
    className: string,
    student: stu[]
}

interface stu {
    checked: boolean,
    studentID: number,
    studentName: string,
    studentNumber: string,
    seatNo: number,
    classID: number,
    className: string,
    gradeYear: number
}



export const SelectStuModal=({selectedStu,setSelectedStu,isModalOpen,onModalClose}:props)=>{
    const [allClassStudents, setAllClassStudents] = useState<any[]>([]); // 所有班級學生資料
    const [classStu, setClassStu] = useState<classStu[]>([]); // 班級學生資料
    const [serachedStu, setSearchedStu] = useState<stu[]>([]); // 搜尋學生資料
    const [stuKeyword, setStuKeyword] = useState<string>(''); // 搜尋學生姓名關鍵字

    const [gradeYear, setGradeYear] = useState<number>(0); // 年級
    const [gradeClassStu, setGradeClassStu] = useState<classStu[]>([]); // 年級班級學生資料

    const [tmpSelectedStu, setTmpSelectedStu] = useState<stu[]>([]); // 已選擇的學生(暫存)
   
    const [passCheckedStu, setPassCheckedStu] = useState<string[]>([]); // 已檢查的學號
    const [stuNumberLinesArea, setStuNumberLinesArea] = useState(''); // 輸入的學號(每行一個學號)


  /** 檢查輸入的學號行 */
  const handleStuNumCheck = () => () => {
    const lines = stuNumberLinesArea.split("\n").map((line) => line.trim());
    const stuNumbers = lines.map((line) => line.trim());
    const passCheckedStu = stuNumbers.filter((stuNumber) => {
      return allClassStudents.flatMap((classItem) => classItem.student).some((student) => student.studentNumber === stuNumber);
    });
    setPassCheckedStu(passCheckedStu);
    console.log('passCheckedStu:',passCheckedStu);
  }
    
  /** 處理輸入的學號行 */
  const handleStuNumberLines = (e: { target: { value: string; }; }) => {
    setStuNumberLinesArea(e.target.value);
  }

  
  /** 匯入輸入的學號行 */
  const handleStuNumImport = () => () => {
    const importStu=classStu.flatMap((classItem) => classItem.student).filter((student) => passCheckedStu.includes(student.studentNumber));

    const selectedStuMap=new Map(tmpSelectedStu.map((stu) => [stu.studentID,stu])); // 類似字典用法

    /** 更新已選擇的學生 */
    importStu.forEach((student) => {
        if(!selectedStuMap.has(student.studentID)){
            student.checked=true;
            tmpSelectedStu.push(student);
        }
    })
    setTmpSelectedStu(tmpSelectedStu);

    /** 更新瀏覽清單內的學生 */
    const updateClassStu=gradeClassStu.map((classItem) => ({
        ...classItem,
        student: classItem.student.map((student) => ({
            ...student,
            checked: (tmpSelectedStu.filter((sstu) => sstu.studentID === student.studentID).length > 0) ? true : false
        }))
    }))
    setGradeClassStu(updateClassStu);

    const updateSearchedStu=serachedStu.map((student) => ({
        ...student,
        checked: (tmpSelectedStu.filter((sstu) => sstu.studentID === student.studentID).length > 0) ? true : false
    }))
    setSearchedStu(updateSearchedStu);

    setPassCheckedStu([]);
    setStuNumberLinesArea('');

  }

  
  const handleGradeYear = () => (e:any) => {
    if(e.target.value==='0')
    {
      setGradeYear(0);

      const updateClassStu=classStu.map((classItem) => ({
        ...classItem,
        student: classItem.student.map((student) => ({
            ...student,
            checked: (tmpSelectedStu.filter((sstu) => sstu.studentID === student.studentID).length > 0) ? true : false
        }))
      }))
      setGradeClassStu(updateClassStu);

    }
    else
    {
      const intGrade=parseInt(e.target.value);
      const temp=classStu.filter((classItem) => classItem.gradeYear===intGrade);

      setGradeYear(intGrade);
      const updateClassStu=temp.map((classItem) => ({
        ...classItem,
        student: classItem.student.map((student) => ({
            ...student,
            checked: (tmpSelectedStu.filter((sstu) => sstu.studentID === student.studentID).length > 0) ? true : false
        }))
        }))
      setGradeClassStu(updateClassStu);
    }    
  }  
    
  const handleSelectedStu = () => {
        //const temp=classStu.flatMap((classItem) => classItem.student).filter((student) => student.checked);
        setSelectedStu(tmpSelectedStu.map((student) => ({
            name: student.studentName,
            seatNo: student.seatNo,
            classID: student.classID,
            className: student.className,
            gradeYear: student.gradeYear,
            studentID: student.studentID,
            studentNumber: student.studentNumber
        })));
        onModalClose();
    }

    const AllChecked = (classItem:classStu) => {
        return classItem.student.every((student) => student.checked);        
    }

    const IndeterminateChecked = (classItem:classStu) => {
        return classItem.student.some((student) => student.checked) && !AllChecked(classItem);
    }

    const handleSearchChange = (e: { target: { checked: any; }; },student:stu) => {
        const temp=serachedStu.map((stu) => 
            (stu.studentID === student.studentID) ? 
            {
                ...stu,
                checked: e.target.checked
            } : stu
        )

        setSearchedStu(temp);

        const chktmp=temp.filter((student) => student.checked);
        const selectedStuMap=new Map(tmpSelectedStu.map((stu) => [stu.studentID,stu])); // 類似字典用法

        chktmp.forEach((student) => {
            if(!selectedStuMap.has(student.studentID)){
                tmpSelectedStu.push(student);
            }
        })
        setTmpSelectedStu(tmpSelectedStu);

        const updateClassStu=gradeClassStu.map((classItem) => ({
            ...classItem,
            student: classItem.student.map((student) => ({
                ...student,
                checked: (chktmp.filter((sstu) => sstu.studentID === student.studentID).length > 0) ? true : false
            }))
        }))
        setGradeClassStu(updateClassStu);


    }

    const handleStuKeyword = (e: { target: { value: any; }; }) => {
        setStuKeyword(e.target.value);
        if(e.target.value==='')
        {
            setSearchedStu([]);

            return;
        }                  
        const temp = classStu.flatMap((classItem) => classItem.student).filter((student) =>
        (student.studentName != null && student.studentName.includes(e.target.value)) ||
        (student.studentNumber != null && student.studentNumber.includes(e.target.value)));
  
        const tmpSelectedStuMap=new Map(tmpSelectedStu.map((stu) => [stu.studentID,stu])); // 類似字典用法

        temp.forEach((student) => {
            if(tmpSelectedStuMap.has(student.studentID)){
                student.checked=true;                
            }
        })
        setSearchedStu(temp);
    }


    const handleParentChange = (e: { target: { checked: any; }; },classItem:classStu) => {
        const temp=gradeClassStu.map((Item) => 
            (Item.classID === classItem.classID) ?
            {
                ...Item,
                student: Item.student.map((student) => ({
                    ...student,
                    checked: e.target.checked
                }))
            } : Item
        )
        setGradeClassStu(temp);
        const chktmp=temp.flatMap((classItem) => classItem.student).filter((student) => student.checked);
        const selectedStuMap=new Map(tmpSelectedStu.map((stu) => [stu.studentID,stu])); // 類似字典用法

        chktmp.forEach((student) => {
            if(!selectedStuMap.has(student.studentID)){
                tmpSelectedStu.push(student);
            }
        })
        setTmpSelectedStu(tmpSelectedStu);
      
    };
    
    const handleChildChange = (e: { target: { checked: any; }; },classItem:classStu,student:stu) =>{
        const temp=gradeClassStu.map((Item) => 
            (Item.classID === classItem.classID) ?
            {
                ...Item,
                student: Item.student.map((stu) => 
                    (stu.studentID === student.studentID) ?
                    {
                        ...stu,
                        checked: e.target.checked
                    } : stu
                )
            } : Item
        )
        setGradeClassStu(temp);
        const chktmp=temp.flatMap((classItem) => classItem.student).filter((student) => student.checked);
        const selectedStuMap=new Map(tmpSelectedStu.map((stu) => [stu.studentID,stu])); // 類似字典用法

        chktmp.forEach((student) => {
            if(!selectedStuMap.has(student.studentID)){
                tmpSelectedStu.push(student);
            }
        })

        setTmpSelectedStu(tmpSelectedStu);
    };
    
    const buildClassStu=(allClaStu:classStu[])=>{
        const clsStu=allClaStu.map((classItem: any) => ({
            classID: classItem.classID,
            gradeYear: classItem.gradeYear,
            className: classItem.className,
                   student: classItem.student.map((student: any) => ({
                       checked: (selectedStu.filter((sstu: any) => sstu.studentID === student.studentID).length > 0) ? true : false,
                       studentID: student.studentID,
                       studentName: student.studentName,
                       studentNumber: student.studentNumber,
                       seatNo: student.seatNo,
                       classID: classItem.classID,
                       className: classItem.className,
                       gradeYear: classItem.gradeYear
                   }))         

       }))
       setClassStu(clsStu);
       setGradeClassStu(clsStu);
       setTmpSelectedStu(clsStu.flatMap((classItem) => classItem.student).filter((student) => student.checked));
     //  setSearchedStu(clsStu.flatMap((classItem) => classItem.student).filter((student) => student.checked));

    }

    const loadData = async () => {
        const res = await DSAAbsenceHelper.getSchoolStudents();
        const classStu= res.class;             
        setAllClassStudents(res.class);
        buildClassStu(classStu);

    }

    useEffect(() => {        
        loadData();
    },[])

    useEffect(() => {
        setGradeYear(0);
        loadData(); 
    }
    ,[setSelectedStu])

    useEffect(() => {
        /** 資料初始化 */
        setGradeYear(0);
        setStuKeyword('');
        setPassCheckedStu([]);
        setSearchedStu([]);
        setStuNumberLinesArea('');
        loadData();          
    }
    ,[isModalOpen])

    return(
        <Modal size="xl" closeOnOverlayClick={false} isOpen={isModalOpen} onClose={onModalClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader className='!font-normal'>增加請假學生</ModalHeader>
                <ModalCloseButton />

                <ModalBody pb={6}>
                    <Tabs isFitted>
                    <TabList mb='2em'>
                        <Tab>清單瀏覽</Tab>
                        <Tab>搜尋學生</Tab>
                        <Tab>輸入學號</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel className="!p-0" >
                       {/* <div className="ps-4">班級</div> */}
                        <Select w='120px' value={gradeYear}  onChange={handleGradeYear()}>
                            <option value='0'>全年級</option>
                            <option value='1'>一年級</option>
                            <option value='2'>二年級</option>
                            <option value='3'>三年級</option>
                        </Select>
                        <Accordion allowToggle>
                            {
                              (gradeClassStu.length>0) &&  gradeClassStu.map((classItem, index) => (
                                    <AccordionItem key={classItem.classID}>
                                        <h2>
                                            <AccordionButton>
                                                <Box as="span" flex='1' textAlign='left'>
                                                    {classItem.gradeYear} 年 {classItem.className} 班
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </h2>
                                        <AccordionPanel pb={4}>
                                            <Checkbox isChecked={AllChecked(classItem)} isIndeterminate={IndeterminateChecked(classItem)} onChange={(e)=>handleParentChange(e,classItem)}>
                                                全選 {classItem.gradeYear} 年 {classItem.className} 班 (共{classItem.student.length}人)
                                            </Checkbox>
                                            <Stack pl={6} mt={1} className="!grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 flex-wrap">
                                                {classItem.student.map((student:any, index:any) => (
                                                    <Checkbox key={student.studentID} isChecked={student.checked} onChange={(e)=>handleChildChange(e,classItem,student)}>
                                                        {student.studentNumber+" "+student.studentName}
                                                    </Checkbox>
                                                ))}
                                            </Stack>
                                        </AccordionPanel>
                                    </AccordionItem>
                                ))
                            }     
                        </Accordion>

                        </TabPanel>
                        <TabPanel className="!p-0">
                        <InputGroup className="mb-6 max-w-md mx-auto">
                            <InputLeftElement pointerEvents='none' >
                            <Search2Icon color='gray.300' />
                            </InputLeftElement>
                            <Input type='text' placeholder='' borderColor='gray.400' value={stuKeyword} onChange={(e)=>handleStuKeyword(e)}/>
                        </InputGroup>

                        <Stack pl={6} mt={1} className="!grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 flex-wrap">
                            {
                                serachedStu.map((student, index) => (
                                    <Checkbox key={student.studentID} isChecked={student.checked} onChange={(e)=>handleSearchChange(e,student)}>
                                        {student.className+" "+student.studentNumber+" "+student.studentName}
                                    </Checkbox>
                                ))
                            }

                        </Stack>

                        </TabPanel>
                        <TabPanel className="!p-0 max-w-md mx-auto">
                        {passCheckedStu.length > 0 && 
                        <div className="px-4">通過檢查的學號:
                            <Tag colorScheme='twitter' className="ml-2">
                                {passCheckedStu.join(', ')}
                            </Tag>
                        </div>
                        }
                        <Textarea className='!h-40 border border-gray-200' value={stuNumberLinesArea} placeholder='請輸入學號(每行輸入一個學號)' onChange={handleStuNumberLines}></Textarea>
                        <div className="px-4 pt-4">
                            <Button colorScheme='whatsapp' isDisabled={!(stuNumberLinesArea!=='')} onClick={handleStuNumCheck()}>檢查</Button>
                            <Button className="float-right"isDisabled={!(passCheckedStu.length>0)} onClick={handleStuNumImport()}>匯入</Button>
                        </div>
                        </TabPanel>
                    </TabPanels>
                    </Tabs>
                </ModalBody>

                <ModalFooter className='gap-6'>
                    <Accordion allowToggle>
                        <AccordionItem>
                            <h2>
                                <AccordionButton>
                                    <Box as="span" flex='1' textAlign='left'>
                                        選擇學生 (已選擇{
                                            tmpSelectedStu.filter((student) => student.checked).length
                                        }人)
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <Stack pl={6} mt={1} className="!grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 flex-wrap">
                                    {tmpSelectedStu.map((student:any, index:any) => (
                                        <Tag key={student.studentID} colorScheme='twitter' className="mb-2">
                                            {student.className+" "+student.studentNumber+" "+student.studentName}
                                        </Tag>
                                    ))}
                                </Stack>
                            </AccordionPanel>
                        </AccordionItem>

                    </Accordion>
                    {/*<div className="">選擇學生 (已選擇{
                        classStu.reduce((acc, classItem) => acc + classItem.student.filter((student) => student.checked).length, 0)
                    }人)</div> */}
                    <Button colorScheme='twitter' className="" onClick={()=>handleSelectedStu()} isDisabled={!(tmpSelectedStu.length>0)}>確認</Button>
                </ModalFooter>
            </ModalContent>
      </Modal>
    )
}