import { SetStateAction, useEffect, useState } from "react";
import {
  Button,
  Card,
  Stack,
  StackDivider,
  useDisclosure,
  Select,
  Icon,
  CardFooter,
  CardHeader,  
  Link
} from "@chakra-ui/react";


import { InfoOutlineIcon, ChevronLeftIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { LeaveApplicationHelper } from "../../dal/leave_application_helper";
import { useUserContext } from "../../components/auth/user-validation";
import { LeaveApplicationItem } from "../../components/LeaveApplicationItem";
import { LeaveApplicationRecord, SemesterInfo, SemesterStrInfo } from "../../dal/value_object";

import { Util } from "../../dal/util";
import { ZoomPdfModal } from "../../components/Modals/ZoomPdfModal";
import { PdfZoomModal } from "../../components/Modals/PdfZoomModal";
import { SchoolOtherSettingsHelper } from "../../dal/school_other_settings_helper";
import { PdfModalHtml } from "../../components/Modals/PdfModalHtml";
import { PdfModalGoogleDoc } from "../../components/Modals/PdfModalGoogleDoc";
import { PdfJSModal } from "../../components/Modals/PdfJSModal";
import { useTranslation } from "react-i18next";

export const LeaveList = () => {
  const { userInfo } = useUserContext();
  const [activeButton, setActiveButton] = useState("全部");
  const [leaveApplications, setLeaveApplications] = useState<LeaveApplicationRecord[]>([]);      // 畫面上要顯示的請假單(篩選過)
  const [leaveAllApplication, setLeaveAllApplication] = useState<LeaveApplicationRecord[]>([]); // 這位學生的所有請假單
  const [semesters, setSemesters] = useState<SemesterStrInfo[]>([]);
  const [currentSemester, setCurrentSemester] = useState<SemesterStrInfo>(); // 目前選擇學年度學期 [學年度-學期]
  const [reload, setReload] = useState<boolean>(false);
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  const [schoolOtherSetting, setSchoolOtherSetting] = useState<any>({}); // 學校的其他設定
  const { t, i18n } = useTranslation("translation");


  //監聽視窗大小
  useEffect(() => {
    //setColorMode('light');

  }, []);


  //const [isSettingReady, setIsSettingReady] = useState<boolean>(false); // 學校是否已經完成設定
  
  const handleButtonClick = (buttonText: SetStateAction<string>) => {
    setActiveButton(buttonText);
    if(buttonText === "全部") {
      setLeaveApplications(leaveAllApplication);     
    }
    else
    {
      const recs =leaveAllApplication.filter((rec: any) => {
        switch (buttonText) {
          case "申請中":
            return rec.status === "parent_sign" || rec.status === "progressing";
          case "退回":
            return rec.status === "rejected";
          case "已完成":
            return rec.status === "finished";
        }
        return false;
    })
    setLeaveApplications(recs);
    }
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isRuleOpen,
    onOpen: onRuleOpen,
    onClose: onRuleClose,
  } = useDisclosure();
  const navigate = useNavigate();

  const loadLeaveApplications = async () => {

    const recs =
      await LeaveApplicationHelper.getStudentHistoriesBySchoolYearAndSemester(
        currentSemester?.school_year,
        currentSemester?.semester
      );
    let noCancel: any;  
    if(recs)
    {
        noCancel=recs.filter((rec: any) => {
        return rec.status !== "canceled"      
      })
    }
    else
    {
      noCancel=[];
    }
    const sorted=Util.reSortLeaveApp(noCancel);
    
    setLeaveApplications(sorted);
    setLeaveAllApplication(sorted);
  }
  const handleSemetersChange = async (e: any) => {
    const [schoolYear, semester] = e.target.value.split("-");
    setCurrentSemester({ school_year: schoolYear, semester: semester });
    await loadSemesterLeaApp(schoolYear,semester);
  }

  const handlePDFOpen = () => {
    if(schoolOtherSetting?.leave_rule)
      setIsPdfModalOpen(true);   

  }

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (reload) {
      loadData();
      setReload(false);
    }
  }, [reload]);

  const loadData = async () => {
    const semesters =
    await LeaveApplicationHelper.getStudentHistoriesSemester();
    setSemesters(semesters);
    console.log({semesters});
    if (semesters.length > 0) {
      setCurrentSemester(semesters[0]);
      loadSemesterLeaApp(semesters[0].school_year,semesters[0].semester);
    }
    const setting = await SchoolOtherSettingsHelper.getSchoolOtherSettings();
    setSchoolOtherSetting(setting);

   // await loadLeaveApplications();
  };  

  const loadSemesterLeaApp = async (schoolYear:string,semester:string) => {
    await LeaveApplicationHelper.getStudentHistoriesBySchoolYearAndSemester(
      schoolYear,semester
    ).then(recs => {
      const noCancel = recs.filter((rec: any) => {
        return rec.status !== "canceled"      
      })
      if(noCancel.length > 0) {
        const sorted=Util.reSortLeaveApp(noCancel);
        setLeaveApplications(sorted);
        setLeaveAllApplication(sorted);
      }
   //   setLeaveApplications(noCancel);
   //   setLeaveAllApplication(noCancel);
    });
  }


  return (
    <>
      <div className="max-w-md bg-slate-100 h-screen py-4 px-4 sm:px-6">
        <div className="flex items-center gap-3">
          <div className="flex-1 text-start">
            <Button
              colorScheme="blue"
              variant="ghost"
              size="md"
              className="!p-0 w-fit"
              onClick={() => navigate('/student')}
              leftIcon={
                <Icon w={6} h={6} color="blue.500" viewBox="0 0 24 24">
                  <g fill="none" fillRule="evenodd">
                    <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                    <path
                      fill="currentColor"
                      d="M8.293 12.707a1 1 0 0 1 0-1.414l5.657-5.657a1 1 0 1 1 1.414 1.414L10.414 12l4.95 4.95a1 1 0 0 1-1.414 1.414z"
                    />
                  </g>
                </Icon>
              }
            >
              {/* <span>返回主頁</span> */}
            </Button>
          </div>
          <div className="text-center text-lg font-semibold grow">{t("app.leave_history")}</div>
          <div className="flex-1"/>
        </div>

        <div className="flex flex-wrap justify-between items-center gap-2 pb-4 pt-3">
          <div className="">
            {/* #TODO:確認學期是否需要翻譯 */}
            <Select variant="flushed" placeholder="" className="!w-auto" onChange={handleSemetersChange}>        
              {
                (semesters.find((semester) => semester.school_year === userInfo?.schoolYear.toString() && semester.semester === userInfo?.semester.toString())) ?
                  semesters.map((semester, index: number) => (
                    (semester.school_year === userInfo?.schoolYear.toString() && semester.semester === userInfo?.semester.toString()) ?                                  
                    <option key={index} value={semester.school_year+"-"+semester.semester} selected>{semester.school_year+`${i18n.language === "en" ? "-Semester" : "學年度第"}`+semester.semester+`${i18n.language === "en" ? "" : "學期"}` }</option> :
                    <option key={index} value={semester.school_year+"-"+semester.semester}>{semester.school_year+`${i18n.language === "en" ? "-Semester" : "學年度第"}`+semester.semester+`${i18n.language === "en" ? "" : "學期"}`}</option>))
                :
                  semesters.map((semester, index: number) => (
                  (index===0) ?                                  
                  <option key={index} value={semester.school_year+"-"+semester.semester} selected>{semester.school_year+`${i18n.language === "en" ? "-Semester" : "學年度第"}`+semester.semester+`${i18n.language === "en" ? "" : "學期"}` }</option> :
                  <option key={index} value={semester.school_year+"-"+semester.semester}>{semester.school_year+`${i18n.language === "en" ? "-Semester" : "學年度第"}`+semester.semester+`${i18n.language === "en" ? "" : "學期"}`}</option> ))
              }     
            </Select>
          </div> 
          {/*<div onClick={onRuleOpen} className="cursor-pointer">          
            <InfoOutlineIcon />
            <span className="ms-1 align-middle">請假規定</span>
            </div>*/}
            <Link href={schoolOtherSetting?.leave_rule+'?__target=InAppBrowser'} isExternal className="cursor-pointer" style={{ textDecoration: 'none' }}>
              <InfoOutlineIcon />
              <span className="ms-2 align-middle">{t("app.rules_of_leave")}</span>
            </Link> 
          {/*  <div onClick={()=>handlePDFOpen()} className="cursor-pointer">          
            <InfoOutlineIcon />
              <span className="ms-1 align-middle">請假規定</span>
        </div>*/}


        </div>

        <div className="mb-4">
          <Stack direction="row" align="center" className="justify-between">
            <Button
              size="md"
              className={`!h-8 !text-sm !text-white bg-ing ${
                activeButton === "申請中" && "active"
              }`}
              onClick={() => handleButtonClick("申請中")}
            >
              {t("app.pending")}
            </Button>
            <Button
              size="md"
              className={`!h-8 !text-sm !text-white bg-return ${
                activeButton === "退回" && "active"
              }`}
              onClick={() => handleButtonClick("退回")}
            >
              {t("app.decline")}
            </Button>
            <Button
              size="md"
              className={`!h-8 !text-sm !text-white bg-ed ${
                activeButton === "已完成" && "active"
              }`}
              onClick={() => handleButtonClick("已完成")}
            >
              {t("app.completed")}
            </Button>
            <Button
              size="md"
              className={`!h-8 !text-sm !text-white bg-all ${
                activeButton === "全部" && "active"
              }`}
              onClick={() => handleButtonClick("全部")}
            >
              {t("app.all")}
            </Button>
          </Stack>
        </div>
        
        
        <Card className="!rounded-2xl">
          <CardHeader className="!p-2" />
          {
          (leaveApplications.length === 0) ?
          <div className='flex flex-col justify-center items-center h-[500px]'>
            <img src="/assets/img/nodata.png" alt="" className='w-32 mx-auto mb-4' />
            <div>{t("app.no_data")}</div>
          </div> :
          <Stack
            divider={<StackDivider />}
            spacing="2"
            className="h-stud overflow-auto"
          >
            {leaveApplications.map((leaveApp: any, index: number) => (
              <LeaveApplicationItem
                key={index}
                leaveApplicationRecord={leaveApp}
                onShowDetail={(data: any) => {
                  console.log({ data });
                }}
                setReload={setReload}
              />
            ))}
            </Stack>
          }        

          <CardFooter className="!p-2" />
        </Card>
      </div>
      {/*<PdfZoomModal
        isModalOpen={isPdfModalOpen}
        onModalClose={()=>setIsPdfModalOpen(false)}
        pdfUrl={schoolOtherSetting?.leave_rule}
        title="請假單"
      />*/}
      <PdfModalHtml
        isModalOpen={isPdfModalOpen}
        onModalClose={()=>setIsPdfModalOpen(false)}
        pdfUrl={schoolOtherSetting?.leave_rule+'?__target=InAppBrowser'}
        title="請假單"
    />
     {/* <PdfJSModal
        isModalOpen={isPdfModalOpen}
        onModalClose={()=>setIsPdfModalOpen(false)}
        pdfUrl={schoolOtherSetting?.leave_rule}
        title="請假單"
  />*/}
    </>
  );
};
