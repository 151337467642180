import { List, ListItem, ListIcon } from "@chakra-ui/react"
import { NavLink, useLocation } from "react-router-dom";

export const ManagerMenuPage = () => {

  /* 查詢全校請假紀錄 */
  const location = useLocation();


  return (

    <List mr='16px' className="text-lg">
      <NavLink to="record">
        <ListItem
          className={`flex items-center hover:bg-[#d6f5f050] rounded-md py-2 px-4 mb-2 ${location.pathname === '/manager' && 'actived'}`}>
          <ListIcon viewBox='0 0 612 612'>
            <g><path d="M63.311 73.862C28.342 73.862 0 102.204 0 137.172s28.342 63.311 63.311 63.311c34.968 0 63.31-28.342 63.31-63.311s-28.342-63.31-63.31-63.31zm0 168.827C28.342 242.689 0 271.032 0 306c0 34.969 28.342 63.311 63.311 63.311 34.968 0 63.31-28.342 63.31-63.311 0-34.968-28.342-63.311-63.31-63.311zm0 168.829C28.342 411.518 0 439.859 0 474.827c0 34.969 28.342 63.311 63.311 63.311 34.968 0 63.31-28.342 63.31-63.311 0-34.968-28.342-63.309-63.31-63.309zm168.827-232.139h337.655c23.319 0 42.207-18.888 42.207-42.207s-18.888-42.207-42.207-42.207H232.138c-23.319 0-42.207 18.888-42.207 42.207s18.888 42.207 42.207 42.207zm337.655 84.414H232.138c-23.319 0-42.207 18.888-42.207 42.207s18.888 42.207 42.207 42.207h337.655C593.112 348.207 612 329.319 612 306s-18.888-42.207-42.207-42.207zm0 168.828H232.138c-23.319 0-42.207 18.887-42.207 42.206s18.888 42.207 42.207 42.207h337.655c23.319 0 42.207-18.888 42.207-42.207s-18.888-42.206-42.207-42.206z" fill="#32bea6" opacity="1" data-original="#000000"></path></g>
          </ListIcon>
          查詢全校請假紀錄
        </ListItem>
      </NavLink>
      <NavLink to="classday" >
        <ListItem
          className="flex items-center hover:bg-[#d6f5f050] rounded-md py-2 px-4 my-2">
          <ListIcon viewBox='0 0 512 512'>
            <g><path d="M105 75V25c0-13.8 11.2-25 25-25s25 11.2 25 25v50c0 13.8-11.2 25-25 25s-25-11.2-25-25zm103.37 291.3-6.12 35.68c-2.1 12.23 10.73 21.55 21.71 15.78L256 400.92l32.04 16.84c10.98 5.77 23.81-3.55 21.71-15.78l-6.12-35.68 25.92-25.27c8.88-8.66 3.98-23.74-8.29-25.53l-35.82-5.2-16.02-32.46c-5.49-11.12-21.35-11.12-26.84 0l-16.02 32.46-35.82 5.2c-12.27 1.78-17.18 16.87-8.29 25.53zM382 100c13.8 0 25-11.2 25-25V25c0-13.8-11.2-25-25-25s-25 11.2-25 25v50c0 13.8 11.2 25 25 25zM0 187h512v265c0 33.1-26.9 60-60 60H60c-33.1 0-60-26.9-60-60zm131 157.5c0 69.04 55.96 125 125 125s125-55.96 125-125-55.96-125-125-125-125 55.96-125 125zM452 52h-35v23c0 19.3-15.7 35-35 35s-35-15.7-35-35V52H165v23c0 19.3-15.7 35-35 35S95 94.3 95 75V52H60C26.9 52 0 78.9 0 112v65h512v-65c0-33.1-26.9-60-60-60z" data-name="13" fill="#32bea6" opacity="1" data-original="#000000"></path></g>
          </ListIcon>
          設定上課日
        </ListItem>
      </NavLink>
      <NavLink to="process" >
        <ListItem
          className="flex items-center hover:bg-[#d6f5f050] rounded-md py-2 px-4 my-2">
          <ListIcon viewBox='0 0 512 400'>
            <g><path d="m437 300-17.988 23.992 23.996 18.004H391v-30H121v30H90c-33.09 0-60-26.906-60-60 0-33.09 26.91-60 60-60h31v30h270v-30h31c49.629 0 90-40.371 90-90s-40.371-90-90-90h-31v-30H121v30h-10L54.992 0 37.008 23.992l23.996 18.004H0v30h61.004L37.008 90l17.984 23.992L111 71.996h10v30h270v-30h31c33.09 0 60 26.91 60 60 0 33.094-26.91 60-60 60h-31v-30H121v30H90c-49.629 0-90 40.371-90 90s40.371 90 90 90h31v30h270v-30h52.008L419.012 390 437 413.992l75-56.996zm0 0" fill="#32bea6" opacity="1" data-original="#000000"></path></g>
          </ListIcon>
          設定請假流程
        </ListItem>
      </NavLink>
      <NavLink to="absenttype" >
        <ListItem
          className="flex items-center hover:bg-[#d6f5f050] rounded-md py-2 px-4 my-2">
          <ListIcon viewBox='0 0 32 32'>
            <g><circle cx="16" cy="21.5" r="3.5" fill="#32bea6" opacity="1" data-original="#000000"></circle><path d="M20.5 28.24V31a1.003 1.003 0 0 1-1 1h-7a1.003 1.003 0 0 1-1-1v-2.86a4.548 4.548 0 0 1 .79-2.59 5.476 5.476 0 0 0 7.38.04 4.883 4.883 0 0 1 .83 2.65ZM7.857.406H2.67a2.048 2.048 0 0 0-2 2.089v5.417a2.048 2.048 0 0 0 2 2.088h5.187a2.048 2.048 0 0 0 2-2.088V2.495a2.048 2.048 0 0 0-2-2.089Z" fill="#32bea6" opacity="1" data-original="#000000"></path><circle cx="27" cy="5" r="5" fill="#32bea6" opacity="1" data-original="#000000"></circle><path d="M16.866.625a.985.985 0 0 0-1.732 0l-4 7.706a1.221 1.221 0 0 0 0 1.113A.986.986 0 0 0 12 10h8a.986.986 0 0 0 .866-.556 1.221 1.221 0 0 0 0-1.113ZM16 17a1 1 0 0 1-1-1v-3.099a1 1 0 0 1 2 0V16a1 1 0 0 1-1 1ZM10.036 21.929H8.35A4.354 4.354 0 0 1 4 17.58v-4.679a1 1 0 0 1 2 0v4.679a2.351 2.351 0 0 0 2.349 2.349h1.687a1 1 0 0 1 0 2ZM23.651 21.929h-1.687a1 1 0 0 1 0-2h1.687A2.351 2.351 0 0 0 26 17.58v-4.679a1 1 0 0 1 2 0v4.679a4.354 4.354 0 0 1-4.349 4.349Z" fill="#32bea6" opacity="1" data-original="#000000"></path><path d="M17.993 15.007a.997.997 0 0 1-.707-.293L16 13.428l-1.286 1.286A1 1 0 0 1 13.3 13.3l1.993-1.993a1 1 0 0 1 1.414 0L18.7 13.3a1 1 0 0 1-.707 1.707ZM6.993 15.007a.997.997 0 0 1-.707-.293L5 13.428l-1.286 1.286A1 1 0 0 1 2.3 13.3l1.993-1.993a1 1 0 0 1 1.414 0L7.7 13.3a1 1 0 0 1-.707 1.707ZM28.993 15.007a.997.997 0 0 1-.707-.293L27 13.428l-1.286 1.286A1 1 0 0 1 24.3 13.3l1.993-1.993a1 1 0 0 1 1.414 0L29.7 13.3a1 1 0 0 1-.707 1.707Z" fill="#32bea6" opacity="1" data-original="#000000"></path></g>
          </ListIcon>
          設定請假類別
        </ListItem>
      </NavLink>
      <NavLink to="time" >
        <ListItem
          className="flex items-center hover:bg-[#d6f5f050] rounded-md py-2 px-4 my-2">
          <ListIcon viewBox='0 0 20 20' h='20px'>
            <g><path d="M20 7h-9c-.6 0-1-.4-1-1s.4-1 1-1h9c.6 0 1 .4 1 1s-.4 1-1 1zM20 13h-9c-.6 0-1-.4-1-1s.4-1 1-1h9c.6 0 1 .4 1 1s-.4 1-1 1zM20 19h-8c-.6 0-1-.4-1-1s.4-1 1-1h8c.6 0 1 .4 1 1s-.4 1-1 1zM8 21H4c-.4 0-.8-.3-.9-.7s0-.8.3-1.1l3.1-2.6c.2-.2.5-.5.5-.6 0-.3-.1-.5-.3-.7-.4-.4-1-.4-1.4 0-.2.2-.3.4-.3.7 0 .6-.4 1-1 1s-1-.4-1-1c0-.8.3-1.6.9-2.1 1.1-1.1 3.1-1.1 4.2 0 .6.5.9 1.3.9 2.1 0 .9-.6 1.5-1.1 2l-.2.2-.9.8H8c.6 0 1 .4 1 1s-.4 1-1 1zM6 11c-.6 0-1-.4-1-1V6.4l-.3.3c-.4.4-1 .4-1.4 0s-.4-1 0-1.4l2-2c.3-.3.7-.4 1.1-.2.4.1.6.5.6.9v6c0 .6-.4 1-1 1z" fill="#32bea6" opacity="1" data-original="#000000"></path></g>
          </ListIcon>
          設定請假節次
        </ListItem>
      </NavLink>
      <NavLink to="other" >
        <ListItem
          className="flex items-center hover:bg-[#d6f5f050] rounded-md py-2 px-4 my-2">
          <ListIcon viewBox='0 0 24 24'>
            <g><g data-name="Layer 2"><rect width="9.5" height="9.5" x="13.25" y="1.25" rx="1.75" fill="#32bea6" opacity="1" data-original="#000000"></rect><path d="m22.733 16.763-3.496-3.496a1.753 1.753 0 0 0-2.474 0l-3.496 3.496a1.752 1.752 0 0 0 0 2.474l3.496 3.496a1.752 1.752 0 0 0 2.474 0l3.496-3.496a1.751 1.751 0 0 0 0-2.474z" fill="#32bea6" opacity="1" data-original="#000000"></path><rect width="9.5" height="9.5" x="1.25" y="1.25" rx="1.75" fill="#32bea6" opacity="1" data-original="#000000"></rect><rect width="9.5" height="9.5" x="1.25" y="13.25" rx="1.75" fill="#32bea6" opacity="1" data-original="#000000"></rect></g></g>
          </ListIcon>
          請假規章及其他設定
        </ListItem>
      </NavLink>
      <NavLink to="setadmin" >
        <ListItem
          className="flex items-center hover:bg-[#d6f5f050] rounded-md py-2 px-4 my-2">
          <ListIcon viewBox='0 0 24 24'>
            <g><g data-name="Layer 2"><rect width="9.5" height="9.5" x="13.25" y="1.25" rx="1.75" fill="#32bea6" opacity="1" data-original="#000000"></rect><path d="M21 21c1.7 0 4.9 1.3 5 2H12c.1-.7 3.3-2 5-2h4m0-1h-4c-1.7 0-6 1.3-6 3 0 .6.4 1 1 1h14c.6 0 1-.4 1-1 0-1.7-4.3-3-6-3zM19 9c2.4 0 3.5 2.1 3.5 4 0 2.5-1.6 5.5-3.5 5.5s-3.5-3-3.5-5.5c0-1.9 1.1-4 3.5-4m0-1c-2.8 0-4.5 2.2-4.5 5s1.7 6.5 4.5 6.5 4.5-3.7 4.5-6.5-1.7-5-4.5-5z" fill="#32bea6" opacity="1" data-original="#000000"></path><rect width="9.5" height="9.5" x="1.25" y="1.25" rx="1.75" fill="#32bea6" opacity="1" data-original="#000000"></rect><rect width="9.5" height="9.5" x="1.25" y="13.25" rx="1.75" fill="#32bea6" opacity="1" data-original="#000000"></rect></g></g>
          </ListIcon>
          設定管理者
        </ListItem>
      </NavLink>
    </List>
  )
}