


/** 取得系統的資訊  */
export class SystemHelper {

    static async getCurrentSemester() {
        const resp = await fetch('/service/system/current_semester');
        return await resp.json();
    }

    static async getSemesters() {

    }

    static async getAllSchools() {
        const resp = await fetch('/service/system/get_schools');
        return await resp.json();

    }

}
