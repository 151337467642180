import { useEffect, useRef, useState } from 'react';
import { Button, Icon, Input, InputGroup, InputRightElement, ListItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, OrderedList, Stack, Switch, Tag, Textarea, useDisclosure } from "@chakra-ui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SignatureCanvas from 'react-signature-canvas';
import { AbsenceLeaveRequestItemInfo, AbsenceType, SchoolOtherSettings } from '../../../dal/value_object';
import dayjs from 'dayjs';
import { useUserContext } from '../../../components/auth/user-validation';
import { LoadingDialog } from '../../../components/LoadingDialog';
import { FileStorageHelper } from '../../../dal/file_storage_helper';
import { LeaveApplicationHelper } from '../../../dal/leave_application_helper';
import { useTranslation } from "react-i18next";
import { m } from 'framer-motion';

/** 新增缺曠請假 */
export const AbsentAdd = () => {
  const { userInfo } = useUserContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation("translation");
  // console.log({ state: location.state })
  const absRecords: AbsenceLeaveRequestItemInfo[] = location.state.absRecords ;
  const absenceTypes = location.state.absenceTypes ;
  const schoolOtherSettings: SchoolOtherSettings = location.state.schoolOtherSettings ;
  console.log( { absRecords, absenceTypes });

  const [selectedOption, setSelectedOption] = useState<AbsenceType | null>(null);
 // const handleSwitchChange = (value: string) => {
 //   setSelectedOption(value)
 // };
  const [absReason, setAbsReason] = useState<string | null>(""); // 暫存請假理由
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined); // 暫存使用者選擇的附件
  const [isInputDataReady, setIsInputDataReady] = useState<boolean>(false); // 請假填寫資料是否已齊備可送出
  const [showLoading, setShowLoading] = useState<boolean>(false); // 是否顯示載入中圖示的視窗
  const fileInputRef = useRef(null) //使用者選擇檔案的input ref
  
  const { isOpen, onOpen: onSignOpen, onClose } = useDisclosure();
  // const {
  //   isOpen: isSignOpen,
  //   onOpen: onSignOpen,
  //   onClose: onSignClose,
  // } = useDisclosure();


  const [imageURL, setImageURL] = useState<string | undefined>(undefined);
  const sigCanvas = useRef<SignatureCanvas | null>(null);
  const clear = () => sigCanvas.current?.clear();
  const save = () => {
    setImageURL(sigCanvas.current?.getTrimmedCanvas()?.toDataURL("/assets/img/png") ?? undefined);
  };

  const handleAbsenceTypeChange = (value: AbsenceType) => {
    setSelectedOption(value);
  };

  /** 紀錄使用者填寫請假原因 */
  const handleAbsReasonChanged = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setAbsReason(e.target.value);
  };


  /** 暫存使用者選取的檔案 */
  const handleAttachFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      // setSelectedFile(e.target.files[0]);
      const targetFIle = e.target.files[0];
      if (FileStorageHelper.isImageFormat(targetFIle)) {
        setSelectedFile(targetFIle);
      } else {
        window.alert('附件格式請選取圖片或照片檔案');
      }
    }
  };

  /** 檢查使用者是否已輸入所有必要資訊 */
  const checkData = () => {
    console.log(" checkData() ...");

    let result = true;

    // 1. 是否選取假別
    result = result && !!selectedOption;

    // 2. 是否輸入請假原因
    result = result && !!absReason;

    // 3. 檢查是否簽名
    const needParentSign = schoolOtherSettings
      ? schoolOtherSettings.need_parent_approval
      : false;
    if (needParentSign && userInfo?.roleType === "parent" && !imageURL) {
      result = result && false;
      // window.alert("需要簽名");
    }

    setIsInputDataReady(result);

    return result;
  };

/** 點擊上傳檔案外觀按鈕，開啟檔案選擇視窗 */
  const handleUploadFileClick = () => {
    if (fileInputRef.current) {
      (fileInputRef.current as HTMLInputElement).click();
    }
  }
  
  /** 上傳檔案 */
  const uploadFile = async (file: File | undefined) => {
    if (!file) return;
    try {
      const attInfo = await FileStorageHelper.uploadFile(file);
      console.log(attInfo);
      return attInfo;
    } catch (ex) {
      throw ex ;
    }
  };

  /** 送出申請單 */
  const submitLeaveApplication = async () => {
    const leaveDateAndPeriods=makeLeaveDateAndPeriodPayload();
    // 檢查請假日數強制附件上傳
    if(((selectedOption?.min_days ?? 0) <= leaveDateAndPeriods.length) && (selectedFile === undefined) && ((selectedOption?.min_days ?? 0) !== -1))
    {
      alert(`根據學校規定，${leaveDateAndPeriods.length}天以上的${selectedOption?.type}需提交相關附件，請上傳附件再送出假單。`);
      return;
    }
    console.log("submit application");
    setShowLoading(true);

    let isReady = true ;

    let attInfo ;

    // 確認附件
    try {
      attInfo = await uploadFile(selectedFile);
    } catch (ex: any) {
      console.log({ ex });
    //  if (ex.code === 413) {
    //    alert('附件大小請勿超過 5MB.')
    //  } else {
        alert('上傳附件失敗！');
    //  }
      isReady = false ;
    }

    // 上傳簽名檔
    const signFile = FileStorageHelper.convertDataURLtoFile(
      imageURL,
      "parent_signature"
    ); // 把 imageUrl 轉成 File 物件
    const signFileInfo = await uploadFile(signFile);
    console.log({ signFileInfo });

    if (isReady) {
      // 4. 送出

    const data = {
      absence_type: selectedOption?.type,
      leaveDateAndPeriod: leaveDateAndPeriods,
      reason: absReason,
      need_parent_signature: schoolOtherSettings?.need_parent_approval,
      attachment_info: attInfo,
      parent_sign_info: signFileInfo,
    };
    // console.log( { data });
    const resp = await LeaveApplicationHelper.send(data);
    console.log( { resp });

    setShowLoading(false);
    navigate("../history");
  }
  else {
    setShowLoading(false);
  }
};

  const makeLeaveDateAndPeriodPayload = () => {
    const result = absRecords.map( absRec => ( {
      d: dayjs(absRec.rawAbsRec.OccurDate).format('YYYY/MM/DD'),
      periods: absRec.rawAbsRec.AbsenceDetail.map( absDetail => absDetail.Period)
    }));
    // const result = selectedDates.map((sd) => ({
    //   d: sd,
    //   periods: leaveDatePeriods[sd],
    // }));

    return result;
  };

  /** 當這些變數有變動時 */
  useEffect(() => {
    checkData();

  }, [selectedOption, absReason, imageURL]);

  return (
    <>
      <div className="max-w-md bg-white mx-auto py-4 px-4 sm:px-6 h-screen">
        {/* Header Toolbar */}
        <div className="grid grid-cols-3 items-center">
          <Button colorScheme='blue' variant='ghost' size='md' className="w-6" onClick={() => navigate(-1)}
            leftIcon={
              <Icon w={6} h={6} color='blue.500' viewBox='0 0 24 24'>
                <g fill="none" fillRule="evenodd">
                  <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" /><path fill="currentColor" d="M8.293 12.707a1 1 0 0 1 0-1.414l5.657-5.657a1 1 0 1 1 1.414 1.414L10.414 12l4.95 4.95a1 1 0 0 1-1.414 1.414z" />
                </g>
              </Icon>
            }>
          </Button>
          <div className="font-semibold text-center">{t("app.absent_leave")}</div>
        </div>

        <div className='text-left py-3'>
          <OrderedList spacing={3}>
            <ListItem className="font-semibold">{t("app.leave_dates")}</ListItem>
            <div className="!mt-2"> {dayjs(absRecords[0].rawAbsRec.OccurDate).format('YYYY/MM/DD')}
            { (absRecords.length > 1) && ( <span> ~ </span> ) }
            { (absRecords.length > 1) && ( dayjs(absRecords[absRecords.length -1].rawAbsRec.OccurDate )).format('YYYY/MM/DD') }
              
            </div>
            <ListItem className="font-semibold">{t("app.leave_category_l")}{" "}
                {!selectedOption && (
                  <span className="text-sm text-red-500 ms-2">{t("app.leave_category_not_selected")}</span>
                )}
            </ListItem>
            <div className="!mt-2">
              <Stack align="center" direction="row" className="flex-wrap">
                  {absenceTypes.map((absType: AbsenceType) => (
                    <Switch
                      key={absType.type}
                      size="lg"
                      colorScheme="twitter"
                      content={absType.type}
                      className="switch-txt"
                      onChange={() => handleAbsenceTypeChange(absType)}
                      isChecked={selectedOption?.type === absType.type}
                    />
                  ))}
              </Stack>
            </div>
            <ListItem className="font-semibold">{t("app.leave_sessions")}</ListItem>
            { absRecords && absRecords.map( absRec => (
              <AbsenceLeaveDatePeriod item={absRec} />
            ))}
            <ListItem className="font-semibold">
              {t("app.leave_reason_l")}{" "}
                {!absReason && (
                  <span className="text-sm text-red-500 ms-2">{t("app.leave_reason_unfilled")}</span>
                )}
            </ListItem>
            <div className='pr-4'>
              <Textarea placeholder={t("app.enter_leave_reason")} 
              onChange={handleAbsReasonChanged}/>
            </div>
            <ListItem className="font-semibold">{t("app.upload_attachment")}
            { (userInfo?.roleType === "parent" &&
                  schoolOtherSettings?.need_parent_approval) && (t("app.and_parent_sign"))}
            </ListItem>
            <InputGroup className="!grid grid-cols-1 gap-4 w-auto pr-4">
              {/* 選擇檔案的按鈕，為了翻譯做一個外表 */}
                <Input
                  type="text"
                  placeholder="Enter amount"
                  className="w-full z-10 cursor-pointer"
                  isReadOnly={true}
                  onClick={handleUploadFileClick}
                  value={selectedFile?.name || t("app.no_file_selected")}
                />
                <Input
                  type="file"
                  placeholder="Enter amount"
                  className="w-full pt-2 z-10 cursor-pointer hidden"
                  accept=".jpg, .jpeg, .png, .gif"
                  onChange={handleAttachFileChange}
                  ref={fileInputRef}
                />
                <InputRightElement className="!right-4">
                  <Icon w={6} h={6} color="gray.500" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M11 16V7.85l-2.6 2.6L7 9l5-5l5 5l-1.4 1.45l-2.6-2.6V16zm-5 4q-.825 0-1.412-.587T4 18v-3h2v3h12v-3h2v3q0 .825-.587 1.413T18 20z"
                    />
                  </Icon>
                </InputRightElement>

                {/* 簽名按鈕，只有學校設定需家長簽名、且身份為家長，才會出現簽名按鈕 */}
                {userInfo?.roleType === "parent" &&
                  schoolOtherSettings?.need_parent_approval && (
                    <div className="flex flex-wrap gap-3 items-end">
                      {imageURL && (
                        <div className="mx-auto flex-shrink-0">
                          <img
                            src={imageURL}
                            alt="Signature"
                            className="h-28 mx-auto"
                          />
                        </div>
                      )}
                      <Button
                        onClick={onSignOpen}
                        rightIcon={
                          <Icon
                            w={6}
                            h={6}
                            color="gray.500"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="M9.75 20.85c1.78-.7 1.39-2.63.49-3.85c-.89-1.25-2.12-2.11-3.36-2.94A9.817 9.817 0 0 1 4.54 12c-.28-.33-.85-.94-.27-1.06c.59-.12 1.61.46 2.13.68c.91.38 1.81.82 2.65 1.34l1.01-1.7C8.5 10.23 6.5 9.32 4.64 9.05c-1.06-.16-2.18.06-2.54 1.21c-.32.99.19 1.99.77 2.77c1.37 1.83 3.5 2.71 5.09 4.29c.34.33.75.72.95 1.18c.21.44.16.47-.31.47c-1.24 0-2.79-.97-3.8-1.61l-1.01 1.7c1.53.94 4.09 2.41 5.96 1.79m11.09-15.6c.22-.22.22-.58 0-.79l-1.3-1.3a.562.562 0 0 0-.78 0l-1.02 1.02l2.08 2.08M11 10.92V13h2.08l6.15-6.15l-2.08-2.08z"
                            />
                          </Icon>
                        }
                        className="w-full"
                      >
                        {imageURL ? (t("app.resign")) : (t("app.start_to_sign"))}
                      </Button>
                    </div>
                  )}
              </InputGroup>
          </OrderedList>
        </div>
        <Button 
          isDisabled={!isInputDataReady}
          onClick={submitLeaveApplication}
          leftIcon={<Icon w={6} h={6} color='white' viewBox='0 0 24 24'>
          <path fill="currentColor" d="M7.1 11.35q.35-.7.725-1.35t.825-1.3l-1.4-.275l-2.1 2.1zm12.05-6.875q-1.75.05-3.737 1.025T11.8 8.1q-1.05 1.05-1.875 2.25T8.7 12.6l2.85 2.825q1.05-.4 2.25-1.225t2.25-1.875q1.625-1.625 2.6-3.6T19.675 5q0-.1-.038-.2t-.112-.175q-.075-.075-.175-.112t-.2-.038m-5.5 6q-.575-.575-.575-1.412t.575-1.413q.575-.575 1.425-.575t1.425.575q.575.575.575 1.413t-.575 1.412q-.575.575-1.425.575t-1.425-.575m-.85 6.55L13.625 19l2.1-2.1l-.275-1.4q-.65.45-1.3.813t-1.35.712m8.775-13.35q.2 2.75-.9 5.363T17.2 14.025l.5 2.475q.1.5-.05.975t-.5.825L14 21.45q-.375.375-.9.288t-.725-.588l-1.525-3.575L6.575 13.3L3 11.775q-.5-.2-.6-.725t.275-.9L5.825 7q.35-.35.837-.5t.988-.05l2.475.5q2.375-2.375 4.988-3.475t5.362-.9q.2.025.4.113t.35.237q.15.15.238.35t.112.4m-17.65 12.3q.875-.875 2.138-.887t2.137.862q.875.875.863 2.138t-.888 2.137q-1.2 1.2-2.838 1.425t-3.287.45l.45-3.287q.225-1.637 1.425-2.838m1.425 1.4q-.425.425-.587 1.025T4.5 19.625q.625-.1 1.225-.25T6.75 18.8q.3-.3.325-.725T6.8 17.35q-.3-.3-.725-.288t-.725.313" />
          </Icon>} colorScheme='twitter' className='my-5 w-full'>
            { (userInfo?.roleType === 'student' && schoolOtherSettings.need_parent_approval) ? (i18n.language === "en" ? "Send to parent to sign" : "傳送給家長簽名" ) : (i18n.language === "en" ? "Send Leave Application" : "送出假單" )}
        </Button>
      </div>

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className='!font-normal'>{t("app.parent_sing_info")}</ModalHeader>
          <ModalCloseButton />

          <ModalBody pb={6}>
            <div className='h-52 w-full border border-gray-200'>
              <SignatureCanvas penColor='blue' ref={sigCanvas}
                canvasProps={{ className: 'sigCanvas w-full h-52' }} />
            </div>
          </ModalBody>

          <ModalFooter className='gap-4'>
            <Button colorScheme='twitter' variant='outline' className="mr-auto w-full" onClick={clear}>{t("app.clear")}</Button>
            <Button colorScheme='twitter' className="w-full" onClick={() => { save(); onClose(); }}>{t("app.confirm")}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

       {/* 顯示載入中的視窗 */}
       <LoadingDialog isOpen={showLoading} />

    </>
  )
}


/**
 * 一筆缺框紀錄的日期與節次
 * @param param0 
 * @returns 
 */
function AbsenceLeaveDatePeriod( {item }: { item: AbsenceLeaveRequestItemInfo; }) {
  const { t, i18n } = useTranslation("translation");
  return (
    <div>
      <div className="!mt-4 mb-3">
        <Tag size='md' colorScheme='red' className="justify-center w-28 !mt-0">{dayjs(item.rawAbsRec.OccurDate).format('YYYY/MM/DD')}</Tag>
        <span className="text-sm ms-4">{i18n.language === "en" ? "" : "共"} {item.rawAbsRec.AbsenceDetail.length} {i18n.language === "en" ? "sessions in total" : "節"}</span>
      </div>
      <div className="!mt-2">
        <Stack align='center' direction='row' className="flex-wrap session">
          { item.rawAbsRec.AbsenceDetail.map( p => (
            <Switch key={p.Period} size='lg' colorScheme='twitter' content={p.Period} className="switch-txt" isDisabled defaultChecked />
          )) }
        </Stack>
      </div>
    </div>
  );
}
