import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Card, Checkbox, Collapse, Divider, Icon, IconButton, Input, InputGroup, InputLeftElement, InputRightElement, ListItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, OrderedList, Select, Stack, StackDivider, Switch, Tab, TabList, TabPanel, TabPanels, Tabs, Tag, Textarea, useDisclosure } from "@chakra-ui/react"
import { AddIcon, DownloadIcon, InfoOutlineIcon, PlusSquareIcon, Search2Icon, SmallAddIcon } from "@chakra-ui/icons";
import {useEffect, useState } from "react";
import dayjs from "dayjs";
import { Util } from "../dal/util";
import config  from '../config';
import { LeaveApplicationHelper } from "../dal/leave_application_helper";
import DatePicker, { DateObject } from "react-multi-date-picker";
import taiwan_zh from "../taiwan_zh";
import InputIcon from "react-multi-date-picker/components/input_icon";
import { LeaveStudent, SchoolDate, SchoolOtherSettings } from "../dal/value_object";
import { SelectStuModal } from "./Modals/SelectStuModal";
import { FileStorageHelper } from "../dal/file_storage_helper";
import LeaveDatePeriod from "../pages/StudentPage/InAdvance/LeaveDatePeriod";
import { AbsenceTypeHelper } from "../dal/absence_type_helper";
import { SchoolPeriodHelper } from "../dal/school_period_helper";
import { SchoolDateHelper } from "../dal/school_date_helper";
import { SchoolOtherSettingsHelper } from "../dal/school_other_settings_helper";

interface StuLeaveDetailProps {

}

export function StuOfficialDetail() 
{

  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();

  const [selectedLappStu, setSelectedLappStu] = useState<LeaveStudent[]>([]); // 選擇請假學生

  // ====== 學校設定值 =====
  const [absenceTypes, setAbsenceTypes] = useState<string[]>([]); // 學校在本學期允許請假的假別
  const [allowedPeriods, setAllowedPeriods] = useState<string[]>([]); // 學校在本學期允許請假的節次
  const [schoolDates, setSchoolDates] = useState<SchoolDate[]>([]); // 學校在本學期定義的上課日
  const [schoolOtherSettings, setSchoolOtherSettings] =
  useState<SchoolOtherSettings | null>(null); // 學校其他設定
  const [isSettingReady, setIsSettingReady] = useState<boolean>(false); // 學校是否已經完成設定

  // ===== 紀錄使用者操作 =====
  const [isDialogBoxOpened, setIsDialogBoxOpened] = useState<boolean>(false); // 是否開啟提示的對話方塊
  const todayString = dayjs().format('YYYY/MM/DD')
  const [selectedMultipleDates, setSelectedMultipleDates] = useState<any[]>([]); // 日期控制項的 controled component 
  const [selectedDates, setSelectedDates] = useState<string[]>([]); // 使用者選擇要請假的日期
  const [selectedFile, setSelectedFile] = useState<File | null>(null); // 暫存使用者選擇的附件
  const [leaveDatePeriods, setLeaveDatePeriods] = useState<{
    [dt: string]: string[];
  }>({}); // 暫存使用者已選擇要請假的日期與節次
  const [absReason, setAbsReason] = useState<string | null>(""); // 暫存請假理由
  const [isInputDataReady, setIsInputDataReady] = useState<boolean>(false); // 請假填寫資料是否已齊備可送出
  const [showLoading, setShowLoading] = useState<boolean>(false); // 是否顯示載入中圖示的視窗

  
  /** 處理刪除已選擇學生 */
  const handleDelStu = (stu: LeaveStudent) => () => {
    setSelectedLappStu(selectedLappStu.filter((s) => s.studentID !== stu.studentID));
  }

  const handleDateChange = (e: DateObject[]) => {
    const dates = findValidDates(e);
    setSelectedDates(dates); // 紀錄使用者選取的有效日期清單

    setLeaveDatePeriods({}); // 清空選取的日期與節次
  };

  /**
   * 檢查找出有效的日期(多筆單日)
   * 如果學校有定義上課日，則以上課日為主，否則就檢查排除六日
   * */
  const findValidDates = (chkDates: DateObject[]): string[] => {
    const result: string[] = [];
    chkDates.forEach((d) => {
      const targetD = dayjs(d.toString());
      let isValidDate = false;
      if (schoolDates.length > 0) {
        const td = schoolDates.find(
          (sd) =>
            targetD.format("YYYY/MM/DD") === dayjs(sd.d).format("YYYY/MM/DD")
        );
        isValidDate = !!td;
      } else {
        // 學校沒有定義上課日，就直接排除六日
        isValidDate = targetD.day() !== 0 && targetD.day() !== 6;
      }
      // 如果是有效的上課日，才讓使用者進行請假
      if (isValidDate) {
        result.push(targetD.format("YYYY/MM/DD"));
      }
    });
    return result;
  };

  /** 處理使用者選擇某一天要請假的節次後 */
  const handleDatePeriodChanged = (dt: string, periods: string[]) => {
    console.log({ dt, periods });
    const isRightDate = selectedDates.find((sd) => sd === dt);
    if (isRightDate) {
      const tempLeaveDatePeriod = { ...leaveDatePeriods };
      tempLeaveDatePeriod[dt] = periods;
      setLeaveDatePeriods(tempLeaveDatePeriod);
    }
  };

  /** 暫存使用者選取的檔案 */
  const handleAttachFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedFile(e.target.files[0]);
    }
  };

  /** 上傳檔案 */
  const uploadFile = async (file: File | null) => {
    if (!file) return;
    const attInfo = await FileStorageHelper.uploadFile(file);
    console.log(attInfo);
    return attInfo;
  };

  const makeLeaveDateAndPeriodPayload = () => {
    const result = selectedDates.map((sd) => ({
      d: sd,
      periods: leaveDatePeriods[sd],
    }));
  }

  const handleAbsReasonChanged = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setAbsReason(e.target.value);
  };

  /** 檢查使用者是否已輸入所有必要資訊 */
  const checkData = () => {
    console.log(" checkData() ...");

    let result = true;
    // 1. 是否選取學生
    result = result && selectedLappStu.length > 0;
    // 2. 是否選取日期
    result = result && selectedDates.length > 0;

    // 3. 是否有日期沒有選節次
    selectedDates.forEach((sd) => {
      result =
        result && !!leaveDatePeriods[sd] && leaveDatePeriods[sd].length > 0;
    });

    // 4. 是否輸入請假原因
    result = result && !!absReason;   

    setIsInputDataReady(result);

    return result;
  };

  /** 送出申請單 */
  const submitLeaveApplication = async () => {
    console.log("submit application");
    setShowLoading(true);

    // 確認附件
    const attInfo = await uploadFile(selectedFile);

    // 送出假單
    const data = {
      absence_type: (schoolOtherSettings) ? schoolOtherSettings.public_leave_name : '公假',
      leaveDateAndPeriod: makeLeaveDateAndPeriodPayload(),
      reason: absReason,
      need_parent_signature: false,
      attachment_info: attInfo,
      students: selectedLappStu
    };
    // console.log( { data });
    const resp = await LeaveApplicationHelper.send(data);

    setShowLoading(false);
  };

  /** 讀取資料 */
  const loadData = async () => {
    const p1 = AbsenceTypeHelper.getAllowedAbsenceTypes();
    const p2 = SchoolPeriodHelper.getAllowedPeriods();
    const p3 = SchoolDateHelper.getDatesBySemester();
    const p4 = SchoolOtherSettingsHelper.get();

    Promise.all([p1, p2, p3, p4]).then((values) => {
      console.log({ values });
      setAbsenceTypes(values[0]);
      setAllowedPeriods(values[1]);
      setSchoolDates(values[2]);
      setSchoolOtherSettings(values[3]);

      // console.log({ settings: values[3]})
      // 檢查學校需設定資料是否齊全
      let isReady =
        values[0].length > 0 &&
        values[1].length > 0 &&
        (!!values[3] ? !!values[3].id : false);
      setIsSettingReady(isReady);
      setIsDialogBoxOpened(!isReady);
    });
  };

  useEffect(() => {
    loadData();
   // handleDateChange([new DateObject()])   
  },[])

    /** 當這些變數有變動時 */
    useEffect(() => {
      checkData();
  
    }, [selectedDates, leaveDatePeriods, absReason]);

  return (
    <>
      <div className="bg-slate-100 w-[45rem] relative">
      <Card id="main" className='h-teach-r !rounded-2xl mt-8 mx-4 p-4 overflow-auto'>
            {/* <CheckDetail /> */}
            <Button colorScheme='blue' variant='ghost' size='md' className="!flex md:!hidden w-fit !ps-1 mb-2"
              leftIcon={
                <Icon w={6} h={6} color='blue.500' viewBox='0 0 24 24'>
                  <g fill="none" fillRule="evenodd">
                    <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                    <path fill="currentColor" d="M8.293 12.707a1 1 0 0 1 0-1.414l5.657-5.657a1 1 0 1 1 1.414 1.414L10.414 12l4.95 4.95a1 1 0 0 1-1.414 1.414z" />
                  </g>
                </Icon>}>
              <span>返回請假主頁</span>
            </Button>
            <div className='text-left p-4'>
              <OrderedList spacing={3}>
                <ListItem className="font-semibold">請假學生
                  {/* <span className="text-sm text-red-500 mx-2">未選學生</span> */}
                  <span className="text-sm mx-4">已選取{selectedLappStu.length}位</span>
                  {/* <Button onClick={onNameOpen} rightIcon={<PlusSquareIcon />}>選取學生</Button> */}
                  <IconButton aria-label='Add Student' icon={<AddIcon />} onClick={onModalOpen} />
                </ListItem>
                <div className="grid grid-cols-2 lg:grid-cols-3 gap-2 break-keep -mx-5 !mt-2">
                  {selectedLappStu.map((stu, index) => (
                    <div key={index} className="flex items-center group/item hover:bg-slate-100 rounded-md w-fit px-2">
                      <div>{stu.className} {stu.studentNumber} {stu.name}</div>
                      <div className="group/edit invisible group-hover/item:visible cursor-pointer ms-2" onClick={handleDelStu(stu)}>
                        <svg fill="none" viewBox="0 0 24 24" width="20" stroke="currentColor" className="group-hover/edit:text-gray-700">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" className="text-red-500"></path>
                        </svg>
                      </div>
                    </div>
                  ))}                  
                </div>
                <ListItem className="font-semibold">請假日期</ListItem>
                <div className="!mt-2 pr-4">
                  <InputGroup>
                    <DatePicker 
                      multiple
                      locale={taiwan_zh} 
                      render={<InputIcon/>}
                      value={selectedMultipleDates}
                      onChange={handleDateChange}
                    />
                    {/* <InputRightElement>
                      <Icon w={6} h={6} color='gray.500' viewBox='0 0 24 24'>
                        <g fill="none"><rect width="18" height="15" x="3" y="6" stroke="currentColor" rx="2" /><path fill="currentColor" d="M3 10c0-1.886 0-2.828.586-3.414C4.172 6 5.114 6 7 6h10c1.886 0 2.828 0 3.414.586C21 7.172 21 8.114 21 10z" /><path stroke="currentColor" strokeLinecap="round" d="M7 3v3m10-3v3" /><rect width="4" height="2" x="7" y="12" fill="currentColor" rx=".5" /><rect width="4" height="2" x="7" y="16" fill="currentColor" rx=".5" /><rect width="4" height="2" x="13" y="12" fill="currentColor" rx=".5" /><rect width="4" height="2" x="13" y="16" fill="currentColor" rx=".5" /></g>
                      </Icon>
                    </InputRightElement> */}
                  </InputGroup>
                </div>
                <ListItem className="font-semibold">
                  請假節次 ({selectedDates.length}天)
                </ListItem>
                {selectedDates.map((sd) => (
                <LeaveDatePeriod
                  key={sd}
                  targetDate={sd}
                  allowPeriods={allowedPeriods}
                  onSelected={handleDatePeriodChanged}
                />
                ))}


                <ListItem className="font-semibold">公假事由</ListItem>
                <div className='pr-4'>
                  <Textarea placeholder='請輸入請假原因'  onChange={handleAbsReasonChanged}/>
                </div>
                <ListItem className="font-semibold">上傳附件</ListItem>
                <InputGroup className="!grid grid-cols-1 gap-4 w-auto pr-4">
                <Input
                  type="file"
                  placeholder="Enter amount"
                  className="w-full pt-2 z-10"
                  accept=".jpg, .jpeg, .png, .gif, .pdf"
                  onChange={handleAttachFileChange}
                />
                <InputRightElement className="!right-4">
                  <Icon w={6} h={6} color="gray.500" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M11 16V7.85l-2.6 2.6L7 9l5-5l5 5l-1.4 1.45l-2.6-2.6V16zm-5 4q-.825 0-1.412-.587T4 18v-3h2v3h12v-3h2v3q0 .825-.587 1.413T18 20z"
                    />
                  </Icon>
                </InputRightElement>

                </InputGroup>
              </OrderedList>
              <Button 
                leftIcon={
                  <Icon w={6} h={6} color='white' viewBox='0 0 24 24'>
                    <path fill="currentColor" d="M7.1 11.35q.35-.7.725-1.35t.825-1.3l-1.4-.275l-2.1 2.1zm12.05-6.875q-1.75.05-3.737 1.025T11.8 8.1q-1.05 1.05-1.875 2.25T8.7 12.6l2.85 2.825q1.05-.4 2.25-1.225t2.25-1.875q1.625-1.625 2.6-3.6T19.675 5q0-.1-.038-.2t-.112-.175q-.075-.075-.175-.112t-.2-.038m-5.5 6q-.575-.575-.575-1.412t.575-1.413q.575-.575 1.425-.575t1.425.575q.575.575.575 1.413t-.575 1.412q-.575.575-1.425.575t-1.425-.575m-.85 6.55L13.625 19l2.1-2.1l-.275-1.4q-.65.45-1.3.813t-1.35.712m8.775-13.35q.2 2.75-.9 5.363T17.2 14.025l.5 2.475q.1.5-.05.975t-.5.825L14 21.45q-.375.375-.9.288t-.725-.588l-1.525-3.575L6.575 13.3L3 11.775q-.5-.2-.6-.725t.275-.9L5.825 7q.35-.35.837-.5t.988-.05l2.475.5q2.375-2.375 4.988-3.475t5.362-.9q.2.025.4.113t.35.237q.15.15.238.35t.112.4m-17.65 12.3q.875-.875 2.138-.887t2.137.862q.875.875.863 2.138t-.888 2.137q-1.2 1.2-2.838 1.425t-3.287.45l.45-3.287q.225-1.637 1.425-2.838m1.425 1.4q-.425.425-.587 1.025T4.5 19.625q.625-.1 1.225-.25T6.75 18.8q.3-.3.325-.725T6.8 17.35q-.3-.3-.725-.288t-.725.313" />
                  </Icon>
                } 
                colorScheme='twitter' 
                className='w-full my-8'
                onClick={submitLeaveApplication}
                isDisabled={!isInputDataReady}
              >
                  送出假單
              </Button>
            </div>

          </Card>
      </div>
      <SelectStuModal isModalOpen={isModalOpen} onModalClose={onModalClose} setSelectedStu={setSelectedLappStu} selectedStu={selectedLappStu} />

    </>
  );
}
