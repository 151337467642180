import React, { SetStateAction, useEffect, useState } from "react"
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Card, Checkbox, Collapse, Divider, Icon, IconButton, Input, InputGroup, InputLeftElement, InputRightElement, ListItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, OrderedList, Select, Stack, StackDivider, Switch, Tab, TabList, TabPanel, TabPanels, Tabs, Tag, Textarea, useDisclosure } from "@chakra-ui/react"
import { AddIcon, DownloadIcon, InfoOutlineIcon, PlusSquareIcon, Search2Icon, SmallAddIcon } from "@chakra-ui/icons";
import { CheckCourse } from "./CheckCourse";
import { Link } from "react-router-dom";
import DatePicker from "react-multi-date-picker";
import taiwan_zh from "../../taiwan_zh";
import { PdfModal } from "../../components/Modals/PdfModal";
import { Util } from "../../dal/util";
import { LeaveApplicationHelper } from "../../dal/leave_application_helper";
import { useUserContext } from "../../components/auth/user-validation";

interface OfficialDetailProps {
  OfficialLeaveApp: any;
  setSelectedLeaveApp: ((date: any) => void);
  handleShowDetail: (date: boolean) => void;
}

export const OfficialDetail = ({OfficialLeaveApp,setSelectedLeaveApp,handleShowDetail}:OfficialDetailProps) => {
  const { isOpen: isRuleOpen, onOpen: onRuleOpen, onClose: onRuleClose } = useDisclosure();
  const [selectedLeaveApps , setSelectedLeaveApps] = useState<any[]>([]);  // 篩選後的請假紀錄

  const [activeButton, setActiveButton] = useState('progressing');
  const [ keyword, setKeyword] = useState<string>();

  const { userInfo } = useUserContext();

  const handleButtonClick = (buttonText: SetStateAction<string>) => {
    setActiveButton(buttonText);
    setKeyword('')  // 清除關鍵字
    if (buttonText === 'all') {
     // setSelectedLeaveApps([ ...leaveApplications ]);
      //setSelectedLeaveApp(leaveApplications[0]);
      setSelectedLeaveApp(OfficialLeaveApp[0]);
      handleShowDetail(false);
    } else {
      //const temp = leaveApplications.filter( lapp => lapp.status === buttonText);
      //console.log( { buttonText, temp })
      const temp = OfficialLeaveApp.filter((lapp:any) => lapp.status === buttonText);
      setSelectedLeaveApps( [...temp]);
      setSelectedLeaveApp(temp[0]);
      handleShowDetail(false);
  }
};
  /** 關鍵字搜尋 */
  const findByKeyword = (keyword: string) => {

    setKeyword(keyword);
    if (keyword === '') {
      const temp = OfficialLeaveApp.filter((lapp:any) => lapp.status === activeButton);
      setSelectedLeaveApps( [...temp]);
      setSelectedLeaveApp(temp[0]);
      return;
    }
    const filterBase=OfficialLeaveApp.filter((lapp:any) => lapp.status === activeButton);
    const result = LeaveApplicationHelper.filter(filterBase, keyword);

    setSelectedLeaveApps([...result]);
    setSelectedLeaveApp(result[0]);
  }

  const { isOpen, onToggle } = useDisclosure();

   useEffect(() => {

   const temp = OfficialLeaveApp.filter((lapp:any) => lapp.status === activeButton);
   setSelectedLeaveApps( [...temp]);
   setSelectedLeaveApp(temp[0]);  

}, [OfficialLeaveApp]);


  useEffect(() => {
    const temp = OfficialLeaveApp.filter((lapp:any) => lapp.status === activeButton);
    setSelectedLeaveApps( [...temp]);
    setSelectedLeaveApp(temp[0]);
  }, []);


  return (
    <>
      <div className='mb-4'>
          <Stack direction='row' align='center' className="justify-between">
            <Button size='md' colorScheme='pink' variant='ghost'
              className={`w-20 !h-8 !text-sm ${activeButton === '新增' && 'active'}`}
              // onClick={() => { handleButtonClick('新增'); }}
            >
              + 新增
            </Button>
            <Button size='md'
              className={`w-20 !h-8 !text-sm !text-white bg-ing ${activeButton === 'progressing' && 'active'}`}
              onClick={() => handleButtonClick('progressing')}>
              申請中
            </Button>
            <Button size='md'
              className={`w-20 !h-8 !text-sm !text-white bg-return ${activeButton === 'rejected' && 'active'}`}
              onClick={() => handleButtonClick('rejected')}>
              退回
            </Button>
            <Button size='md'
              className={`w-20 !h-8 !text-sm !text-white bg-ed ${activeButton === 'finished' && 'active'}`}
              onClick={() => handleButtonClick('finished')}>
              已完成
            </Button>
            <DownloadIcon w={8} h={8} className="cursor-pointer border border-gray-300 rounded-md p-1" />
            <Search2Icon w={6} h={6} className="cursor-pointer" onClick={onToggle} />
          </Stack>
      </div>
      <Collapse in={isOpen}>
        <InputGroup className="mb-4">
          <InputLeftElement pointerEvents='none'>
            <Search2Icon color='gray.300' />
          </InputLeftElement>
          <Input type='text' placeholder=''  value={keyword} onChange={ (e) => findByKeyword(e.target.value)} borderColor='gray.400' />
        </InputGroup>
      </Collapse>
      <div className='flex justify-between items-center mb-4 h-10'>
        <div className='flex gap-3'>
           {/* <Select variant='flushed' placeholder='' className='!w-auto'>
              <option value='112'>112 學年度</option>
              <option value='111'>111 學年度</option>
              <option value='110'>110 學年度</option>
            </Select>
            <Select variant='flushed' placeholder='' className='!w-auto'>
              <option value='1'>第1學期</option>
              <option value='2'>第2學期</option>
            </Select> */}            
            <span className="ml-2">{userInfo?.schoolYear}學年度</span>
            <span className="ml-1">第{userInfo?.semester}學期</span>
        </div>
        <div onClick={onRuleOpen} className="cursor-pointer">
          <InfoOutlineIcon />
          <span className='ms-1 align-middle'>請假規定</span>
        </div>
      </div>

      <Card className='h-teach-l2 overflow-auto !rounded-2xl'>
        {
          (selectedLeaveApps.length === 0) ?
          <div className='flex flex-col justify-center items-center h-[500px]'>
            <img src="/assets/img/nodata.png" alt="" className='w-32 mx-auto mb-4' />
            <div>沒有資料</div>
          </div> :         
          <Stack divider={<StackDivider />} spacing='2'>
            {selectedLeaveApps.map((leaveApp:any,index:number)  => 
              {
                const statusObj=Util.calculateProgressText(leaveApp);
                const leaveAppPeriods=leaveApp.leaveDateAndPeriod;
                const approveHistory=leaveApp.approveHistory;
                return ( leaveAppPeriods && 
                  <div className='flex items-baseline p-3' key={index}>
                    <div className='me-3'>
                      <Tag size='sm' className={'!text-white w-[3.25rem] justify-center '+statusObj.progressTextCSS}>{statusObj.progressText}</Tag>
                    </div>
                    <div className="cursor-pointer" onClick={() => {setSelectedLeaveApp(leaveApp); handleShowDetail(true)}}>
                      <div className='text-left'>
                        {
                          leaveAppPeriods.map((leaveAppPeriod:any,index:number) => {
                            return (
                              <div key={index}>
                                <div className='text-xl font-semibold'>{leaveAppPeriod.d}</div>
                                <div>{leaveAppPeriod.periods.join(",")}</div>
                              </div>
                            )
                          })
                        }
                      </div>
                      <div className='flex items-baseline gap-x-2'>
                        <div className='text-left'>
                          <div className="flex mt-1">
                            <div className="inline-block flex-shrink-0 font-semibold w-16 me-3" style={{ textAlignLast: 'justify' }}>事由</div>
                            <div>{leaveApp.reason}</div>
                          </div>
                          { approveHistory && approveHistory.map((approve:any,index:number) => {
                            return (
                              <div className='mt-1' key={index}><span className="inline-block font-semibold w-16 me-3" style={{ textAlignLast: 'justify' }}>{Util.convertApproveToText(approve.status)}</span>{approve.stage_name}</div>
                            )                    
                          })
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
              )}
            <div className='text-sm text-center p-5'>
              系統僅顯示當學期資料
            </div>
          </Stack>
          }
      </Card>         


      <PdfModal 
        isModalOpen={isRuleOpen}
        onModalClose={onRuleClose} 
        pdfUrl="./assets/pdf/rule.pdf"
        title="請假規定"
      />

    </>
  )
}