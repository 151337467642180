import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useDisclosure,
  Button,
} from "@chakra-ui/react";

import React, { useEffect, useState } from "react";

export function DialogBox({
  isOpen = false ,
  title,
  msg,
  showCancelButton = true,
  closeButtonText = "Cancel",
  okButtonText = "OK",
  onOK = undefined,
}: {
  isOpen: boolean;
  title: string;
  msg: string;
  showCancelButton: boolean;
  closeButtonText: string;
  okButtonText: string;
  onOK: undefined | (() => void); // 不指定就預設關閉
}) {
  const { onOpen } = useDisclosure();
  const cancelRef = React.useRef(null);

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const dialogWidth = (window.innerWidth > 600) ? 500 : (window.innerWidth - 32);

  useEffect(() => {
    setIsDialogOpen(isOpen);
  }, [isOpen]);

  const onSave = () => {
    onOK && onOK();
    setIsDialogOpen(false);
  };

  const onCancel = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <AlertDialog
        isOpen={isDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCancel}
      >
        <AlertDialogOverlay className="">
          <AlertDialogContent  maxW={dialogWidth} >
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title}
            </AlertDialogHeader>

            <AlertDialogBody>{msg}</AlertDialogBody> 

            <AlertDialogFooter>
              {showCancelButton && (
                <Button ref={cancelRef} onClick={onCancel}>
                  {closeButtonText}
                </Button>
              )}
              <Button colorScheme="red" onClick={onSave} ml={3}>
                {okButtonText}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
