import { Box, Button, Icon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Tag, useDisclosure } from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";

import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { LeaveDetail } from "../pages/StudentPage/LeaveDetail";
import { Util } from "../dal/util";
import { useTranslation } from "react-i18next";

export function LeaveApplicationItem({
  leaveApplicationRecord = null,
  onShowDetail = undefined,
  setReload = undefined
}: {
  leaveApplicationRecord: any;
  onShowDetail: undefined | ((data: any) => void); // 不指定就預設關閉
  setReload: undefined | ((data: any) => void); // 預定義重新載入
}) {
  const [year, setYear] = useState<string>();
  const [bd, setBD] = useState<string>(); // 開始日期
  const [ed, setED] = useState<string>(); // 結束日期
  const [isManyDates, setIsManyDates] = useState<boolean>(false); // 這張假單請假日數是否多日天（還是單日）
  const [periodCount, setPeriodCount] = useState<number>(0); // 請假總節數
  const emptyObj = {
    status: "",
    progressText: "",
    progressTextEn: "",
    progressTextCSS: "",
    statusText: "",
    statusTextEn: "",
    statusTextCSS: "",
  };
  const [otherData, setOtherData] = useState<any>(emptyObj);
  const {
    isOpen: isLeaveDetailOpen,
    onOpen: onLeaveDetailOpen,
    onClose: onLeaveDetailClose,
  } = useDisclosure();
  const { t, i18n } = useTranslation("translation");

  useEffect(() => {
    const leaveDatePeriods: any[] = leaveApplicationRecord.leaveDateAndPeriod || [];
    if (leaveDatePeriods.length > 0) {
      setIsManyDates(leaveDatePeriods.length > 1);
      const firstDate = dayjs(leaveDatePeriods[0].d);
      const lastDate = dayjs(leaveDatePeriods[leaveDatePeriods.length - 1].d);
      setYear(firstDate.year().toString());
      setBD(firstDate.format("MM/DD"));
      setED(lastDate.format("MM/DD"));
      let pCount = 0;
      leaveDatePeriods.forEach((lp) => {
        pCount += lp.periods.length;
      });
      // const pCount = leaveDatePeriods.reduce( (result, lp) => {
      //     return result + lp.periods.length ;
      // });
      setPeriodCount(pCount);

      const od = Util.calculateProgressText(leaveApplicationRecord);
      setOtherData(od);
    }
  }, [leaveApplicationRecord]);

  /*const calculateProgressText = (leaveApplicationRecord: any) => {
    const status: string = leaveApplicationRecord.status;
    const result = {
      ...emptyObj,
      status
    };

    try {
      if (status === "parent_sign" || status === "progressing") {
        result.progressText = "申請中";
        result.progressTextCSS = "bg-ing";
        if (status === "parent_sign") {
          result.statusText = "待家長簽名";
          result.statusTextCSS = "text-red-500";
        } else {
          result.statusText = `待${leaveApplicationRecord.current_stage_name}簽核`;
          result.statusTextCSS = "";
        }
      } else if (status === "rejected" || status === "canceled") {
        result.progressTextCSS = "bg-return";
        result.progressText = "退回";
        if (status === "rejected") {
          const targetStage = leaveApplicationRecord.approveHistory.find(
            (stage: any) => stage.status === status
          );
          result.statusText = targetStage
            ? `${targetStage.stage_name}未核准`
            : "未核准";
        } else {
          result.statusText = "已抽單";
        }
      } else if (status === "finished") {
        result.progressText = "已完成";
        result.progressTextCSS = "";
      } else if (status === "canceled") {
        result.progressText = "已撤單";
        result.progressTextCSS = "";
      }
    } catch (ex) {
      console.log({ ex });
    }

    return result;
  };*/

  const onItemClick = () => {
    onShowDetail && onShowDetail(leaveApplicationRecord);
    return (
      <LeaveDetail leaveAppRec={leaveApplicationRecord} onLeaveClose={onLeaveDetailClose} />
    )
  };

  const handleLevelDetailClose = () => {
    onLeaveDetailClose();
    setReload && setReload(true);
  }

  return (
    <>
      <Box className="p-3 pb-1 cursor-pointer" onClick={onLeaveDetailOpen}>
        <div className="flex items-baseline gap-x-2">
          <div className="flex-shrink-0 whitespace-nowrap">
            <div className="text-lg">{year}</div>
            <div>{bd}</div>
            {isManyDates && (
              <>
                <div>~</div>
                <div>{ed}</div>
              </>
            )}
          </div>
          <div className="">
            <div className="text-left">
              <Tag
                size="md"
                className={`justify-center me-2 whitespace-nowrap ${otherData.progressTextCSS}`}
              >
                {i18n.language === "en"
                  ? otherData.progressTextEn
                  : otherData.progressText}
              </Tag>
              {(otherData.status === "progressing" ||
                otherData.status === "parent_sign") && (
                <span className="text-xl text-red-500 font-semibold">
                  {i18n.language === "en"
                    ? otherData.statusTextEn
                    : otherData.statusText}
                </span>
              )}
            </div>
            <div className="text-justify">
              {leaveApplicationRecord.absence_type} {periodCount} {t("app.session")}
            </div>
          </div>
        </div>
        <div className="text-end -mt-6">
          <ChevronRightIcon
            boxSize={6}
            color="blue.500"
            className="cursor-pointer"          
          />
        </div>
      </Box>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isLeaveDetailOpen}
        onClose={onLeaveDetailClose}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className="!pb-0">
            <div className="grid grid-cols-3 items-center">
              <Button
                colorScheme="blue"
                variant="ghost"
                size="md"
                className="w-6 me-auto"
                onClick={onLeaveDetailClose}
                leftIcon={
                  <Icon w={6} h={6} color="blue.500" viewBox="0 0 24 24">
                    <g fill="none" fillRule="evenodd">
                      <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                      <path
                        fill="currentColor"
                        d="M8.293 12.707a1 1 0 0 1 0-1.414l5.657-5.657a1 1 0 1 1 1.414 1.414L10.414 12l4.95 4.95a1 1 0 0 1-1.414 1.414z"
                      />
                    </g>
                  </Icon>
                }
              ></Button>
              <div className="font-semibold text-center whitespace-nowrap">
                {t("app.leave_history")}
              </div>
              <ModalCloseButton className="!static ml-auto" />
            </div>
          </ModalHeader>
          <ModalBody className="!px-4">
            <LeaveDetail leaveAppRec={leaveApplicationRecord} onLeaveClose={()=>handleLevelDetailClose()}/>
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  );
}
