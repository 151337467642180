import React, { createContext, useContext, useState, ReactNode } from 'react';
import { school } from '../dal/value_object';

interface SchoolContextType {
  selectedSchool: school | null;
  setSelectedSchool: (school: school | null) => void;
}

const SchoolContext = createContext<SchoolContextType | undefined>(undefined);

export const useSchoolContext = (): SchoolContextType => {
  const context = useContext(SchoolContext);
  if (!context) {
    throw new Error('useSchoolContext must be used within a SchoolProvider');
  }
  return context;
};

interface SchoolProviderProps {
  children: ReactNode;
}

export const SchoolProvider: React.FC<SchoolProviderProps> = ({ children }) => {
  const [selectedSchool, setSelectedSchool] = useState<school | null>(null);

  return (
    <SchoolContext.Provider value={{ selectedSchool, setSelectedSchool }}>
      {children}
    </SchoolContext.Provider>
  );
};
