import { SchoolPeriodConfig } from "./value_object";

/** 學校定義可請假的缺曠類別的資訊  */
export class SchoolPeriodHelper {

    static async getAllowedPeriods() {
        const resp = await fetch('/service/school_period/get_allowed_periods');
        return await resp.json();
    }

     /**取得學校節次設定 */
     static async getSchoolPeriod(dsns:string): Promise<SchoolPeriodConfig[]> {
        const resp = await fetch('/service/school_period/get_school_period?schooldsns='+dsns);
        const result: SchoolPeriodConfig[] | [] = await resp.json()
        return await result;
    }

    /**更新學校節次設定 */
    static async updateSchoolPeriod(semester:number,periods:string[]) {
            const data = {
            "semester": semester,
            "periods": periods
        }
        const resp = await fetch('/service/school_period/update_school_period', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
        return await resp.json();
    }

    /**新增學校節次設定 */
    static async addSchoolPeriod(semester:number,periods:string[]) {
        const data = {
            "semester": semester,
            "periods": periods
        }
        const resp = await fetch('/service/school_period/insert_school_period', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
        return await resp.json();
    }
    /** 刪除學校節次設定 */
    static async deleteSchoolPeriod(semester:number) {
        const data = {
            "semester": semester
        }
        const resp = await fetch('/service/school_period/delete_school_period', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
        return await resp.json();
    }

}