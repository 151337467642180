const taiwan_zh = {
  name: "taiwan_zh",
  months: [
    ["一月", "Jan"],
    ["二月", "Feb"],
    ["三月", "Mar"],
    ["四月", "Apr"],
    ["五月", "May"],
    ["六月", "Jun"],
    ["七月", "Jul"],
    ["八月", "Aug"],
    ["九月", "Sep"],
    ["十月", "Oct"],
    ["十一月", "Nov"],
    ["十二月", "Dec"],
  ],
  weekDays: [
    ["Saturday", "六"],
    ["Sunday", "日"],
    ["Monday", "一"],
    ["Tuesday", "二"],
    ["Wednesday", "三"],
    ["Thursday", "四"],
    ["Friday", "五"],
  ],
  digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
  meridiems: [
    ["AM", "am"],
    ["PM", "pm"],
  ],
};

export default taiwan_zh
