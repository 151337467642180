import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Checkbox, Divider, Input, InputGroup, InputLeftElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay,Select,Stack,Tab,TabList,TabPanel,TabPanels,Tabs,Tag,Textarea} from "@chakra-ui/react"
import dayjs from "dayjs";
import { Util } from "../../dal/util";
import config  from './../../config';
import { useEffect, useState } from "react";
import { Search2Icon } from "@chakra-ui/icons";
import { DSAAbsenceHelper } from "../../dal/dsa_absence_helper";
import { set } from "date-fns";
import { LeaveStudent, SchoolDate, SchoolOtherSettings } from "../../dal/value_object";
import { DateObject } from "react-multi-date-picker";
import { is } from "date-fns/locale";
import { useUserContext } from "../../components/auth/user-validation";
import { a } from "react-spring";
import { ApprovalClassesHelper } from "../../dal/approval_classes_helper";


interface props {
    isModalOpen:boolean;
    onModalClose:()=>void;
}

interface classItem {
    classID:number,
    className:string,
    gradeYear:number
}

export const SelectClassModal=({isModalOpen,onModalClose}:props)=>{
    const [allClasses, setAllClasses] = useState<classItem[]>([]); // 所有班級
    const [selectedClasses, setSelectedClasses] = useState<string[]>([]); // 已選擇的班級
    const [hasPreload, setHasPreload] = useState(false); // 是否先載入資料
    const { userInfo } = useUserContext();  
   
    const handleSelectedClass = async() => {

        if(hasPreload && selectedClasses.length === 0)
        {
            await ApprovalClassesHelper.updateApprovalClasses([]);            
        }
        else
        {
            await ApprovalClassesHelper.updateApprovalClasses(selectedClasses);
        }
      //  setSelectedClass(selectedClass);
        onModalClose();
    }

    const loadData = async () => {
        if(userInfo?.schoolDsns === undefined) return;
        const res = await DSAAbsenceHelper.getAllClasses(userInfo?.schoolDsns); // 以教師身分取得所有班級
        if(res.class)
        {
            const allClass:classItem[] = [];
            res.class.forEach((classItem:any) => {
                allClass.push({
                    classID: classItem.classID,
                    className: classItem.className,
                    gradeYear: classItem.gradeYear
                });
            });
            setAllClasses(allClass);
        }
        const resp = await ApprovalClassesHelper.getApprovalClasses();
        if(resp)
            {
                const selectedClass:string[] = [];
                resp.forEach((classItem:any) => {
                    selectedClass.push(classItem.class_name);
                });
                if(selectedClass.length>0)
                {
                    setHasPreload(true);
                }
                else
                {
                    setHasPreload(false);
                }

                setSelectedClasses(selectedClass);
        }
    }

    const toggleClass = (classItem: any) => {
        if(selectedClasses.some(item => item === classItem.className)){
            setSelectedClasses(selectedClasses.filter(item => item !== classItem.className));
        }else{
            setSelectedClasses([...selectedClasses, classItem.className]);
        }        
    }

    useEffect(() => {        
        loadData();
    },[])

    useEffect(() => {
        loadData();        
    }
    ,[isModalOpen])

    return(
        <Modal size="xl" closeOnOverlayClick={false} isOpen={isModalOpen} onClose={onModalClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader className='!font-normal'>設定關懷班級</ModalHeader>
                <ModalCloseButton />

                <ModalBody pb={6}>
                    <Tabs>
                    <TabPanels>
                        <TabPanel className="!p-0">
                        <Accordion allowToggle>
                            {
                                (allClasses.length>0) &&  [1,2,3,4,5,6,7,8,9,10,11,12].map(
                                    (grade) => (
                                        (allClasses.filter((classItem) => classItem.gradeYear === grade).length>0) &&
                                        <AccordionItem key={grade}>
                                            <h2>
                                                <AccordionButton>
                                                    <Box as="span" flex='1' textAlign='left'>
                                                        {grade} 年級
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4}>
                                                <Stack pl={6} mt={1} className="!grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 flex-wrap">
                                                    {
                                                        allClasses.filter((classItem) => classItem.gradeYear === grade).map((classItem, index) => (
                                                            <Button
                                                            key={classItem.classID}
                                                            onClick={() => toggleClass(classItem)}
                                                            colorScheme={selectedClasses.some(item => item === classItem.className) ? "blue" : "gray"}
                                                            size="sm"
                                                        >
                                                            {classItem.className} 班
                                                        </Button>
                                                        ))
                                                    }
                                                </Stack>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    )
                                )
                            }     
                        </Accordion>

                        </TabPanel>
                   
                    </TabPanels>
                    </Tabs>
                </ModalBody>

                <ModalFooter className='gap-6'>                 
                   <div className="">已設定關懷班級(
                    {
                        selectedClasses.join(",")
                    })
                    </div>
                    <Button colorScheme='twitter' className="" onClick={()=>handleSelectedClass()} isDisabled={(!hasPreload) && !(selectedClasses.length>0)}>確認</Button>
                </ModalFooter>
            </ModalContent>
      </Modal>
    )
}