import { SetStateAction, useEffect, useState } from 'react';
import { Button, Card, Stack, StackDivider, useDisclosure, Collapse, Input, InputGroup, InputLeftElement, Select, InputRightElement, IconButton, Link } from "@chakra-ui/react";
import { CloseIcon, DownloadIcon, InfoOutlineIcon, Search2Icon } from '@chakra-ui/icons';
import { LeaveApplicationHelper } from '../../dal/leave_application_helper';
import { TeacherApproveHistoryItem } from '../../components/TeacherApproveHistoryItem';
import { PdfModal } from '../../components/Modals/PdfModal';
import { useUserContext } from '../../components/auth/user-validation';
import { LeaveApplicationRecord, SemesterStrInfo } from '../../dal/value_object';
import Excel from 'exceljs';
import FileSaver from 'file-saver';
import dayjs from 'dayjs';
import { Util } from '../../dal/util';
import { PdfZoomModal } from '../../components/Modals/PdfZoomModal';
import { SchoolOtherSettingsHelper } from '../../dal/school_other_settings_helper';
import { useTranslation } from "react-i18next";

interface ApproveHistoryProps {
  ApproveHistoryLeaveApp: LeaveApplicationRecord[];
  setSelectedLeaveApp: ((date: any) => void);
  handleShowDetail: ((date: boolean) => void);
  semesters: SemesterStrInfo[];
}


export const CheckCourse = ({ ApproveHistoryLeaveApp, setSelectedLeaveApp, handleShowDetail, semesters }: ApproveHistoryProps) => {
  const [activeButton, setActiveButton] = useState('all');
  const [leaveApplications, setLeaveApplications] = useState<any[]>([]); // 這位教師審核過的請假單
  const [filteredLeaveApplications, setFilteredLeaveApplications] = useState<any[]>([]); // 這位教師審核過的請假單(篩選過)
  const [selectedLeaveApplications, setSelectedLeaveApplications] = useState<any[]>([]);   // 畫面上要顯示的請假單(關鍵字篩選過)
  const [selectedLeaveApplication, setSelectedLeaveApplication] = useState<any>();   // 請假單詳細資料
  const [currentSelectedLappID, setCurrentSelectedLappID] = useState<any>();  // 目前選擇的請假單ID
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  const [schoolOtherSetting, setSchoolOtherSetting] = useState<any>({}); // 學校的其他設定
  const { t, i18n } = useTranslation("translation");

  //** 監聽螢幕大小 */
  const [applyButtonProps, setApplyButtonProps] = useState(window.innerWidth < 768);

  //監聽視窗大小
  useEffect(() => {
    const handleResize = () => {
      setApplyButtonProps(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);

  const [ruleOpen, setRuleOpen] = useState(false);
  const [keyword, setKeyword] = useState<string>();
  const { userInfo } = useUserContext();
  const [currentSemester, setCurrentSemester] = useState<SemesterStrInfo>(
    {
      school_year: userInfo?.schoolYear.toString() ?? '0',
      semester: userInfo?.semester.toString() ?? '0'
    }
  ); // 目前選擇學年度學期 [學年度-學期]

  const { isOpen, onToggle } = useDisclosure();
  const { isOpen: isRuleOpen, onOpen: onRuleOpen, onClose: onRuleClose } = useDisclosure();

  const handleButtonClick = (buttonText: SetStateAction<string>) => {
    setActiveButton(buttonText);
    setKeyword('')  // 清除關鍵字
    if (buttonText === 'all') {
      setSelectedLeaveApplications([...leaveApplications]);
      setFilteredLeaveApplications([...leaveApplications]);
      //  setSelectedLeaveApp(leaveApplications[0]);
      handleShowDetail(false);
      setSelectedLeaveApp(null)
      setCurrentSelectedLappID(null)

    } else {
      const temp = leaveApplications.filter(lapp => lapp.status === buttonText);
      setSelectedLeaveApplications([...temp]);
      setFilteredLeaveApplications([...temp]);
      //setSelectedLeaveApp(temp[0]);
      handleShowDetail(false);
      setSelectedLeaveApp(null)
      setCurrentSelectedLappID(null)
    }
  };

  const handleSelectedLeaveApp = (lapp: any) => {
    setSelectedLeaveApp(lapp);
    setCurrentSelectedLappID(lapp.id);
  };
  const handleSemsters = () => (e: React.ChangeEvent<HTMLSelectElement>) => {
    const [schoolYear, semester] = e.target.value.split('-');
    setCurrentSemester({
      school_year: schoolYear,
      semester: semester
    });
    const filterResult = ApproveHistoryLeaveApp.filter(lapp => lapp.school_year === parseInt(schoolYear) && lapp.semester === parseInt(semester));
    setFilteredLeaveApplications(filterResult);
    setSelectedLeaveApplications(filterResult);
    setLeaveApplications(filterResult);
    setActiveButton('all');
    setSelectedLeaveApp(null)
    setCurrentSelectedLappID(null)
  }

  /** 關鍵字搜尋 */
  const findByKeyword = (keyword: string) => {
    // console.log( { keyword })
    //  setActiveButton('all');
    setKeyword(keyword);

    const result = LeaveApplicationHelper.filter(filteredLeaveApplications, keyword);
    // console.log( { result })
    setSelectedLeaveApplications([...result]);
    setSelectedLeaveApp(null)
    setCurrentSelectedLappID(null)
  }

  /** 匯出核准歷程*/
  const handleExportExcel = async () => {
    const workbook = new Excel.Workbook();
    const sheet = workbook.addWorksheet(`核准歷程-${currentSemester.school_year}學年度第${currentSemester.semester}學期`);
    sheet.columns = [
      { header: '學號', key: 'studentNumber', width: 15 },
      { header: '年級', key: 'gradeYear', width: 10 },
      { header: '班級', key: 'className', width: 15 },
      { header: '座號', key: 'seatNo', width: 10 },
      { header: '姓名', key: 'name', width: 15 },
      { header: '請假單號', key: 'application_no', width: 20 },
      { header: '請假狀態', key: 'status', width: 15 },
      { header: '請假類型', key: 'absence_type', width: 15 },
      { header: '請假日期', key: 'leaveDate', width: 20 },
      { header: '節次', key: 'periods', width: 40 },
      { header: '事由', key: 'reason', width: 30 },
      { header: '附件', key: 'has_attachment', width: 10 },
      { header: '申請時間', key: 'create_time', width: 20 }
    ];

    let currentRow = 2; // Start from the first row after the header.

    for (const lapp of selectedLeaveApplications) {
      const leaveCount = lapp.leaveDateAndPeriod.length;

      lapp.leaveDateAndPeriod.forEach((leave: any, index: any) => {
        const statusStr: {
          progressText: string;
          progressTextCSS: string;
          statusText: string;
          statusTextCSS: string;
          status: string;
        } = Util.calculateProgressText(lapp);
        const rowValues = {
          studentNumber: lapp.leaveStudents[0]?.studentNumber,
          gradeYear: lapp.leaveStudents[0]?.gradeYear,
          className: lapp.leaveStudents[0]?.className,
          seatNo: lapp.leaveStudents[0]?.seatNo,
          name: lapp.leaveStudents[0]?.name,
          application_no: lapp.application_no,
          status: statusStr.progressText,
          absence_type: lapp.absence_type,
          leaveDate: dayjs(leave.d).format('YYYY/MM/DD'),
          periods: leave.periods.join(', '),
          reason: index === 0 ? lapp.reason : '',
          has_attachment: index === 0 ? (lapp.attachment_info ? '有' : '無') : '',
          create_time: index === 0 ? dayjs(lapp.create_time).format('YYYY/MM/DD HH:mm:ss') : ''
        };
        sheet.addRow(rowValues);
      });

      // Merge cells for rows with multiple leaveDateAndPeriod entries, except for the leaveDate and periods columns.
      if (leaveCount > 1) {
        ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'K', 'L', 'M'].forEach(columnLetter => {
          sheet.mergeCells(`${columnLetter}${currentRow}:${columnLetter}${currentRow + leaveCount - 1}`);
        });
      }
      currentRow += leaveCount;
    }
    sheet.eachRow(function (row, rowNumber) {
      row.eachCell(function (cell, colNumber) {
        if (colNumber === 11 && rowNumber > 1) {
          cell.alignment = { wrapText: true, vertical: 'top', horizontal: 'left' };
        }
        else {
          cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
        }
      });
    });

    await workbook.xlsx.writeBuffer().then(buffer => {
      FileSaver.saveAs(new Blob([buffer]), `核准歷程-${dayjs().format('YYYY/MM/DD HH:mm:ss')}.xlsx`);
    });
  };

  const clearInput = () => {
    findByKeyword(''); // Clear the keyword, assuming findByKeyword updates the keyword state
  };

  const loadOtherSettings = async () => {
    const result = await SchoolOtherSettingsHelper.getSchoolOtherSettings();
    setSchoolOtherSetting(result);
  }

  /** 元件第一次啟動時讀取資料 */
  useEffect(() => {
    // loadData();
    if (currentSemester.school_year !== '0' && currentSemester.semester !== '0') {
      const filterResult = ApproveHistoryLeaveApp.filter(lapp => lapp.school_year === parseInt(currentSemester.school_year) && lapp.semester === parseInt(currentSemester.semester));
      setSelectedLeaveApplications(filterResult);
      setFilteredLeaveApplications(filterResult);
      setLeaveApplications(filterResult);
    }
    else {
      setSelectedLeaveApplications(ApproveHistoryLeaveApp);
      setFilteredLeaveApplications(ApproveHistoryLeaveApp);
      setLeaveApplications(ApproveHistoryLeaveApp);
    }
    setActiveButton('all');
    loadOtherSettings();

  }, [ApproveHistoryLeaveApp]);

  useEffect(() => {
    if (isOpen) {
      findByKeyword('');
    }
    else {
      if (selectedLeaveApplications.length === 0) {
        findByKeyword('');
      }
    }
  }, [isOpen]);

  return (
    <>
      <div className='mb-4'>
        <div className="flex flex-wrap gap-2">
          <div className='flex gap-2'>
            <Button size='md'
              className={`!h-8 !text-sm !text-white !px-2 bg-ing ${activeButton === 'progressing' && 'active'}`}
              onClick={() => handleButtonClick('progressing')} >
              {t("app.pending")}
            </Button>
            <Button size='md'
              className={`!h-8 !text-sm !text-white !px-2 bg-return ${activeButton === 'rejected' && 'active'}`}
              onClick={() => handleButtonClick('rejected')}>
              {t("app.decline")}
            </Button>
            <Button size='md'
              className={`!h-8 !text-sm !text-white !px-2 bg-ed ${activeButton === 'finished' && 'active'}`}
              onClick={() => handleButtonClick('finished')}>
              {t("app.completed")}
            </Button>
            <Button size='md'
              className={`!h-8 !text-sm !text-white !px-2 bg-all ${activeButton === 'all' && 'active'}`}
              onClick={() => handleButtonClick('all')}>
              {t("app.all")}
            </Button>
          </div>
          <div className='flex items-center gap-2 ms-auto'>
            {/* {!applyButtonProps && <DownloadIcon w={8} h={8} className="cursor-pointer border border-gray-300 rounded-md p-1" onClick={()=>handleExportExcel()}/> } */}
            <DownloadIcon w={8} h={8} className="cursor-pointer border border-gray-300 rounded-md p-1" onClick={() => handleExportExcel()} />
            <Search2Icon w={6} h={6} className="cursor-pointer" onClick={onToggle} />
          </div>
        </div>
      </div>
      <Collapse in={isOpen}>
        <InputGroup className="mb-4">
          <InputLeftElement pointerEvents='none'>
            <Search2Icon color='gray.300' />
          </InputLeftElement>
          <Input
            type='text'
            placeholder=''
            borderColor='gray.400'
            value={keyword}
            onChange={(e) => { findByKeyword(e.target.value); }}
          />
          {keyword && (
            <InputRightElement>
              <IconButton
                aria-label="Clear input"
                icon={<CloseIcon />}
                size="sm"
                variant="ghost"
                onClick={clearInput}
              />
            </InputRightElement>
          )}
        </InputGroup>
      </Collapse>
      <div className='flex justify-between items-center mb-4'>
        <div className='flex gap-3'>
          <Select variant='flushed' placeholder='' className='!w-auto' onChange={handleSemsters()}>
            {(semesters.length > 0) &&
              semesters.map((semester, index: number) => (
                (semester.school_year === currentSemester.school_year && semester.semester === currentSemester.semester) ?
                  <option key={index} value={semester.school_year + "-" + semester.semester} selected>{semester.school_year.toString() + `${i18n.language === "en" ? "-Semester" : "學年度第"}` + semester.semester.toString() + "學期"}</option> :
                  <option key={index} value={semester.school_year.toString() + "-" + semester.semester.toString()} >{semester.school_year.toString() + `${i18n.language === "en" ? "-Semester" : "學年度第"}` + semester.semester.toString() + `${i18n.language === "en" ? "" : "學期"}`}</option>))
            }
          </Select>
        </div>
        {/*<div onClick={()=>setIsPdfModalOpen(true)} className="cursor-pointer">          
            <InfoOutlineIcon />
              <span className="ms-1 align-middle">請假規定</span>
            </div>*/}
        <Link href={schoolOtherSetting?.leave_rule + '?__target=InAppBrowser'} isExternal className="cursor-pointer" style={{ textDecoration: 'none' }}>
          <InfoOutlineIcon />
          <span className="ms-2 align-middle">{t("app.rules_of_leave")}</span>
        </Link>
      </div>
      <Card className='h-teach-l2 overflow-auto !rounded-2xl'>
        {
          (selectedLeaveApplications.length > 0) ?
            <Stack divider={<StackDivider />} spacing='0'>
              {
                selectedLeaveApplications.map((lapp, idx) => (
                  <TeacherApproveHistoryItem key={idx}
                    leaveApplicationRecord={lapp}
                    handleSelectedLeaveApp={handleSelectedLeaveApp}
                    handleShowDetail={handleShowDetail}
                    currentSelectedLappID={currentSelectedLappID} />
                ))
              }
              {/*  <div className='text-sm text-center p-5'>
                  系統僅顯示當學期資料
            </div> */}
            </Stack> :
            <div className='flex flex-col justify-center items-center h-[500px]'>
              <img src="/assets/img/nodata.png" alt="" className='w-32 mx-auto mb-4' />
              <div>{t("app.no_data")}</div>
            </div>
        }
      </Card>
      <PdfZoomModal
        isModalOpen={isPdfModalOpen}
        onModalClose={() => setIsPdfModalOpen(false)}
        pdfUrl="../assets/pdf/rule.pdf"
        title="請假單"
      />
    </>
  );
}