import { LeaveApplicationRecord, SchoolDate } from "./value_object";

/** 請假申請  */
export class LeaveApplicationHelper {

    /** 送出假單申請單 */
    static async send(data: any) {
        console.log({ data: JSON.stringify(data) });
        const resp = await fetch('/service/leave_application/add', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });

        // const resp = await fetch('/service/school_date/get_dates_by_semester');
        return await resp.json();
    }

    /** 目前學生在指定學年期的假單清單 */
    static async getStudentHistoriesBySchoolYearAndSemester(schoolYear: string | undefined, semester: string | undefined): Promise<LeaveApplicationRecord[]> {
        const resp = await fetch(`/service/leave_application/history/get_by_student_id?schoolYear=${schoolYear}&semester=${semester}`);
        const result = await resp.json()
        return result;
    }

    /** 目前學生在指定日期區間的假單清單 */
    static async getStudentHistoriesByDateRange(stuid:string,beginDate: string | undefined, endDate: string | undefined): Promise<LeaveApplicationRecord[]> {
        const resp = await fetch(`/service/leave_application/history/get_by_stuid_daterange?studentID=${stuid}&beginDate=${beginDate}&endDate=${endDate}`)
        const result = await resp.json()
        return result;
    }

    /** 目前學生有請假單的學年期清單 */
    static async getStudentHistoriesSemester() {
        const resp = await fetch(`/service/leave_application/history/get_semesters`);
        const result = await resp.json()
        return await result;
    }

    /** 目前師審核過的所有假單的學年期清單 */
    static async getTeacherHistoriesSemester() {
        const resp = await fetch(`/service/leave_application/history/get_teacher_semesters`);
        const result = await resp.json()
        return await result;
    }


    /** 取得等待目前登入教師審核的申請單清單 */
    static async getTeacherWaitingApprovedList() {
        const resp = await fetch(`/service/leave_application/teacher/get_waiting_approved_list`);
        const result = await resp.json()
        return await result;
    }

    /** 取得目前登入教師所審核過的申請單清單(現有學年度學期) */
    static async getTeacherApprovedList() {
        const resp = await fetch(`/service/leave_application/teacher/get_approved_list`);
        const result = await resp.json()
        return await result;
    }

    /** 取得目前登入教師所審核過的申請單清單(所有學年度學期) */
    static async getTeacherAllApprovedList() {
        const resp = await fetch(`/service/leave_application/teacher/get_all_approved_list`);
        const result = await resp.json()
        return await result;
    }

    /** 取得目前登入教師所申請過的公假假單 */
    static async getTeacherPublicLeaveApplicationList() {
        const resp = await fetch(`/service/leave_application/teacher/get_public_leave_list`);
        const result = await resp.json()
        return await result;
    }

    /** 教師核准假單 */
    static async approve(ids: any) {
        const resp = await fetch('/service/leave_application/teacher/approve', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(ids)
        });

        // const resp = await fetch('/service/school_date/get_dates_by_semester');
        return await resp.json();
    }

    /** 教師退回假單 */
    static async reject(data: { ids: any[], comment: string | undefined }) {
        const resp = await fetch('/service/leave_application/teacher/reject', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });

        // const resp = await fetch('/service/school_date/get_dates_by_semester');
        return await resp.json();
    }

    /** 關鍵字篩選的邏輯 */
    static filter(leaveApplications: any[], keyword: string) {
        const result = leaveApplications.filter((lapp: any) => {
            let isMatched = lapp.leaveStudents.filter((stud: any) => {
                // 找學生姓名，班級，學號，
                return (stud.className.indexOf(keyword) > -1) || (stud.name.indexOf(keyword) > -1) || (stud.studentNumber.indexOf(keyword) > -1)
            }).length > 0;

            // 找假別
            isMatched = isMatched || lapp.absence_type.indexOf(keyword) > -1;
            // 事由
            isMatched = isMatched || lapp.reason.indexOf(keyword) > -1;
            
            return isMatched;
        });
        return result;
    }

    // 更新假單中的附件與家長簽名檔
    static async updateAttachParentSign(refid: string, attachJson:any , parentSignJson: any, isParentSign: boolean) {
        
        const data={
            "leaveappID": refid,
            "attachmentInfo": attachJson,
            "parentSignInfo": parentSignJson,
            "isParentSign": (isParentSign)? "true":"false"
        }
        const resp = await fetch(`/service/leave_application/upd_attach_parentsign`,{
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
        return await resp.json();
    }

    // 假單取消
    static async cancel(refid: string) {
        const data={
            "leaveappID": refid,
        }
        const resp = await fetch(`/service/leave_application/cancel`,{
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
                    
        console.log({refid});
        return await resp.json();
    }

    // 更新簽核人員
    static async updateApprover(stage_no:string) {
        const data={
            "stage_no": stage_no,
        }
        const resp = await fetch('/service/leave_application/renew_current_approvers', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data) // Send stage_no in the body as an object
            });
        return await resp.json();
    }

}