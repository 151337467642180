import {
  AlertDialog,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Spinner,
} from "@chakra-ui/react";

import React, { useEffect, useState } from "react";

export function LoadingDialog({ isOpen = false }: { isOpen: boolean }) {
  const cancelRef = React.useRef(null);

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsDialogOpen(isOpen);
  }, [isOpen]);

  const onCancel = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <AlertDialog
        isOpen={isDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCancel}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader
              fontSize="lg"
              fontWeight="bold"
            ></AlertDialogHeader>
            <AlertDialogBody>
              <div className=" flex flexrow justify-center p-8">
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                />
              </div>
            </AlertDialogBody>
            <AlertDialogFooter />
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
