import React, { useEffect, useState, createContext, useContext } from "react";
import { useQueryString } from "../hooks";
import { useNavigate } from "react-router-dom";

export const UserInfoContext = createContext<UserInfoCtx>({
  userInfo: null,
  logout: async () => {},
  currentSemester: { schoolYear: undefined, semester: undefined },
});

// console.log('before UserValidation')

function UserValidation({ children }: UserValidationProps) {
  const queryString = useQueryString();
  const code = queryString.get("code");
  const dsns = queryString.get("dsns");
  const role = queryString.get("role");

  // console.log({ action: 'enter UserValidation', code, dsns, role })

  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);

  /** 取得 server 的 session 中的使用者資料 */
  const getUserInfo = async () => {
    console.log(" getUserInfo() ....");
    try {
      const resp = await fetch("/service/user/info");
      const data = await resp.json();
      console.log({ action: "getUserInfo()", data });
      if (data.errCode) {
        setUserInfo(null);
        navigate("/auth/not-signin");
      } else {
        setUserInfo(data);
        if (data.roleType === "student" || data.roleType === "parent") {
          navigate("student");
        } else if (data.roleType === "teacher") {
          // console.log({ data })
          if (!data.isAdmin) {
            navigate("teacher"); 
          } else {
            console.log( { isAdmin: data.isAdmin })
            if ( window.on1CampusWebView) {
              navigate("teacher");  // 行動裝置不處理管理者功能
            } else {
              navigate("teacher/menu");   // 桌機瀏覽器才顯示管理者功能
            }
          }
        } else {
          throw { error: 'unauthorized!' }
        }
      }
    } catch (ex) {
      //
      navigate("auth/not-signin");
    }
  };

  /** 由 web 或 app 開啟時呼叫 */
  const validateIdentityCode = async (code: string, dsns: string) => {
    console.log({ action: "validateIdentityCode()", code, dsns });
    try {
      // return ;
      const resp = await fetch(
        `/oauth/callback/identity_code?code=${code}&dsns=${dsns}`
      );

      const data = await resp.json();
      console.log({ action: "validateIdentityCode", data });
      setUserInfo(data);

      getUserInfo();  // 重新從 server 取得使用者身份。
    } catch (ex) {
      console.log({ ex });
      navigate("auth/not-signin");
    }
  };

  /** 登出 */
  const signout = async () => {
    console.log("signout in user-validation ");
    await fetch("/auth/logout");
    setUserInfo(null);
    navigate("auth/not-signin");
  };

  useEffect(() => {
    console.log(" trigger useEffect() in UserValidation ");

    if (code && dsns) {
      validateIdentityCode(code, dsns);
    } else {
      getUserInfo();
    }
  }, []);

  return (
    <UserInfoContext.Provider
      value={{
        userInfo,
        logout: signout,
        currentSemester: {
          schoolYear: userInfo?.schoolYear,
          semester: userInfo?.semester,
        },
      }}
    >
      {children}
    </UserInfoContext.Provider>
  );
}

export default UserValidation;

export function useUserContext() {
  return useContext(UserInfoContext);
}

export declare interface UserValidationProps {
  children?: React.ReactNode;
}

export declare interface ParentInfo {
  seatNo: number;
  classID: number;
  classNo: string;
  className: string;
  gradeYear: number;
  studentID: number;
  studentName: string;
  relationship: string;
  studentNumber: string;
  studentSourceIndex: string;
}

export declare interface TeacherInfo {
  teacherID: number;
  teacherNo: string;
  teacherName: string;
  teacherSourceIndex: string;
}

export declare interface StudentInfo {
  seatNo: number;
  classID: number;
  classNo: string;
  className: string;
  gradeYear: number;
  studentID: number;
  studentName: string;
  studentNumber: string;
  studentSourceIndex: string;
}

export declare interface UserInfo {
  name: string;
  account: string;
  roleType: string;
  schoolDsns: string;
  schoolName: string;
  schoolYear: number;
  semester: number;
  student: any | null;
  parent: ParentInfo | null;
  teacher: TeacherInfo | null;
  isAdmin: boolean;
  isSystemAdmin: boolean;
  language: string;
}

export declare interface UserInfoCtx {
  userInfo: UserInfo | null;
  logout: () => Promise<void> | null;
  currentSemester: {
    schoolYear: number | undefined;
    semester: number | undefined;
  };
}
