import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay,Textarea} from "@chakra-ui/react"
import { useTranslation } from "react-i18next";
interface props {
    isModalOpen:boolean;
    onModalClose:()=>void;
    setComment:(data:string)=>void;
    onConfirm:()=>void;
}

export const RejectReason=({isModalOpen,onModalClose,setComment,onConfirm}:props)=>{
    const { t, i18n } = useTranslation("translation");
    return(
        <Modal
            closeOnOverlayClick={false}
            isOpen={isModalOpen}
            onClose={onModalClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader className='!font-normal'>{t("app.enter_decline_reason")}</ModalHeader>
                <ModalCloseButton />

                <ModalBody pb={6}>
                    <Textarea size='lg' className='!h-44 !border-gray-300' onChange={(e)=> setComment(e.target.value)}  />
                </ModalBody>

                <ModalFooter className='gap-4'>
                    <Button colorScheme='orange' variant='outline' className="mr-auto w-full" onClick={onModalClose}>{t("app.backspace")}</Button>
                    <Button colorScheme='orange' className="w-full" onClick={() => { onModalClose(); onConfirm()}}>{t("app.confirm")}</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}