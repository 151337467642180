import React, { useEffect, useState } from "react"
import { Box, Button, Card, Checkbox, Collapse, Divider, Icon, IconButton, Input, InputGroup, InputLeftElement, InputRightElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Slide, Stack, StackDivider, Tab, TabList, TabPanel, TabPanels, Tabs, Tag, Textarea, effect, useDisclosure } from "@chakra-ui/react"
import { InfoOutlineIcon, Search2Icon, ChevronDownIcon, ChevronUpIcon, CloseIcon } from "@chakra-ui/icons";
//import { AiOutlineEllipsis, AiOutlineUnfold } from 'react-icons/ai';
import { LeaveApplicationHelper } from "../../dal/leave_application_helper";
import dayjs from "dayjs";
import { RejectReason } from "../../components/Modals/RejectReason";
import { ToggleString } from "../../components/ToggleString";
import { DialogBox } from "../../components/DialogBox";

import { LoadingDialog } from "../../components/LoadingDialog";
import { NotifyBoxModal } from "../../components/Modals/NotifyBoxModal";
import { useTranslation } from "react-i18next";
import { SelectClassModal } from "../../components/Modals/SelectClassModal";
import { on } from "events";


interface WaitApproveProps {
  WaitApproveLeaveApp: any;
  setSelectedLeaveApp: ((date: any) => void);
  handleShowDetail: (date: boolean) => void;
  setReload: (date: number) => void;
  activateFirst: boolean;
}


export const WaitingApprove = ({ WaitApproveLeaveApp, setSelectedLeaveApp, handleShowDetail, setReload, activateFirst }: WaitApproveProps) => {

  const { onOpen, onClose, isOpen, onToggle } = useDisclosure();
  const { isOpen: isWithdrawOpen, onOpen: onWithdrawOpen, onClose: onWithdrawClose } = useDisclosure();
  const { isOpen: isNotifyOpen, onOpen: onNotifyOpen, onClose: onNotifyClose } = useDisclosure();
  const { isOpen: isSelectClassOpen, onOpen: onSelectClassOpen, onClose: onSelectClassClose } = useDisclosure();

  const { t, i18n } = useTranslation("translation");
  //** 監聽螢幕大小 */
  const [applyButtonProps, setApplyButtonProps] = useState(window.innerWidth < 640);
  const { getButtonProps, getDisclosureProps } = useDisclosure({ defaultIsOpen: true });
  const buttonProps = getButtonProps();
  const disclosureProps = getDisclosureProps();

  const [leaveApplications, setLeaveApplications] = useState<any[]>([]); // 從資料庫中取得的紀錄
  const [resultLeaveApplications, setResultLeaveApplications] = useState<any[]>([]);  // 呈現在畫面中的紀錄
  const [filteredLeaveApplications, setFilteredLeaveApplications] = useState<any[]>([]);  // 呈現在畫面中的紀錄(keyword搜尋後)
  // const [selectedLeaveApplication , setSelectedLeaveApplication] = useState<any>();
  const [selectedLappID, setSelectedLappID] = useState<any>();

  const [comment, setComment] = useState<string>();

  const [keyword, setKeyword] = useState<string>();
  const [showDetailPanel, setShowDetailPanel] = useState<boolean>(false);
  const [curCheckAll, setCurCheckAll] = useState<boolean>(false);
  const [confirmReject, setConfirmReject] = useState<boolean>(false);
  const [localSelected, setLocalSelected] = useState();

  const [isExpanded, setIsExpanded] = useState(false); // 事由是否展開


  const handleApprovalClassClose = () => {
    setReload(2);
    onSelectClassClose();
  }
  const handleSelectClassOpen = () => {
    onSelectClassOpen();
 //   setReload(true);
  }
  const BatchApprove = async () => {

    const ids = resultLeaveApplications.filter(lapp => lapp.checked).map(lapp => lapp.id);
    await LeaveApplicationHelper.approve(ids);
    onNotifyOpen();
    setReload(1);
    //DialogBoxModal({isOpen:true,title:'批次核准',msg:'批次核准成功',showCancelButton:false,closeButtonText:'確定',okButtonText:'確定',onOK:()=>{setReload(true);}});
    //LoadingDialog({isOpen:true});
    //setReload(true);
    // console.log( { ids });
    // await loadData();
  }

  const CheckAll = () => {
    setCurCheckAll(!curCheckAll);
    //resultLeaveApplications.map((item)=>{
    //    item.checked=!item.checked;
    //})
    const updatedResultLeaveApplications = resultLeaveApplications.map((item) => ({
      ...item,
      checked: !curCheckAll,
    }));
    setResultLeaveApplications(updatedResultLeaveApplications);
  }

  const BatchWithdraw = async () => {
    const ids = resultLeaveApplications.filter(lapp => lapp.checked).map(lapp => lapp.id);
    await LeaveApplicationHelper.reject({ ids, comment });
    setReload(1);
  }

  const clearInput = () => {
    findByKeyword(''); // Clear the keyword, assuming findByKeyword updates the keyword state
  };

  /** 關鍵字搜尋 */
  const findByKeyword = (keyword: string) => {
    // console.log( { keyword })
    setKeyword(keyword);

    const temp = WaitApproveLeaveApp.map((item: any) => ({
      ...item,
      checked: false
    }))
    const result = LeaveApplicationHelper.filter(temp, keyword);
    // console.log( { result })

    setResultLeaveApplications([...result]);
  }

  const setChecked = (targetLapp: any, chk: boolean) => {
    //     targetLapp.checked = checked ;
    //     const temp = leaveApplications.filter(lapp => lapp.id !== targetLapp.id) ;
    //     const result = [ ...temp, targetLapp ].sort( (x: any, y: any) =>  (x.id > y.id) ? -1 : 1);
    //const result = [ ...temp, targetLapp ];
    setResultLeaveApplications(lapps => {
      return lapps.map(lapp => lapp.id === targetLapp.id ?
        {
          ...lapp, checked: chk
        } :
        lapp
      );
    });
  }

  useEffect(() => {
    setLeaveApplications(WaitApproveLeaveApp);
    const temp = WaitApproveLeaveApp.map((item: any) => ({
      ...item,
      checked: false
    }))
    setResultLeaveApplications(temp);
  }, [WaitApproveLeaveApp]);

  useEffect(() => {
    if (confirmReject) {
      BatchWithdraw()
      setConfirmReject(false)
      setComment('')
    }
  }, [confirmReject])

  useEffect(() => {
    if (isOpen) {
      findByKeyword('');
    }
    else {
      if (resultLeaveApplications.length === 0) {
        findByKeyword('');
      }
    }
  }, [isOpen]);

  return (
    <>
      <div className='flex flex-wrap justify-between items-center mb-4'>
        <div className="flex-shrink-0">
          <Icon w={9} h={9} viewBox='0 0 20 20' className="cursor-pointer me-2" onClick={() => CheckAll()}>
            <path fill="currentColor" d="M12.33 6.624a.5.5 0 0 1 .046.705l-3.5 4a.5.5 0 0 1-.73.025l-2-2a.5.5 0 1 1 .708-.708l1.622 1.622l3.148-3.597a.5.5 0 0 1 .705-.047M3 6a3 3 0 0 1 3-3h6a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3zm3-2a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2zm-.246 12c.55.614 1.347 1 2.236 1h4.5a4.5 4.5 0 0 0 4.5-4.5V8c0-.884-.383-1.68-.991-2.228V11c0 .1-.004.2-.01.299v1.2a3.5 3.5 0 0 1-3.5 3.5h-1.434l-.056.001z" />
          </Icon>
          <Search2Icon w={6} h={6} className="cursor-pointer me-2" onClick={onToggle} />
          <Icon w={6} h={6} viewBox='0 0 20 20' className="cursor-pointer" onClick={()=>handleSelectClassOpen()} >
            <path fill="red" d="M10 3.22l-.61-.6a5.5 5.5 0 0 0-7.78 7.77L10 18.78l8.39-8.4a5.5 5.5 0 0 0-7.78-7.77l-.61.61z" />
          </Icon>
        </div>
        <div className="flex gap-4">
          <Button colorScheme='orange' className='!min-w-[100px]' onClick={() => { setConfirmReject(false); onWithdrawOpen() }}
            isDisabled={(resultLeaveApplications.length === 0) || (resultLeaveApplications.filter(lapp => lapp.checked).length === 0)}>
            {t("app.batch_decline")}
          </Button>
          <Button colorScheme='twitter' className='!min-w-[100px]' onClick={() => BatchApprove()}
            isDisabled={(resultLeaveApplications.length === 0) || (resultLeaveApplications.filter(lapp => lapp.checked).length === 0)}>
            {t("app.batch_approve")}
          </Button>
        </div>
      </div>
      <Collapse in={isOpen}>
        <InputGroup className="mb-4">
          <InputLeftElement pointerEvents='none'>
            <Search2Icon color='gray.300' />
          </InputLeftElement>
          <Input
            type='text'
            placeholder=''
            borderColor='gray.400'
            value={keyword}
            onChange={(e) => { findByKeyword(e.target.value); }}
          />
          {keyword && (
            <InputRightElement>
              <IconButton
                aria-label="Clear input"
                icon={<CloseIcon />}
                size="sm"
                variant="ghost"
                onClick={clearInput}
              />
            </InputRightElement>
          )}
        </InputGroup>
      </Collapse>
      <Card className='h-teach-l1 overflow-auto !rounded-2xl'>
        <Stack divider={<StackDivider />} spacing='0'>

          {(resultLeaveApplications.length > 0) ?
            resultLeaveApplications.map((lapp) => (
              <div key={lapp.id} style={(lapp.id === selectedLappID || activateFirst ) ? { backgroundColor: '#F3F4F6' } : { backgroundColor: '#FFFFFF' }}>
                <div className='flex items-baseline p-3' >
                  <div className='me-3'>
                    <Checkbox size='lg' colorScheme='twitter' isChecked={lapp.checked} onChange={(e) => setChecked(lapp, e.target.checked)} />
                  </div>
                  <div className="cursor-pointer" onClick={() => { setSelectedLeaveApp(lapp); setSelectedLappID(lapp.id); handleShowDetail(true); }}>
                    <div className='flex items-baseline gap-x-2'>
                      {(lapp.approval_class_name) &&
                       <Icon w={4} h={4} viewBox='0 0 20 20' className="cursor-pointer" >
                        <path fill="red" d="M10 3.22l-.61-.6a5.5 5.5 0 0 0-7.78 7.77L10 18.78l8.39-8.4a5.5 5.5 0 0 0-7.78-7.77l-.61.61z" />
                      </Icon>
                      }
                      <div className='text-xl'>{lapp.leaveStudents[0]?.studentNumber}</div>
                      <div className='text-xl font-semibold'>{lapp.leaveStudents[0]?.className} {lapp.leaveStudents[0]?.name}</div>
                      {(lapp.leaveStudents[0]?.length > 1) && (
                        <div className='text-xl font-semibold'> ... {t("app.more")}</div>
                      )}
                    </div>
                    <div className='text-left'>
                      <div className={`${i18n.language === "en" ? "grid" : "flex" } items-baseline gap-x-3 `}>
                        <span className="font-semibold flex-shrink-0">{t("app.application_date")}</span>
                        {dayjs(lapp.apply_time).format('YYYY/MM/DD')}
                      </div>
                      <div className={`${i18n.language === "en" ? "grid" : "flex" } items-baseline gap-x-3 `}>
                        <span className="font-semibold flex-shrink-0">{t("app.leave_dates")}</span>
                        <span className="">{dayjs(lapp.leaveDateAndPeriod[0].d).format('YYYY/MM/DD')}
                        {lapp.leaveDateAndPeriod.length > 1 && (
                          <>
                            {` ~ `}{dayjs(lapp.leaveDateAndPeriod[lapp.leaveDateAndPeriod.length - 1].d).format('MM/DD')} ( {lapp.leaveDateAndPeriod.length}{t("app.day")} )
                          </>
                        )}</span>
                      </div>
                      <div className={`${i18n.language === "en" ? "grid" : "flex" } items-baseline gap-x-3 `}>
                        <span className="font-semibold flex-shrink-0">{t("app.category_session")}</span>
                        {lapp.absence_type} {lapp.leaveDateAndPeriod.reduce((acc: number, current: any) => acc + current.periods.length, 0)} {t("app.session")}
                      </div>
                      <div className={`${i18n.language === "en" ? "grid" : "flex" } items-baseline gap-x-3 `}>
                        <div className="font-semibold flex-shrink-0">{t("app.leave_reason_4w")}</div>
                        <ToggleString toggleStr={lapp.reason} />
                      </div>
                      <div className={`${i18n.language === "en" ? "grid" : "flex" } items-baseline gap-x-3 `}>
                        <span className="font-semibold flex-shrink-0">{t("app.attachment_4w")}</span>
                        {lapp.attachment_info ? t("app.included") : t("app.none")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )) :
            <div className='flex flex-col justify-center items-center h-[500px]'>
              <img src="/assets/img/nodata.png" alt="" className='w-32 mx-auto mb-4' />
              <div>{t("app.no_data")}</div>
            </div>
          }
        </Stack>
      </Card>
      <RejectReason isModalOpen={isWithdrawOpen} onModalClose={onWithdrawClose} setComment={setComment} onConfirm={BatchWithdraw} />
      <NotifyBoxModal isModalOpen={isNotifyOpen} onModalClose={onNotifyClose} title={`${t("app.batch_approve")}`} message={`${t("app.batch_approval_success")}`} />
      <SelectClassModal isModalOpen={isSelectClassOpen} onModalClose={handleApprovalClassClose} />
    </>
  )

}