import { SchoolAbsenceType, SchoolDateConfig, SchoolOtherSettings, SchoolPeriodConfig } from "./value_object";

/** 學校定義每學期上課日子的資訊  */
export class SchoolOtherSettingsHelper {

    static async get(): Promise<SchoolOtherSettings> {
        const resp = await fetch('/service/school_other_settings/get');
        const result: SchoolOtherSettings = await resp.json() || {};
        return await result;
    }

    /* 新增所有設定 */
    static async addAllSettings(
        need_parent_approval: any,
        date_range: any,
        leave_rule: any,
        editor_account: any,
        absence_name: any,
        public_leave_name: any
    ) {

        const data = {
            "need_parent_approval": need_parent_approval,
            "date_range": date_range,
            "leave_rule": leave_rule,
            "editor_account": editor_account,
            "absence_name": absence_name,
            "public_leave_name": public_leave_name,
        }
        const resp = await fetch('/service/school_other_settings/add_all_settings', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
        return await resp.json();
    }

    /* 更新所有設定 */
    static async updateAllSettings(
        need_parent_approval: any,
        date_range: any,
        leave_rule: any,
        editor_account: any,
        absence_name: any,
        public_leave_name: any
    ) {

        const data = {
            "need_parent_approval": need_parent_approval,
            "date_range": date_range,
            "leave_rule": leave_rule,
            "editor_account": editor_account,
            "absence_name": absence_name,
            "public_leave_name": public_leave_name,
        }
        const resp = await fetch('/service/school_other_settings/update_all_settings', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
        return await resp.json();
    }

    /* 更新請假規章 */
    static async updateLeaveRule(leave_rule: any) {

        const data = {
            "leave_rule": leave_rule
        }
        const resp = await fetch('/service/school_other_settings/update_leave_rule', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
        return await resp.json();
    }

    /**取得學校上課日設定 */
    static async getSchoolDate(): Promise<SchoolDateConfig> {
        const resp = await fetch('/service/school_other_settings/get_school_date');
        const result: SchoolDateConfig = await resp.json()
        return await result;
    }

    /**取得學校節次設定 */
    static async getSchoolPeriod(): Promise<SchoolPeriodConfig[]> {
        const resp = await fetch('/service/school_other_settings/get_school_period');
        const result: SchoolPeriodConfig[] | [] = await resp.json()
        return await result;
    }

    /**更新學校節次設定 */
    static async updateSchoolPeriod(semester:number,periods:string[]) {
            const data = {
            "semester": semester,
            "periods": periods
        }
        const resp = await fetch('/service/school_other_settings/update_school_period', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
        return await resp.json();
    }

    /**新增學校節次設定 */
    static async addSchoolPeriod(semester:number,periods:string[]) {
        const data = {
            "semester": semester,
            "periods": periods
        }
        const resp = await fetch('/service/school_other_settings/insert_school_period', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
        return await resp.json();
    }

    /**取得學校缺曠類別 */
    static async getSchoolAbsenceType(): Promise<SchoolAbsenceType[]> {
        const resp = await fetch('/service/school_other_settings/get_school_absence_type');
        const result: SchoolAbsenceType[] | [] = await resp.json()
        return await result;
    }

    /**更新學校缺曠類別 */
    static async updateSchoolAbsenceType(semester:number,absence_types:string[]) {
        const data = {
            "semester": semester,
            "absence_types": absence_types
        }
        const resp = await fetch('/service/school_other_settings/update_school_absence_type', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
        return await resp.json();
    }

    /**新增學校缺曠類別 */
    static async addSchoolAbsenceType(semester:number,absence_types:string[]) {
        const data = {
            "semester": semester,
            "absence_types": absence_types
        }
        const resp = await fetch('/service/school_other_settings/insert_school_absence_type', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
        return await resp.json();
    }

    /**取得學校請假其他設定 */
    static async getSchoolOtherSettings(): Promise<SchoolOtherSettings> {
        const resp = await fetch('/service/school_other_settings/get_school_other_settings');
        const result: SchoolOtherSettings = await resp.json()
        return await result;
    }

    /**以DSNS取得學校請假其他設定 */
    static async getSchoolOtherSettingsByDSNS(dsns:string): Promise<SchoolOtherSettings> {
        const resp = await fetch('/service/school_other_settings/get_school_other_settings_dsns?schooldsns='+dsns);
        
        const result: SchoolOtherSettings = await resp.json()
        return await result;
    }
}