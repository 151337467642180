import { AddIcon, ArrowLeftIcon, ArrowRightIcon, CalendarIcon } from "@chakra-ui/icons";
import { Box, InputGroup, Button, Input, Checkbox, useDisclosure, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, InputLeftAddon, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Grid, GridItem, useColorModeValue, ButtonGroup, IconButton } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Calendar, DateObject } from "react-multi-date-picker";
import taiwan_zh from "../../taiwan_zh";
import { SchoolDateHelper } from "../../dal/school_date_helper";
import { SchoolDateConfig, SemesterMonths } from "../../dal/value_object";
import dayjs from "dayjs";
import { NotifyBoxModal } from "../../components/Modals/NotifyBoxModal";
import { useUserContext } from "../../components/auth/user-validation";
import { useSchoolContext } from "../../dal/SchoolContext";



// import DatePicker, { registerLocale } from "react-datepicker";
// import 'react-datepicker/dist/react-datepicker.min.css';
// import zhTW from 'date-fns/locale/zh-TW';
// registerLocale('zhTW', zhTW);

/* 設定上課日 */
interface SemesterDetails {
  schoolYear: number;
  semester: number;
  firstDate: Date;
  lastDate: Date;
  lastUpdate: Date;
  monthlyDates: Record<string, MonthDetails>; // Keyed by "YYYY-MM"
  activate: boolean;
  id: string;
}

/** 每月的日期 */
interface MonthDetails {
  firstDate: string;
  lastDate: string;
  dates: (Date | string)[];
}

export const ClassdayPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { isOpen: isNotifyOpen, onOpen: onNotifyOpen, onClose: onNotifyClose } = useDisclosure(); //提示訊息視窗控制
  const [notifyMessage, setNotifyMessage] = useState(''); //提示訊息內容

  const [schoolDatesDetail, setSchoolDatesDetail] = useState<SemesterDetails[]>([]);
  const [semesterMask, setSemesterMask] = useState<boolean[]>([false,false,false,false]); //用來判斷該學年中之學期是否有已設定上課日
  const [semesterValue, setSemesterValue] = useState<boolean[]>([false,false,false,false]); //用來設定新增學年度之學期
  const [currentSchoolYear, setCurrentSchoolYear] = useState<number>(0); //用來設定新增學年度之學年度
  const [validDateString, setValidDateString] = useState(''); //用來判斷日期在不同學期中是否重複設定

  const [panelIdx, setPanelIdx] = useState<number[]>([]); // Accordion展開的panel index


  const [reload, setReload] = useState<boolean>(false); //重新載入資料
  const [key, setKey] = useState(0); //用來設定Accordion的key

  const { userInfo } = useUserContext(); // 使用者資訊

  const { selectedSchool } = useSchoolContext(); // 學校資訊



  const color = useColorModeValue('gray.600', 'gray.400');

  /** 新增學期時之預設月份依據 */
  const semMonth:SemesterMonths[] = [
    {semester: 1, begin_month: 9, end_month: 1}, //第一學期預設為9月到1月
    {semester: 2, begin_month: 3, end_month: 6}, //第二學期預設為2月到2月
    {semester: 3, begin_month: 2, end_month: 2}, //寒假預設為3月到6月
    {semester: 4, begin_month: 7, end_month: 8}  //暑假預設為7月到8月
  ];

  /** 將月份字串減一個月份 YYYY-MM+1 */ 
  function subtractOneMonth(key: string): string {
    if(key === undefined) return '';
    let [year, month] = key.split('-').map(Number);
    let date = new Date(year, month - 1, 1); // Months are 0-indexed in JavaScript Dates
    date.setMonth(date.getMonth() - 1); // Subtract one month
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`;
  }
  /** 將月份字串加一個月份 YYYY-MM-1 */
  function addOneMonth(key: string): string {
    if(key === undefined) return '';
    let [year, month] = key.split('-').map(Number);
    let date = new Date(year, month - 1, 1);
    date.setMonth(date.getMonth() + 1); // Add one month
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`;
  }

  function generateMonthKeys(startKey: string, endKey: string): string[] {
    if(startKey === undefined || endKey === undefined) return [];
    let result = [];
    let [startYear, startMonth] = startKey.split('-').map(Number);
    let [endYear, endMonth] = endKey.split('-').map(Number);
    let currentDate = new Date(startYear, startMonth - 1, 1);

    while (currentDate.getFullYear() < endYear || (currentDate.getFullYear() === endYear && currentDate.getMonth() <= endMonth - 1)) {
      const year = currentDate.getFullYear();
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      result.push(`${year}-${month}`);
      currentDate.setMonth(currentDate.getMonth() + 1);
    }
    return result;
  }

 
  const loadData = async () => {
    const currDsns = selectedSchool?.dsns || ''; // Set currDsns to an empty string if it is undefined
    const schDate = await SchoolDateHelper.getDatesForAllSemester(currDsns);
    const semesterDetails = organizeSemesterDates(schDate);
    setSchoolDatesDetail(semesterDetails); 
  }

  /** 新增月份預設日期 */
  const handleAddOnMonth = (detail: SemesterDetails, monthKey: string, panelIdx:number,type:string) => () => {
    const updateSemesterDetail=[...schoolDatesDetail];
    const updateSemesterIndex = updateSemesterDetail.findIndex((item) => detail.schoolYear === item.schoolYear && detail.semester === item.semester);
    const monthlyDates = updateSemesterDetail[updateSemesterIndex].monthlyDates;
    const dateKey=monthKey.split('-');
    const year = Number(dateKey[0]);
    const month = Number(dateKey[1]);
    if(monthlyDates[monthKey]){
      delete updateSemesterDetail[updateSemesterIndex].monthlyDates[monthKey];
    }
    let targetDate:Date;
    if(type === 'firstday')
    {
      monthlyDates[monthKey] = {
        firstDate: dayjs(`${year}-${month}-01`).format("YYYY/MM/DD"),
        lastDate: dayjs(`${year}-${month}-01`).endOf("month").format("YYYY/MM/DD"),
        dates: [] 
      };
      
      targetDate=new Date(dayjs(`${year}-${month}-01`).format("YYYY/MM/DD"));
      const dayOfWeek = targetDate.getDay();
      if(dayOfWeek === 0 || dayOfWeek === 6) //若為週六日則往後推到最近的平日
      {
        if(dayOfWeek === 0) targetDate.setDate(targetDate.getDate()+1);
        if(dayOfWeek === 6) targetDate.setDate(targetDate.getDate()+2);
      }
    }
    else
    {
      monthlyDates[monthKey] = {
        firstDate: dayjs(`${year}-${month}-01`).format("YYYY/MM/DD"),
        lastDate: dayjs(`${year}-${month}-01`).endOf("month").format("YYYY/MM/DD"),
        dates: [] 
      };
      targetDate=new Date(dayjs(`${year}-${month}-01`).endOf("month").format("YYYY/MM/DD"));
      const dayOfWeek = targetDate.getDay();
      if(dayOfWeek === 0 || dayOfWeek === 6) //若為週六日則往後推到最近的平日
      {
        if(dayOfWeek === 0) targetDate.setDate(targetDate.getDate()-2);
        if(dayOfWeek === 6) targetDate.setDate(targetDate.getDate()-1);
      }
    }

    /** 檢查檢查新增月份預設日期重複設定 */
    const checkSemester=schoolDatesDetail.filter((item) => item.schoolYear === detail.schoolYear && item.semester !== detail.semester && item.monthlyDates[monthKey]);
    const findIndex=checkSemester.findIndex((item) => item.monthlyDates[monthKey].dates.includes(dayjs(targetDate).format("YYYY/MM/DD")));
    if(findIndex !== -1)
    {
      //setSchoolDatesDetail(updateSemesterDetail);      
      setNotifyMessage('新增月份預設日期，將造成其他學期有重複日期設定');      
      onNotifyOpen();  
      setReload(true);
      return;
    }
    else
    {
      monthlyDates[monthKey].dates.push(dayjs(targetDate).format("YYYY/MM/DD")); // 新增月份預設日期(該月該月份份第1天)
    }      
      
    const sortedMonthlyDates = Object.entries(monthlyDates).sort((a, b) => a[0].localeCompare(b[0]));
    updateSemesterDetail[updateSemesterIndex].monthlyDates = Object.fromEntries(sortedMonthlyDates);
    const {overallFirstDate, overallLastDate} = findOverallFirstAndLastDay(updateSemesterDetail[updateSemesterIndex]);
    updateSemesterDetail[updateSemesterIndex].firstDate = new Date(overallFirstDate);
    updateSemesterDetail[updateSemesterIndex].lastDate = new Date(overallLastDate);
    setSchoolDatesDetail(updateSemesterDetail);
    setPanelIdx([panelIdx]);
    setKey((prev)=>prev+1); 
    //setIsEdit(true);
  }

  /** 新增學期時依據預設月份新增日期 */
  const handleAddSemeter = () => {
    const originalSchoolDatesDetail = [...schoolDatesDetail];

    [1,2,3,4].forEach((index) => {
      if(!semesterMask[index-1] && semesterValue[index-1])
      {
        const newSemesterSchoolDates:SemesterDetails = {
          schoolYear: currentSchoolYear,
          semester: index,
          firstDate: new Date(),
          lastDate: new Date(),
          lastUpdate: new Date(),
          monthlyDates: {},
          activate: false,
          id: ''
        };

        const hasExtendNextYear = semMonth[index-1].begin_month > semMonth[index-1].end_month;        
        const baseSchoolYear = (index===1) ? currentSchoolYear+1911: currentSchoolYear+1912;        
        const begin_month=semMonth[index-1].begin_month;
        if(hasExtendNextYear)
        {
          for(let i=begin_month; i<=12; i++)
          {
            const monthKey = `${baseSchoolYear}-${i.toString().padStart(2, '0')}`;
            newSemesterSchoolDates.monthlyDates[monthKey] = {
              firstDate: dayjs(`${baseSchoolYear}-${i}-01`).format("YYYY/MM/DD"),
              lastDate: dayjs(`${baseSchoolYear}-${i}-01`).endOf("month").format("YYYY/MM/DD"),
              dates: [] 
            };
            const generatedDates=generateDefaultDates(newSemesterSchoolDates.monthlyDates[monthKey].firstDate, newSemesterSchoolDates.monthlyDates[monthKey].lastDate);
            newSemesterSchoolDates.monthlyDates[monthKey].dates = generatedDates;
          }
          for(let i=1; i<=semMonth[index-1].end_month; i++)
          {
            const monthKey = `${baseSchoolYear+1}-${i.toString().padStart(2, '0')}`;
            newSemesterSchoolDates.monthlyDates[monthKey] = {
              firstDate: dayjs(`${baseSchoolYear+1}-${i}-01`).format("YYYY/MM/DD"),
              lastDate: dayjs(`${baseSchoolYear+1}-${i}-01`).endOf("month").format("YYYY/MM/DD"),
              dates: [] 
            };
            const generatedDates=generateDefaultDates(newSemesterSchoolDates.monthlyDates[monthKey].firstDate, newSemesterSchoolDates.monthlyDates[monthKey].lastDate);
            newSemesterSchoolDates.monthlyDates[monthKey].dates = generatedDates;
          }
        }
        else
        {
          for(let i=semMonth[index-1].begin_month; i<=semMonth[index-1].end_month; i++)
          {
            const monthKey = `${baseSchoolYear}-${i.toString().padStart(2, '0')}`;
            newSemesterSchoolDates.monthlyDates[monthKey] = {
              firstDate: dayjs(`${baseSchoolYear}-${i}-01`).format("YYYY/MM/DD"),
              lastDate: dayjs(`${baseSchoolYear}-${i}-01`).endOf("month").format("YYYY/MM/DD"),
              dates: [] 
            };
            const generatedDates=generateDefaultDates(newSemesterSchoolDates.monthlyDates[monthKey].firstDate, newSemesterSchoolDates.monthlyDates[monthKey].lastDate);
            newSemesterSchoolDates.monthlyDates[monthKey].dates = generatedDates;
          }
        }
        const {overallFirstDate, overallLastDate} = findOverallFirstAndLastDay(newSemesterSchoolDates);
        newSemesterSchoolDates.firstDate = new Date(overallFirstDate);
        newSemesterSchoolDates.lastDate = new Date(overallLastDate);
        originalSchoolDatesDetail.push(newSemesterSchoolDates);
      }
    });
    const sorted=resortSchoolDatesDetail(originalSchoolDatesDetail);
    setSchoolDatesDetail([...sorted]);
    setValidDateString('');
    onClose();
  }

  const handleOnClose = () => {
    setValidDateString('');
    onClose();
  }


  /** 重新排序學年度 */
  const resortSchoolDatesDetail = (detail:SemesterDetails[]) => {
    const sortedSchoolDatesDetail = [...detail].sort((a, b) => 
    {
      // First, compare by schoolYear
      if (a.schoolYear > b.schoolYear) return -1;
      if (a.schoolYear < b.schoolYear) return 1;

      // If schoolYear is the same, then compare by semester
      if (a.semester > b.semester) return -1;
      if (a.semester < b.semester) return 1;

      // If both schoolYear and semester are the same, keep the original order
      return 0;
    });
    return sortedSchoolDatesDetail;
  }

  /** 產生預設的上課日(月份內不含周六日) */
  const generateDefaultDates = (beginDate: string, endDate: string) => {
    const dates = [];
    let currentDate = new Date(beginDate);
    while (currentDate <= new Date(endDate)) {
      if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) //不包含週六日
        dates.push(dayjs(currentDate).format("YYYY/MM/DD"));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  }

  /** 將每學期所有上課日分割成每個月之上課日 */
  const organizeSemesterDates = (data: SchoolDateConfig[]): SemesterDetails[] => {
    const detailsMap: Record<string, SemesterDetails> = {};
  
    data.forEach((semester) => {
      const semesterKey = `${semester.school_year}-${semester.semester}`;
        // Initialize or retrieve existing semester details
      if (!detailsMap[semesterKey]) {
        detailsMap[semesterKey] = {
          schoolYear: semester.school_year,
          semester: semester.semester,
          firstDate: semester.begin_date,
          lastDate: semester.end_date,
          lastUpdate: semester.last_update,
          monthlyDates: {},
          activate: true,
          id: semester.id,
        };
      }

      semester.dates.forEach((dateItem) => {
        const date = new Date(dateItem.d);
        const year = date.getFullYear();
        // Add 1 to the month because getMonth() returns a zero-based index
        const month = date.getMonth() + 1;
        const monthKey = `${year}-${month.toString().padStart(2, '0')}`; 
  
        // Initialize the month array if not already present
        if (!detailsMap[semesterKey].monthlyDates[monthKey]) {
          detailsMap[semesterKey].monthlyDates[monthKey] = 
          {
            firstDate: dayjs(`${year}-${month}-01`).format("YYYY/MM/DD"),
            lastDate: dayjs(`${year}-${month}-01`).endOf("month").format("YYYY/MM/DD"),
            dates:[]
          };
        } 
        detailsMap[semesterKey].monthlyDates[monthKey].dates.push(dateItem.d);
      });

      const {overallFirstDate, overallLastDate} = findOverallFirstAndLastDay(detailsMap[semesterKey]);
      detailsMap[semesterKey].firstDate = new Date(overallFirstDate);
      detailsMap[semesterKey].lastDate = new Date(overallLastDate);
    });
  
    // Convert the details map to an array and optionally sort the dates within each month
    const semesterDetailsArray = Object.values(detailsMap);
    semesterDetailsArray.forEach((detail) => {
      Object.keys(detail.monthlyDates).forEach((monthKey) => {
        detail.monthlyDates[monthKey].dates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
      });
    });

    return semesterDetailsArray;
  };
  
  const handleDateChange = (date: DateObject | DateObject[] | null,schoolyeaar:number,semester:number,monthkey:string) => {
    let standardizedDates: DateObject[];

    /** date參數的使用判斷 */
    if (Array.isArray(date)) {
      // If dates is already an array, use it directly
      standardizedDates = date;
    } else if (date) {
      // If dates is a single DateObject (not null and not an array), make it an array
      standardizedDates = [date];
    } else {
      // If dates is null, use an empty array
      standardizedDates = [];
    }
    /** 更新日期的選擇結果 */
    const updateSemesterDetail=[...schoolDatesDetail]
    const updateSemesterIndex = updateSemesterDetail.findIndex((item) => item.schoolYear === schoolyeaar && item.semester === semester);
    const checkSemester=schoolDatesDetail.filter((item) => item.schoolYear === schoolyeaar && item.semester !== semester && item.monthlyDates[monthkey]);
    const updateMonthDetail = updateSemesterDetail[updateSemesterIndex].monthlyDates[monthkey];
    const keyIndex=Object.keys(updateSemesterDetail[updateSemesterIndex].monthlyDates).findIndex((item) => item === monthkey);

    if(standardizedDates.length === 0 && Object.keys(updateSemesterDetail[updateSemesterIndex].monthlyDates).length === 1)
    {
      setSchoolDatesDetail(updateSemesterDetail);      
      setNotifyMessage('該月份至少需選擇一日');
      onNotifyOpen();      
      return;
    }

    if(standardizedDates.length === 0 && keyIndex !== 0 && keyIndex !== Object.keys(updateSemesterDetail[updateSemesterIndex].monthlyDates).length-1)
    {
      setSchoolDatesDetail(updateSemesterDetail);      
      setNotifyMessage('該月份至少需選擇一日');
      onNotifyOpen();      
      return;
    }    

    if(standardizedDates.length === 0) //若該月份無日期則刪除該月份
    {
      delete updateSemesterDetail[updateSemesterIndex].monthlyDates[monthkey];
      const {overallFirstDate, overallLastDate} = findOverallFirstAndLastDay(updateSemesterDetail[updateSemesterIndex])
      updateSemesterDetail[updateSemesterIndex].firstDate = new Date(overallFirstDate);
      updateSemesterDetail[updateSemesterIndex].lastDate = new Date(overallLastDate);
      setSchoolDatesDetail(updateSemesterDetail);
      return;
    }
    const dates = standardizedDates.map(d => new Date(Number(d.year), Number(d.month) - 1, Number(d.day)));

    dates.forEach((date) => {
      const findIndex=checkSemester.findIndex((item) => item.monthlyDates[monthkey].dates.includes(dayjs(date).format('YYYY/MM/DD')));
      if(findIndex !== -1)
      {
    //    setSchoolDatesDetail(schoolDatesDetail);      
        setNotifyMessage('日期在不同學期學期中有有重複設定');
        onNotifyOpen();
        setReload(true);  
        return;
      }
    })
    updateMonthDetail.dates = dates;
    updateSemesterDetail[updateSemesterIndex].monthlyDates[monthkey] = updateMonthDetail;    
    const {overallFirstDate, overallLastDate} = findOverallFirstAndLastDay(updateSemesterDetail[updateSemesterIndex])
    updateSemesterDetail[updateSemesterIndex].firstDate = new Date(overallFirstDate);
    updateSemesterDetail[updateSemesterIndex].lastDate = new Date(overallLastDate);
    setSchoolDatesDetail(updateSemesterDetail);
  }

  /** 找尋學期上課日中的第1天與最後1日 */
  const findOverallFirstAndLastDay = (details: SemesterDetails) => {
    const allDates = Object.values(details.monthlyDates).flatMap(month => 
      month.dates.map(date => date instanceof Date ? date : new Date(date))
    );  
    const sortedDates = allDates.sort((a, b) => a.getTime() - b.getTime());  
    const overallFirstDate = sortedDates[0];
    const overallLastDate = sortedDates[sortedDates.length - 1];
    return {    
      overallFirstDate: dayjs(overallFirstDate).format("YYYY-MM-DD"),
      overallLastDate: dayjs(overallLastDate).format("YYYY-MM-DD")
    };
  };

  /** 輸入新增學年度檢查學年度內是否已有學期日期設定 */
  const handleCurrentSemester = () => (e: React.ChangeEvent<HTMLInputElement>) => {
    if(e.target.value !=='')
    {
      setValidDateString('');
      [1,2,3,4].forEach((index) => {
        if(schoolDatesDetail.findIndex((item) => item.schoolYear === Number(e.target.value) && item.semester === index) !== -1)
        {
          semesterMask[index-1] = true;
          semesterValue[index-1] = true;
        }
        else
        {
          // 檢查預設月份在其他學年度是否已經設定
          const hasExtendNextYear = semMonth[index-1].begin_month > semMonth[index-1].end_month;        
          const baseSchoolYear = (index===1) ? Number(e.target.value)+1911: Number(e.target.value)+1912;        
          const begin_month=semMonth[index-1].begin_month;
          semesterMask[index-1] = false;
          semesterValue[index-1] = false;
          if(hasExtendNextYear)
          {
            for(let i=begin_month; i<=12; i++)
            {
              const monthKey = `${baseSchoolYear}-${i.toString().padStart(2, '0')}`;
              const checkSemester=schoolDatesDetail.filter((item) => item.schoolYear === Number(e.target.value) && item.semester !== index && item.monthlyDates[monthKey])
              if(checkSemester.length > 0)
              {
                semesterMask[index-1] = true;
                semesterValue[index-1] = false;
                setValidDateString(monthKey+'在'+e.target.value+'學年度有已有上課日設定');
                break;
              }

            }
            for(let i=1; i<=semMonth[index-1].end_month; i++)
            {
              const monthKey = `${baseSchoolYear+1}-${i.toString().padStart(2, '0')}`;
              const checkSemester=schoolDatesDetail.filter((item) => item.schoolYear === Number(e.target.value) && item.semester !== index && item.monthlyDates[monthKey])
              if(checkSemester.length > 0)
              {
                semesterMask[index-1] = true;
                semesterValue[index-1] = false;
                setValidDateString(monthKey+'在'+e.target.value+'學年度有已有上課日設定');
                break;
              }
            }
          }
          else
          {
            for(let i=semMonth[index-1].begin_month; i<=semMonth[index-1].end_month; i++)
            {
              const monthKey = `${baseSchoolYear}-${i.toString().padStart(2, '0')}`;
              const checkSemester=schoolDatesDetail.filter((item) => item.schoolYear === Number(e.target.value) && item.semester !== index && item.monthlyDates[monthKey])
              if(checkSemester.length > 0)
              {
                semesterMask[index-1] = true;
                semesterValue[index-1] = false;
                setValidDateString(monthKey+'在'+e.target.value+'學年度有已有上課日設定');
                break;
              }
            }
          }
        }
      });
      setSemesterMask([...semesterMask]);
      setSemesterValue([...semesterValue]);
      setCurrentSchoolYear(Number(e.target.value));
    }
  }

  /** 選擇新增學期 */
  const handleSemesterChange=(index:number)=>{
    semesterValue[index-1] = !semesterValue[index-1];
    setSemesterValue([...semesterValue]);        
  }

  /** 處理儲存學期上課日 */
  const handleSaveSchoolDate = (schoolYear: number,semester:number) => () => {
    const semesterDetail = schoolDatesDetail.find((item) => item.schoolYear === schoolYear && item.semester === semester);
    if (semesterDetail) {
      const dates = Object.values(semesterDetail.monthlyDates).flatMap(month => 
        month.dates.map(date => date instanceof Date ? date : new Date(date))
      );
      const formattedDates = dates.map(date => dayjs(date).format('YYYY-MM-DD'));
      const sortedDates = formattedDates.sort((a, b) => a.localeCompare(b));
      const detailedDates = convertDatesToDetailedFormat(sortedDates);
      if(semesterDetail.id !== '')
      {
        SchoolDateHelper.updateSchoolDate(semesterDetail.id,schoolYear, semester, dayjs(semesterDetail.firstDate).format('YYYY-MM-DD'), dayjs(semesterDetail.lastDate).format('YYYY-MM-DD'), detailedDates);
        setNotifyMessage('學期上課日更新完畢');
        onNotifyOpen();
      }
      else
      {
        SchoolDateHelper.addSchoolDate(schoolYear, semester, dayjs(semesterDetail.firstDate).format('YYYY-MM-DD'), dayjs(semesterDetail.lastDate).format('YYYY-MM-DD'), detailedDates);
        setNotifyMessage('學期上課日新增完畢');
        onNotifyOpen();
      }
      setReload(true);

    }
  }
  // 日期格式轉換
  const convertDatesToDetailedFormat = (dates:string[]) => {
    // Assuming dates is an array of 'YYYY-MM-DD' strings
    return dates.map(dateString => {
      const date = dayjs(dateString);
      return {
        d: date.format('YYYY/MM/DD'), // Convert to 'YYYY/MM/DD' format
        w: date.day(), // Get the day of the week (1-7)
    }});
  };

  useEffect(() => {
    loadData();
  },[]);

  useEffect(() => {
    if(reload)
    {
      loadData();
      setReload(false);
    }
  },[reload]);

  useEffect(() => {
    loadData();
  },[selectedSchool]);

  useEffect(() => {
    if(isOpen)
    {
      setCurrentSchoolYear(0);
      setSemesterMask([true,true,true,true]);
      setSemesterValue([false,false,false,false]);
    }
  },[isOpen]);

  const checkPermission = () => {
   /* if(userInfo?.isSystemAdmin)
    {
      if((selectedSchool?.dsns===userInfo?.schoolDsns) && userInfo?.isAdmin)
      {
        return true;
      }
      else
      {
        return false;
      }
    }
    else
    {     
      return false;
    }*/
    if((selectedSchool?.dsns===userInfo?.schoolDsns) && userInfo?.isAdmin)
      {
        return true;
      }
      else
      {
        return false;
      }

  }

  
  return (
    <>
      <Box borderWidth='1px' borderRadius='lg' p="6" h='87vh' overflow='hidden'>
        <div className="h-full overflow-y-auto">
          <div className="flex justify-between items-center mb-4">
            <div className="text-xl">各學期上課日</div>
            {checkPermission() &&
            <Button leftIcon={<AddIcon />} size={'sm'} colorScheme='cyan' variant='outline' mr={4} onClick={onOpen}>
              新增學年度
            </Button>}
          </div>

          <Grid
            templateColumns='repeat(7, 1fr)'
            color={color}
            className="gap-x-4 text-sm font-semibold text-center px-4 py-2 w-[1112px]">
            {(checkPermission()) ?
            <>
              <GridItem ></GridItem>
              <GridItem >學年度</GridItem> 
              <GridItem colSpan={2}>開始日</GridItem>
              <GridItem >結束日</GridItem>
              <GridItem></GridItem>            
              <GridItem></GridItem> 
            </> :
            <>
              <GridItem >學年度</GridItem> 
              <GridItem colSpan={2}>開始日</GridItem>
              <GridItem >結束日</GridItem>
              <GridItem ></GridItem>
              <GridItem></GridItem>            
              <GridItem></GridItem> 
            </>
            }
          </Grid>

          <Accordion defaultIndex={panelIdx} allowToggle variant='striped' key={key}>
            {
              schoolDatesDetail.map((item, index) => (
                <AccordionItem key={item.schoolYear.toString()+"-"+item.semester.toString()} >
                  <AccordionButton _expanded={{ bg: '#d6f5f080' }} _hover={{ bg: '#d6f5f050' }} >
                    <Grid
                      templateColumns='repeat(7, 1fr)'
                      className="gap-x-4 w-[1080px]">
                      {checkPermission() &&
                      <GridItem justifySelf="start"><Button size='sm' leftIcon={<ArrowLeftIcon />} colorScheme='teal' variant='outline' onClick={handleAddOnMonth(item,subtractOneMonth(Object.keys(item.monthlyDates)[0]),index,'lastday')} >新增{subtractOneMonth(Object.keys(item.monthlyDates)[0])}月份</Button></GridItem> }
                      <GridItem overflow="hidden" textAlign="center">{item.schoolYear}({(item.semester<3)? item.semester: (item.semester===3)? '寒輔':'暑輔'})</GridItem>
                      <GridItem overflow="hidden" colSpan={2}>{dayjs(item.firstDate).format('YYYY/MM/DD')}<CalendarIcon ml={2} color={color} /></GridItem>
                      <GridItem overflow="hidden">{dayjs(item.lastDate).format('YYYY/MM/DD')}<CalendarIcon ml={2} color={color} /></GridItem>
                      <GridItem textAlign="center"><AccordionIcon/></GridItem>
                      {checkPermission() && 
                      <GridItem justifySelf="end"><Button size='sm' rightIcon={<ArrowRightIcon />} colorScheme='teal' variant='outline' onClick={handleAddOnMonth(item,addOneMonth(Object.keys(item.monthlyDates)[Object.keys(item.monthlyDates).length-1]),index,'firstday')}>新增{addOneMonth(Object.keys(item.monthlyDates)[Object.keys(item.monthlyDates).length-1])}月份</Button></GridItem>}
                    </Grid>
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <div className="flex flex-wrap gap-4">
                    {
                      item.monthlyDates && Object.keys(item.monthlyDates).map((monthKey, index) => (
                        item.monthlyDates[monthKey].dates.length >0 ?
                        <div key={monthKey} className="flex flex-wrap gap-4">
                          <Calendar
                            onChange={(selectedDates) => handleDateChange(selectedDates, item.schoolYear, item.semester, monthKey)}
                            disableYearPicker
                            disableMonthPicker
                            multiple
                            locale={taiwan_zh}
                            minDate={item.monthlyDates[monthKey].firstDate}
                            maxDate={item.monthlyDates[monthKey].lastDate}
                            value={item.monthlyDates[monthKey].dates.map(date => new Date(date))}
                          />
                        </div> :
                        null

                      ))
                    }                    
                    </div>
                    <div className="flex justify-between items-center mt-4">
                      <div>最近一次修改時間：{dayjs(item.lastUpdate).format('YYYY/MM/DD HH:mm:ss')}</div>
                      {checkPermission() &&
                      <Button variant='outline' colorScheme='cyan' onClick={handleSaveSchoolDate(item.schoolYear,item.semester)}>儲存</Button> }
                    </div>
                  </AccordionPanel>
                </AccordionItem>
              ))
            }
          </Accordion>

        </div>
      </Box>

      <Modal isOpen={isOpen} onClose={handleOnClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>新增學年度</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <InputGroup w='220px' mx='auto'>
              <InputLeftAddon>學年度</InputLeftAddon>
              <Input type='number' placeholder='' value={(currentSchoolYear===0)? '':currentSchoolYear} onChange={handleCurrentSemester()}/>
            </InputGroup>
            <div className="flex justify-center items-center mt-4" style={{ gap: '10px' }}>
              <Checkbox colorScheme='cyan' isDisabled={semesterMask[0]} isChecked={semesterValue[0]} onChange={()=>handleSemesterChange(1)}>第一學期</Checkbox>
              <Checkbox colorScheme='cyan' isDisabled={semesterMask[1]} isChecked={semesterValue[1]} onChange={()=>handleSemesterChange(2)}>第二學期</Checkbox>
              {/*
              <Checkbox colorScheme='cyan' isDisabled={semesterMask[2]} isChecked={semesterValue[2]} onChange={()=>handleSemesterChange(3)}>寒輔</Checkbox>
          <Checkbox colorScheme='cyan' isDisabled={semesterMask[3]} isChecked={semesterValue[3]} onChange={()=>handleSemesterChange(4)}>暑輔</Checkbox> */}
            </div>
            {validDateString !== '' && <div className="ml-10 mt-4 text-red-500">{validDateString}</div>}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='cyan' mr={3} onClick={(e)=>handleAddSemeter()}>新增</Button>
            <Button onClick={()=>handleOnClose()}>取消</Button>           
          </ModalFooter>
        </ModalContent>
      </Modal>
      <NotifyBoxModal isModalOpen={isNotifyOpen} title='資料提示' message={notifyMessage} onModalClose={onNotifyClose}/>
    </>
  )
}