import React, { useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useBreakpointValue,
  Box,
} from '@chakra-ui/react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { usePinch } from 'react-use-gesture';
import { animated, useSpring } from 'react-spring';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface Props {
  isModalOpen: boolean;
  onModalClose: () => void;
  pdfUrl: string;
  title: string;
}

export const PdfZoomModal = ({ isModalOpen, onModalClose, pdfUrl, title }: Props) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [scale, setScale] = useSpring(() => ({ scale: 1 }));

  const modalSize = useBreakpointValue({ base: 'full', sm: 'lg', md: 'xl', lg: '2xl' });

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const bind = usePinch(state => {
    const { offset: [s] } = state;
    setScale({ scale: 1 + s / 200 }); // Adjust the division factor to control zoom sensitivity
  });

  return (
    <Modal size={modalSize} isOpen={isModalOpen} onClose={onModalClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Box overflow="auto" maxHeight="75vh" {...bind()}>
            <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(numPages), (el, index) => (
                // Use the animated.div to apply the scale transform
                <animated.div key={`page_${index + 1}`} style={{ scale: scale.scale }}>
                  <Page pageNumber={index + 1} />
                </animated.div>
              ))}
            </Document>
          </Box>
        </ModalBody>

        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};
