import { CalendarIcon, DownloadIcon, Search2Icon,TriangleUpIcon,TriangleDownIcon,UpDownIcon } from "@chakra-ui/icons";
import { TableContainer, Table, Thead, Tr, Th, Tbody, Td, Tfoot, Box, InputGroup, InputRightElement, Badge, Center, Button, Select, InputLeftElement, Input, Checkbox, Stack, useColorModeValue, Switch, useDisclosure } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.min.css';
import { ManagerLeaveHelper } from "../../dal/manager_leave_helper";
import { LeaveApplicationRecord } from "../../dal/value_object";
import { DSAAbsenceHelper } from "../../dal/dsa_absence_helper";
import zhTW from "date-fns/locale/zh-TW";
import { LeaveAppModal } from "../../components/Modals/LeaveAppModal";
import Excel from 'exceljs';
import FileSaver from 'file-saver';
import dayjs from 'dayjs';
import { Util } from "../../dal/util";
import { useUserContext } from "../../components/auth/user-validation"
import { useSchoolContext } from "../../dal/SchoolContext"; 

registerLocale('zhTW', zhTW);

interface sortParameter{
  key: string;
  sortType: number;    // 0: no sort, 1: ascending, -1: descending
}

/* 查詢全校請假紀錄 */
export const RecordPage = () => {

  const { isOpen: isModalOpen, onOpen:onModalOpen,onClose: onModalClose } = useDisclosure();
  const [statusArr, setStatusArr] = useState([true, true, true, true]); // 審核狀態字串陣列

  const color = useColorModeValue('gray.600', 'gray.400');

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [LeaveAppAllList, setLeaveAppAllList] = useState<LeaveApplicationRecord[]>([]); // 從資料庫中取得的紀錄
  const [LeaveAppList, setLeaveAppList] = useState<LeaveApplicationRecord[]>([]); // 篩選後的紀錄  
  const [runFilter, setRunFilter] = useState(false); // 重新篩選紀錄
  const [allClasses, setAllClasses] = useState<any[]>([]); // 所有班級資料
  const [gradeClass, setGradeClass] = useState<any[]>([]); // 年級班級資料
  const [gradeYear, setGradeYear] = useState<number>(0); // 年級
  const [currentClass, setCurrentClass] = useState<number>(0); // 班級ID
  const [keyword, setKeyword] = useState(''); // 姓名或學號關鍵字
  const [donwloadEnable, setDownloadEnable] = useState(false); // 下載按鈕是否啟用

  const [selectedLeaveApp, setSelectedLeaveApp] = useState<any>(); // 選擇的請假紀錄
  const [selectedLeaveStu, setSelectedLeaveStu] = useState<any>(); // 選擇的請假學生

  const [sortParameters, setSortParameters] = useState<sortParameter[]>([  // 排序參數
    {key: 'leaveStudents[0].gradeYear', sortType: 0},
    {key: 'leaveStudents[0].className', sortType: 0},
    {key: 'leaveStudents[0].studentNumber', sortType: 0},
    {key: 'leaveStudents[0].name', sortType: 0},
    {key: 'leaveDateAndPeriod.d[0]', sortType: 0},
  ]);
 
  const [currDsns, setCurrDsns] = useState<string>(''); // 顯示的DSNS   

  const { userInfo } = useUserContext(); // 使用者資訊

  const { selectedSchool } = useSchoolContext(); // 學校資訊
  //const [currDsns, setCurrDsns] = useState<string>(''); // 顯示的DSNS]

  //useEffect(() => {
  //  if(dsns)
  //  {
  //    setCurrDsns(dsns);
  //  }
  //}, [dsns]);

  const handleDetailOpen = (item:any,student:any) => () => {
    setSelectedLeaveApp(item);
    setSelectedLeaveStu(student);
    onModalOpen();
  }
  
  const handleStatus = (index:number, chk:boolean) => {
    const temp = [...statusArr];
    temp[index] = chk;
    setStatusArr(temp);
    setRunFilter(true);
  }

  const handleKeyword = () => (e:any) => {
    setKeyword(e.target.value);
    setRunFilter(true);
  }

  const handleGradeYear = () => (e:any) => {
    if(e.target.value==='')    {
      setGradeYear(0);    }
    else
    {
      const intGrade=parseInt(e.target.value);
      setGradeYear(intGrade);
      setGradeClass(allClasses.filter((item) => item.gradeYear === intGrade));
    }
    setCurrentClass(0);
    setRunFilter(true);
  }
  
  const handleCurrentClass = () => (e:any) => {
    if(e.target.value==='')
    {
      setCurrentClass(0);
    }
    else
    {
      const intClass=parseInt(e.target.value);
      setCurrentClass(intClass);
    }
    setRunFilter(true);
  }

  const updateSortParameters = (idx:number) => {
    if (sortParameters[idx].sortType===0) {
      setSortParameters(sortParameters.map((item, index) => {
        if(index===idx) return {key: item.key, sortType: 1};
        return item;
      }));
    }
    else if (sortParameters[idx].sortType===1) {
      setSortParameters(sortParameters.map((item, index) => {
        if(index===idx) return {key: item.key, sortType: -1};
        return item;
      }));
    }
    else if (sortParameters[idx].sortType===-1) {
      setSortParameters(sortParameters.map((item, index) => {
        if(index===idx) return {key: item.key, sortType: 0};
        return item;
      }));
    }
    setRunFilter(true);
  }

  // 轉換請假字串
  const convLeaveDateStr = (leaveDateAndPeriod:any ) => {
    let result=dayjs(leaveDateAndPeriod[0].d).format('YYYY/MM/DD');
    if(leaveDateAndPeriod.length > 1)
    {
      result += "~"+dayjs(leaveDateAndPeriod[leaveDateAndPeriod.length - 1].d).format('MM/DD')+"("+leaveDateAndPeriod.length+"天)";
    }
    return result;
  } 

  const getLeaveRecord = async (dsns:string) => {
    if(userInfo)
    {
      //const LeaveApplicationList = await ManagerLeaveHelper.get(startDate.toDateString(), endDate.toDateString(), dsns);
      const LeaveApplicationList = await ManagerLeaveHelper.get_by_leave_date(startDate.toDateString(), endDate.toDateString(), dsns);
      const resort=Util.reSortLeaveApp(LeaveApplicationList);
      setLeaveAppAllList(resort);
      setLeaveAppList(resort);
      setSortParameters(prevParams => prevParams.map(param => ({
        ...param,
        sortType: 0
      })));
      setRunFilter(true);
    }
  }

  const exportLeaveRecord = async () => {
    const workbook = new Excel.Workbook();
    const sheet = workbook.addWorksheet(`請假紀錄-${dayjs(startDate).format('YYYYMMDD')}-${dayjs(endDate).format('YYYYMMDD')}`);
    sheet.columns = [
      { header: '序號', key: 'index', width: 10 },
      { header: '年級', key: 'gradeYear', width: 10 },
      { header: '班級', key: 'className', width: 10 },
      { header: '座號', key: 'seatNo', width: 10 },
      { header: '學號', key: 'studentNumber', width: 15 },
      { header: '姓名', key: 'name', width: 15 },
      { header: '假單狀態', key: 'status', width: 20 },
      { header: '請假類型', key: 'absence_type', width: 10 },
      { header: '請假日期', key: 'leaveDate', width: 15 },
      { header: '節次', key: 'periods', width: 45 },
      { header: '事由', key: 'reason', width: 30 },
      { header: '附件', key: 'has_attachment', width: 10 },
      { header: '申請時間', key: 'apply_time', width: 20 },
      { header: '核准狀態', key: 'approve_status', width: 20 },
      { header: '核准時間', key: 'approve_time', width: 20 },
      { header: '核准人', key: 'approver', width: 20 },
      { header: '核准意見', key: 'comment', width: 30 },
    ];

    let currentRow = 2; // Start from the first row after the header.
    let idx=1;
    console.log(LeaveAppList);
    for (const lapp of LeaveAppList)
    {
      const maxRows = Math.max(lapp.leaveDateAndPeriod.length, lapp.approveHistory.length, lapp.leaveStudents.length);
      //let firstRowIndex = null;
      const statusStr:{
        progressText: string;
        progressTextCSS: string;
        statusText: string;
        statusTextCSS: string;
        status: string;
      }=Util.calculateProgressText(lapp);

      for (let i = 0; i < maxRows; i++)
      {
        const leaveDateAndPeriod = lapp.leaveDateAndPeriod[i];
        const approveHistory = lapp.approveHistory[i];
        const leaveStudent = lapp.leaveStudents[i];

        // Add a row for each student.
        sheet.addRow({
          index: idx,
          gradeYear: leaveStudent?.gradeYear,
          className: leaveStudent?.className,
          seatNo: leaveStudent?.seatNo,
          studentNumber: leaveStudent?.studentNumber,
          name: leaveStudent?.name,
          status: (statusStr.progressText==='申請中')? '審核中': statusStr.progressText,
          absence_type: lapp.absence_type,
          leaveDate: dayjs(leaveDateAndPeriod?.d).format('YYYY/MM/DD'),
          periods: leaveDateAndPeriod?.periods.join(','),
          reason: lapp.reason,
          has_attachment: lapp.attachment_info ? '有' : '無',
          apply_time: dayjs(lapp.apply_time).format('YYYY/MM/DD HH:mm:ss'),
          approve_status: approveHistory?.status === 'approved' ? '核准' : approveHistory?.status === 'rejected' ? '退回' : '',
          approve_time: (approveHistory?.approve_time) ? dayjs(approveHistory?.approve_time).format('YYYY/MM/DD HH:mm:ss'):'',
          approver: approveHistory?.approver_name,
          comment: approveHistory?.comment,
        });
        //if (i === 0) firstRowIndex = row.number;
      }
      if(maxRows>1)
      {
        ['A','B','C','D','E','F','G','H',"K","L","M"].forEach((col) => {
          sheet.mergeCells(`${col}${currentRow}:${col}${currentRow+maxRows-1}`);
        });
        idx++;
      }
      currentRow+=maxRows;
    }
    sheet.eachRow(function(row, rowNumber) {
      row.eachCell(function(cell, colNumber) {
        if(colNumber===10 || colNumber===11 || colNumber===17 && rowNumber>1)
        {
          cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' };
        }
        else
        {
          cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
        }
      });
    });
    await workbook.xlsx.writeBuffer().then(buffer => {
      FileSaver.saveAs(new Blob([buffer]), `請假紀錄-${dayjs().format('YYYY/MM/DD HH:mm:ss')}.xlsx`);
    });

  }

  const setBadge = (status: string) => {
    switch (status) {
      case 'progressing':
        return <Badge borderRadius='4' px='2' w='52px' colorScheme='green'><Center>審核中</Center></Badge>;
      case 'finished':
        return <Badge borderRadius='4' px='2' w='52px' colorScheme='twitter'><Center>已簽核</Center></Badge>;
      case 'rejected':
        return <Badge borderRadius='4' px='2' w='52px' colorScheme='red'><Center>已退回</Center></Badge>;
      case 'canceled':
        return <Badge borderRadius='4' px='2' w='52px' colorScheme='gray'><Center>作廢</Center></Badge>;
      default:
        return <Badge borderRadius='4' px='2' w='52px' colorScheme='gray'><Center></Center></Badge>;
    }
  }
  const getAllClasses = async (dsns: string) => {
    const res = await DSAAbsenceHelper.getSchoolClasses(dsns);    
    setAllClasses(res.class);
  }

  useEffect(() => {
    if(runFilter){
      let temp = LeaveAppAllList.filter((item) => {
        return statusArr[0] && item.status === 'progressing' ||
          statusArr[1] && item.status === 'finished' ||
          statusArr[2] && item.status === 'rejected' ||
          statusArr[3] && item.status === 'canceled';
      });
      temp=temp.filter((item) => {
        return ((gradeYear===0) || (item.leaveStudents[0].gradeYear === gradeYear)) &&
          ((currentClass===0) || (item.leaveStudents[0].classID === currentClass)) &&
          ((keyword==='') || (item.leaveStudents[0].name.indexOf(keyword)>-1) || (item.leaveStudents[0].studentNumber.indexOf(keyword)>-1));
      }); 
      temp.sort((a, b) => {
        let result = 0;
        if(sortParameters[0].sortType!==0)
        {
          if(a.leaveStudents[0].gradeYear < b.leaveStudents[0].gradeYear) result = sortParameters[0].sortType;
          if(a.leaveStudents[0].gradeYear > b.leaveStudents[0].gradeYear) result = -sortParameters[0].sortType;
          if(result!==0) return result;          
        }
        if(sortParameters[1].sortType!==0)
        {
          if(a.leaveStudents[0].className < b.leaveStudents[0].className) result = sortParameters[1].sortType;
          if(a.leaveStudents[0].className > b.leaveStudents[0].className) result = -sortParameters[1].sortType;
          if(result!==0) return result;
        }
        if(sortParameters[2].sortType!==0)
        {
          if(a.leaveStudents[0].studentNumber < b.leaveStudents[0].studentNumber) result = sortParameters[2].sortType;
          if(a.leaveStudents[0].studentNumber > b.leaveStudents[0].studentNumber) result = -sortParameters[2].sortType;
          if(result!==0) return result;
        }
        if(sortParameters[3].sortType!==0)
        {
          if(a.leaveStudents[0].name < b.leaveStudents[0].name) result = sortParameters[3].sortType;
          if(a.leaveStudents[0].name > b.leaveStudents[0].name) result = -sortParameters[3].sortType;
          if(result!==0) return result;
        }
      if(sortParameters[4].sortType!==0)
        {
          if(a.leaveDateAndPeriod[0].d < b.leaveDateAndPeriod[0].d) result = sortParameters[4].sortType;
          if(a.leaveDateAndPeriod[0].d > b.leaveDateAndPeriod[0].d) result = -sortParameters[4].sortType;
          if(result!==0) return result;
        } 
        return result;
      });
      setLeaveAppList(temp);
      setRunFilter(false);      
      setDownloadEnable(temp.length > 0);
    }
  }, [runFilter]);
            
  useEffect(() => {
    getAllClasses(selectedSchool?.dsns ?? '');
  }, []);

  useEffect(() => {
    getLeaveRecord(selectedSchool?.dsns ?? '');
    getAllClasses(selectedSchool?.dsns ?? '');
    setGradeClass([]);
    setCurrentClass(0);
    setGradeYear(0);
  }, [selectedSchool]);


  return (
    <>
      <Box borderWidth='1px' borderRadius='lg' p="6" h='87vh' overflow='hidden'>
        <div className="h-full overflow-y-auto">
          <div className="mb-5">
            <div className="flex items-center gap-3">
              <div>請假日期：</div>
              <div>
                <InputGroup className="items-center">
                  <DatePicker
                    dateFormat="yyyy/MM/dd"
                    // showIcon
                    // toggleCalendarOnIconClick
                    locale="zhTW"
                    calendarStartDay={0}
                    shouldCloseOnSelect={false}
                    className="z-10 relative bg-transparent"
                    selected={startDate}
                    onChange={(date: Date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                  />
                  <CalendarIcon ml={2} color={color} className="p-2 absolute right-0" style={{ boxSizing: 'content-box' }} />
                </InputGroup>
              </div>
              <div>至</div>
              <div>
                <InputGroup className="items-center">
                  <DatePicker
                    dateFormat="yyyy/MM/dd"
                    locale="zhTW"
                    calendarStartDay={0}
                    shouldCloseOnSelect={false}
                    className="z-10 relative bg-transparent"
                    selected={endDate}
                    onChange={(date: Date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                  />
                  <CalendarIcon ml={2} color={color} className="p-2 absolute right-0" style={{ boxSizing: 'content-box' }} />

                  {/* <DatePicker
                    locale={taiwan_zh}  
                  />
                  <InputRightElement>
                    <DatePickerIcon />
                  </InputRightElement> */}
                </InputGroup>
              </div>
              <div className="flex ms-4 gap-2">
                <Button onClick={()=>getLeaveRecord(selectedSchool?.dsns ?? '')} rightIcon={<Search2Icon />} colorScheme='cyan' variant='outline' >
                  搜尋
                </Button>
                <Button onClick={exportLeaveRecord} rightIcon={<DownloadIcon />} colorScheme='cyan' variant='outline' style={{ marginRight: "10px" }} isDisabled={!donwloadEnable}>
                  下載
                </Button>
              </div>
            </div>

            <div className="flex items-center gap-3 mt-4 mb-5">
              <div>篩選：</div>
              {/* <Select placeholder='年級' w='104px' value={gradeYear}  onChange={handleGradeYear()}> */}
              <Select w='104px' value={gradeYear}  onChange={handleGradeYear()}>
                <option value='0'>年級</option>
                <option value='1'>一年級</option>
                <option value='2'>二年級</option>
                <option value='3'>三年級</option>
              </Select>
              <Select w='104px' value={currentClass} onChange={handleCurrentClass()}>
                <option value='0'>班級</option>
                {
                  (gradeClass.length > 0) ? gradeClass.map((item) => {
                    return (
                      <option value={item.classID}>{item.className}</option>
                    )
                  }) : <> </>
                }
              </Select>
              <div className="">或</div>
              <InputGroup w='219px'>
                <Input placeholder='學生姓名或學號關鍵字' onChange={handleKeyword()}/>
                <InputRightElement>
                  <Search2Icon color='gray.400' />
                </InputRightElement>
              </InputGroup>
            </div>

            <div className="flex items-center gap-3">
              <div>審核狀態：</div>
              <Stack spacing={5} direction='row'>
                <Checkbox isChecked={statusArr[0]} colorScheme='cyan' onChange={(e) => handleStatus(0, e.target.checked)}>審核中</Checkbox>
                <Checkbox isChecked={statusArr[1]} colorScheme='cyan' onChange={(e) => handleStatus(1, e.target.checked)}>已簽核</Checkbox>
                <Checkbox isChecked={statusArr[2]} colorScheme='cyan' onChange={(e) => handleStatus(2, e.target.checked)}>已退回</Checkbox>
                <Checkbox isChecked={statusArr[3]} colorScheme='cyan' onChange={(e) => handleStatus(3, e.target.checked)}>作廢</Checkbox>
              </Stack>
            </div>
          </div>
          <TableContainer>
            <Table size='lg' variant='striped'>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th onClick={()=>updateSortParameters(0)}>{(sortParameters[0].sortType===0)? <UpDownIcon/> : (sortParameters[0].sortType===1)? <TriangleDownIcon/> : <TriangleUpIcon/>}年級</Th>
                  <Th onClick={()=>updateSortParameters(1)}>{(sortParameters[1].sortType===0)? <UpDownIcon/> : (sortParameters[1].sortType===1)? <TriangleDownIcon/> : <TriangleUpIcon/>}班級</Th>
                  <Th onClick={()=>updateSortParameters(2)}>{(sortParameters[2].sortType===0)? <UpDownIcon/> : (sortParameters[2].sortType===1)? <TriangleDownIcon/> : <TriangleUpIcon/>}學號</Th>
                  <Th onClick={()=>updateSortParameters(3)}>{(sortParameters[3].sortType===0)? <UpDownIcon/> : (sortParameters[3].sortType===1)? <TriangleDownIcon/> : <TriangleUpIcon/>}姓名</Th>
                  <Th>假單狀態</Th>
                  <Th onClick={()=>updateSortParameters(4)}>{(sortParameters[4].sortType===0)? <UpDownIcon/> : (sortParameters[4].sortType===1)? <TriangleDownIcon/> : <TriangleUpIcon/>}請假日期</Th> 
                  <Th>查看更多</Th>
                </Tr>
              </Thead>
              <Tbody className="cursor-pointer">
                {
                  (()=>{
                    let currCnt = 1;
                    return LeaveAppList.map((item, index) =>
                      item.leaveStudents.map((student,stuIdx) => 
                        (
                          <Tr key={`${index}-${stuIdx}`}>
                            <Td>{currCnt++}</Td>
                            <Td>{student.gradeYear}</Td>
                            <Td isNumeric>{student.className}</Td>
                            <Td isNumeric>{student.studentNumber}</Td>
                            <Td>{student.name}</Td>                            
                            <Td>{setBadge(item.status)}</Td>
                            <Td>{convLeaveDateStr(item.leaveDateAndPeriod)}</Td>
                            <Td onClick={handleDetailOpen(item,student)}>詳細</Td>
                          </Tr>
                        ))
                    )
                  }
                  )()
                }
              </Tbody>
            </Table>
          </TableContainer>
        </div>
      </Box>
      <LeaveAppModal selectedLeaveApp={selectedLeaveApp} selectedLeaveStu={selectedLeaveStu} isModalOpen={isModalOpen} onModalClose={onModalClose} /> 
    </>
  )
}