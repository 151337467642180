import { AbsenceRecordInfo } from "./value_object";

/** 取得校務系統的資訊  */
export class DSAAbsenceHelper {

    /** 取得登入者的校務系統中定義的假別清單 */
    static async getAbsenceTypes() {
        const resp = await fetch('/service/devapi_leave/get_absence_types');
        return await resp.json();
    }

    /** 以dsns取得校務系統中定義的假別清單 */
    static async getAbsenceTypesByDSNS(dsns:string) {
        const resp = await fetch('/service/devapi_leave/get_absence_types_dsns?schooldsns='+dsns);
        try 
        {
            if (!resp.ok) {
                throw new Error(resp.statusText);
            }            
            const result= await resp.json();
            return result;
        }
        catch (ex) {
         //   console.log("發生錯誤:" + { ex });
         //   return ex;
        }

    }
    /** 取得登入者的校務系統中定義的節次清單 */
    static async getPeriods() {
        const resp = await fetch('/service/devapi_leave/get_periods');
        return await resp.json();
    }

    /** 取得登入者的校務系統中定義的節次清單 */
    static async getPeriodsByDSNS(dsns:string) {
        const resp = await fetch('/service/devapi_leave/get_periods_dsns?schooldsns='+dsns);
        try 
        {
            if (!resp.ok) {
                throw new Error(resp.statusText);
            }            
            const result= await resp.json();
            return result;
        }
        catch (ex) {
         //   console.log("發生錯誤:" + { ex });
         //   return ex;
        }
    }
    


    /** 取得登入者(學生或家長)在校務系統中，有缺曠紀錄的學年期清單 */
    static async getAbsenceSemesters() {
        const resp = await fetch('/service/devapi_leave/student/get_absence_semesters');
        return await resp.json();
    }

    /** 取得登入者(學生或家長)在校務系統中，在指定學年期的缺曠紀錄 */
    static async getAbsenceRecords(schoolYear: number | undefined, semester: number | undefined): Promise<AbsenceRecordInfo[]> {
        const resp = await fetch(`/service/devapi_leave/student/get_absence_records?schoolYear=${schoolYear}&semester=${semester}`);
        return await resp.json();
    }

    /** 取得登入者(學生或家長)的教師資料 */
    static async getHomeroomTeacher() {
        const resp = await fetch(`/service/devapi_leave/student/get_homeroom_teacher`);
        return await resp.json();
    }

    /** 取得登入者(教師)的全校學生清單 */
    static async getSchoolStudents() {
        const resp = await fetch(`/service/devapi_jasmine/teacher/get_all_students`);
        return await resp.json();
    }


    /** 取得登入者(教師)的全校班級清單 */
    static async getAllClasses(dsns:string) {
        if(dsns === undefined) {
            dsns = '';
        }
        const resp = await fetch(`/service/devapi_jasmine/teacher/get_all_classes?dsns=${dsns}`);
        return await resp.json();
    }

    /** 取得登入者(管理者)的全校班級清單 */
    static async getSchoolClasses(dsns:string) {
        if(dsns === undefined) {
            dsns = '';
        }
        const resp = await fetch(`/service/devapi_jasmine/admin/get_all_classes?dsns=${dsns}`);
        return await resp.json();
    }


    /** 取得登入者(管理者)的全校教師清單 */
    static async getSchoolTeachers(schooldns:string) {
        const resp = await fetch(`/service/devapi_jasmine/admin/get_all_teachers?schoolDsns=${schooldns}`);
        try 
        {
            if (!resp.ok) {
                throw new Error(resp.statusText);
            }            
            const result= await resp.json();
            return result;
        }
        catch (ex) {
         //   console.log("發生錯誤:" + { ex });
         //   return ex;
        }
    }

}