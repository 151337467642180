export class ApprovalClassesHelper {

    /** 取得使用者關懷班級 */
    static async getApprovalClasses(): Promise<any> {
        const resp = await fetch(`/service/approval_classes/get_approval_classes`);
        return resp.json();
    }
    /** 更新使用者關懷班級 */
    static async updateApprovalClasses(classes:string[]): Promise<any> {              
        const resp = await fetch(`/service/approval_classes/update_approval_classes`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ classes }),
        });
        return resp.json();
    }

}