import { LeaveApplicationRecord } from "./value_object";


export class ManagerLeaveHelper {

    /** 依據請假日期狀態,取得請假單 */

    static async get(applyTime1: string, applyTime2: string, dsns: string): Promise<LeaveApplicationRecord[]> {
        const resp = await fetch(`/service/manager_leave_helper/getByApplyTime?apply_time1=${applyTime1}&apply_time2=${applyTime2}&schooldsns=${dsns}`);
        //console.log("呼叫Service : /service/manager_leave_helper/getByApplyTime")
        const result : LeaveApplicationRecord[] = await resp.json()
        return await result;
    }

    static async get_by_leave_date(applyTime1: string, applyTime2: string, dsns: string): Promise<LeaveApplicationRecord[]> {
        const resp = await fetch(`/service/manager_leave_helper/getByLeaveDate?leave_date1=${applyTime1}&leave_date2=${applyTime2}&schooldsns=${dsns}`);
        //console.log("呼叫Service : /service/manager_leave_helper/getByApplyTime")
        const result : LeaveApplicationRecord[] = await resp.json()
        return await result;
    }
}