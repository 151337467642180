import { Box, Tag } from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";

import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Util } from "../dal/util";
import { ToggleString } from "./ToggleString";
import { useTranslation } from "react-i18next";

/** 教師審核過的請假單畫面顯示項目 */
export function TeacherApproveHistoryItem({
  leaveApplicationRecord, handleSelectedLeaveApp, handleShowDetail, currentSelectedLappID
}: {
  leaveApplicationRecord: any;
  handleSelectedLeaveApp: ((data: any) => void);
  handleShowDetail: ((date: boolean) => void);
  currentSelectedLappID: any
}) {
  const { t, i18n } = useTranslation("translation");
  const [year, setYear] = useState<string>();
  const [bd, setBD] = useState<string>(); // 開始日期
  const [ed, setED] = useState<string>(); // 結束日期
  const [isManyDates, setIsManyDates] = useState<boolean>(false); // 這張假單請假日數是否多日天（還是單日）
  const [periodCount, setPeriodCount] = useState<number>(0); // 請假總節數
  const emptyObj = {
    status: "",
    progressText: "",
    progressTextEn: "",
    progressTextCSS: "",
    statusText: "",
    statusTextEn: "",
    statusTextCSS: "",
  };
  const [otherData, setOtherData] = useState<any>(emptyObj);
  useEffect(() => {
    const leaveDatePeriods: any[] = leaveApplicationRecord.leaveDateAndPeriod;
    setIsManyDates(leaveDatePeriods.length > 1);
    const firstDate = dayjs(leaveDatePeriods[0].d);
    const lastDate = dayjs(leaveDatePeriods[leaveDatePeriods.length - 1].d);
    setYear(firstDate.year().toString());
    setBD(firstDate.format("MM/DD"));
    setED(lastDate.format("MM/DD"));
    let pCount = 0;
    leaveDatePeriods.forEach((lp) => {
      pCount += lp.periods.length;
    });
    // const pCount = leaveDatePeriods.reduce( (result, lp) => {
    //     return result + lp.periods.length ;
    // });
    setPeriodCount(pCount);

    const od = Util.calculateProgressText(leaveApplicationRecord);
    setOtherData(od);
  }, [leaveApplicationRecord]);

  return (
    <div className="flex items-baseline gap-2 p-2" style={(leaveApplicationRecord.id === currentSelectedLappID) ? { backgroundColor: '#F3F4F6' } : { backgroundColor: '#FFFFFF' }}>
      <Tag
        size="sm"
        className={`${otherData.progressTextCSS} !text-white px-1 justify-center flex-shrink-0 whitespace-nowrap`}
      >
        {i18n.language === "en" ? otherData.progressTextEn : otherData.progressText}
      </Tag>
      <div className="cursor-pointer" onClick={() => { handleSelectedLeaveApp(leaveApplicationRecord); handleShowDetail(true); }}>
        <div className="flex items-baseline gap-x-2">
          <div className="text-xl">{leaveApplicationRecord.leaveStudents[0]?.studentNumber}</div>
          <div className="text-xl font-semibold">{leaveApplicationRecord.leaveStudents[0]?.className} {leaveApplicationRecord.leaveStudents[0]?.name}</div>
          {(leaveApplicationRecord.leaveStudents[0]?.length > 1) && (
            <div className='text-xl font-semibold'> ... {t("app.more")}</div>
          )}
        </div>
        <div className="text-left" >
          <div className={`${i18n.language === "en" ? "grid" : "flex" } items-baseline gap-x-3 `}>
            <span className="font-semibold flex-shrink-0 ">{t("app.leave_dates")}</span>
            {dayjs(leaveApplicationRecord.leaveDateAndPeriod[0].d).format('YYYY/MM/DD')}
            {leaveApplicationRecord.leaveDateAndPeriod.length > 1 && (
              <>
                {` ~ `}{dayjs(leaveApplicationRecord.leaveDateAndPeriod[leaveApplicationRecord.leaveDateAndPeriod.length - 1].d).format('MM/DD')} ({leaveApplicationRecord.leaveDateAndPeriod.length}{t("app.day")})
              </>
            )}
          </div>
          <div className={`${i18n.language === "en" ? "grid" : "flex" } items-baseline gap-x-3 `}>
            <span className="font-semibold flex-shrink-0">{t("app.category_session")}</span>
            {leaveApplicationRecord.absence_type} {leaveApplicationRecord.leaveDateAndPeriod.reduce((acc: number, current: any) => acc + current.periods.length, 0)} {t("app.session")}
          </div>
          <div className={`${i18n.language === "en" ? "grid" : "flex" } items-baseline gap-x-3 `}>
            <div className="font-semibold flex-shrink-0">
              {t("app.leave_reason_4w")}
            </div>
            <ToggleString toggleStr={leaveApplicationRecord.reason} />

          </div>
          {(leaveApplicationRecord.status === 'rejected') && (
            <div className={`${i18n.language === "en" ? "grid" : "flex" } items-baseline gap-x-3 `}>
              <span className="font-semibold flex-shrink-0">
                {t("app.not_approve")}
              </span>
              {/* { leaveApplicationRecord.current_stage_name } */}
              {i18n.language === "en" ? Util.convertRoleToTextEn(leaveApplicationRecord.approveHistory.find((stage: any) => stage.status === 'rejected').stage_name) : leaveApplicationRecord.approveHistory.find((stage: any) => stage.status === 'rejected').stage_name}
            </div>
          )}
          {(leaveApplicationRecord.status === 'finished') && (
            <div className={`${i18n.language === "en" ? "grid" : "flex" } items-baseline gap-x-3 `}>
              <span className="font-semibold flex-shrink-0">
                {t("app.completed_on")}
              </span>
              {dayjs(leaveApplicationRecord.last_update).format('YYYY/MM/DD')}
            </div>
          )}
          {(leaveApplicationRecord.status === 'progressing') && (
            <div className={`${i18n.language === "en" ? "grid" : "flex" } items-baseline gap-x-3 `}>
              <span className="font-semibold flex-shrink-0">
                {t("app.wait_approved")}
              </span>
              {i18n.language === "en" ? Util.convertRoleToTextEn(leaveApplicationRecord.current_stage_name) : leaveApplicationRecord.current_stage_name}
            </div>
          )}
          {/* <div>
              <span
                className="font-semibold w-16"
                style={{ textAlignLast: "justify" }}
              >
                未核准
              </span>
              學務主任
            </div> */}
        </div>
      </div>
    </div>
  );
}
