import { SchoolDate, SchoolDateConfig } from "./value_object";

/** 學校定義每學期上課日 */
export class SchoolDateHelper {

    static async getDatesBySemester(schoolYear: number | undefined = undefined, semester: number | undefined = undefined): Promise<SchoolDate[]> {
        const resp = await fetch(`/service/school_date/get_dates_by_semester?${schoolYear ? `schoolYear=${schoolYear}` : ''}&${semester ? `semester=${semester}` : ''}`);
        return await resp.json();
    }

    static async getDatesForAllSemester(dsns:string): Promise<SchoolDateConfig[]> {
        const resp = await fetch(`/service/school_date/get_dates_forall_semester?schooldsns=${dsns}`);
        return await resp.json();
    }

    static async addSchoolDate(schoolYear: number, semester: number, begin_date: string, end_date: string, dates: {d:string,w:number}[]) {
        const dateString = JSON.stringify(dates);
        const resp = await fetch(`/service/school_date/add_dates_semester`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ schoolYear, semester, begin_date, end_date, dates:dateString })
        });
        return await resp.json();
    }

    static async updateSchoolDate(id: string, schoolYear: number, semester: number, begin_date: string, end_date: string, dates: {d:string,w:number}[]) {
        const dateString = JSON.stringify(dates);
        const resp = await fetch(`/service/school_date/update_dates_semester`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id, schoolYear, semester, begin_date, end_date, dates:dateString })
        });
        return await resp.json();
    }



}