import { IconButton, Select, useColorMode } from "@chakra-ui/react"
import { Outlet } from "react-router-dom"
import { ManagerMenuPage } from "./ManagerMenuPage"
import { useEffect, useState, useRef } from "react"
import { MoonIcon, SunIcon } from "@chakra-ui/icons"
import { SystemHelper } from "../../dal/system_helper"
import { useUserContext } from "../../components/auth/user-validation"
import { SchoolProvider, useSchoolContext } from "../../dal/SchoolContext" 
import { school } from "../../dal/value_object"

const ManagerIndexContent = () => {

  const { toggleColorMode, colorMode } = useColorMode();
  const [selectedTab, setSelectedTab] = useState();
  const [schools, setSchools] = useState<school[]>([]);
  //const [selectedSchool, setSelectedSchool] = useState<school>();
  const {selectedSchool, setSelectedSchool} = useSchoolContext();
  const { userInfo } = useUserContext();
  
  const outletRef=useRef<any>(null);
  
  const handleSelectSchool = (e: any) => {
    const schoolData = e.target.value.split("-");
    const school = schools.find((item) => item.dsns === schoolData[0]);
    setSelectedSchool(school || null);
    //setSelectedSchool(school);
    setSelectedTab(schoolData[0]);
  }


  // 載入假單資料
  useEffect(() => {
    loadData();
  }, [selectedTab]);

  const loadData = async () => {
    const data = await SystemHelper.getAllSchools();
    setSchools(data);
    if(userInfo && !userInfo.isSystemAdmin && userInfo.isAdmin){
      setSelectedSchool(
        {
          id:'',
          dsns: userInfo.schoolDsns,
          school_name: userInfo.schoolName
        }
      )
    }
  }

  return (
    <>
      <div className="w-full" style={{ boxShadow: '0 1px 2px 0 #c6c6c642' }}>
        <div className="flex justify-between w-full max-w-[1488px] text-xl mx-auto p-4">
          <div className="w-72 mt-2">線上請假-管理者功能</div>
          {
            userInfo &&  userInfo.isSystemAdmin &&
            <Select 
              placeholder='選擇學校' 
              value={selectedSchool?.dsns+"-"+selectedSchool?.school_name}
              onChange={handleSelectSchool}            
            >
              {schools?.length > 0 && schools.map((item) => (
                <option key={item.id} value={item.dsns+"-"+item.school_name} >
                  {item.dsns}-{item.school_name}
                </option>
              ))}
            </Select>
          }
          <IconButton
            className="ml-5"
            aria-label="toggle theme"
            rounded="full"
            size="xs"
            onClick={toggleColorMode} icon={colorMode === "dark" ? <SunIcon /> : <MoonIcon />}
          />
        </div>
      </div>
      <div className="flex w-full max-w-[1488px] mx-auto p-4 mt-4">
        <div className="w-72 overflow-y-auto h-[85vh] shrink-0">
          <ManagerMenuPage/>
        </div>
        <div ref={outletRef} className="max-w-[1200px] flex-grow">
          <Outlet/>
        </div>
      </div>
    </>
  )
}
export const ManagerIndex = () => (
  <SchoolProvider>
    <ManagerIndexContent />
  </SchoolProvider>
);