import { LeaveApplicationRecord } from "./value_object";


export class SchoolApprovalHelper {

    /** 取得所有請假流程 */
    static async getAllFlows(dsns: string): Promise<any[]> {
        const resp = await fetch(`/service/school_approval_flow/get_all_flows?dsns=${dsns}`);
        return resp.json() || [];
    }

    /** 更新請假流程 */
    static async updateApprovalFlow(id: string, stage_no: number, stage_name: string, approver_type:string,min_count: number, max_count: number, approvers_info: string): Promise<any> {
        const resp = await fetch(`/service/school_approval_flow/update_approval_flow`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id, stage_no, stage_name, approver_type, min_count, max_count, approvers_info }),
        });
        return resp.json();
    }

    /** 新增請假流程 */
    static async addApprovalFlow(stage_no: number, stage_name: string, approver_type:string,min_count: number, max_count: number, approvers_info: string): Promise<any> {
        const resp = await fetch(`/service/school_approval_flow/add_approval_flow`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ stage_no, stage_name, approver_type, min_count, max_count, approvers_info }),
        });
        return resp.json();
    }
    /** 刪除請假流程 */
    static async deleteApprovalFlow(id:string): Promise<any> {
        const resp = await fetch(`/service/school_approval_flow/delete_approval_flow`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id }),
        });
        return resp.json();
    }

}