import { createBrowserRouter } from "react-router-dom";
// import { IndexMenu } from "./pages";
import { AbsentOff } from "./pages/StudentPage/AbsentOff";
import { StudentPage } from "./pages/StudentPage";
import Root from "./pages/root";
import RouteErrorPage from "./pages/routeerrorpage";
import NotSigninPage from "./components/auth/not-signin-page";
import MenuPage from "./pages/menu";
import { TeacherPage } from "./pages/TeacherPage";
import { OfficialDetail } from "./pages/TeacherPage/OfficialDetail";
import { Absent } from "./pages/StudentPage/Absent";
import { InAdvance } from "./pages/StudentPage/InAdvance";
import { LeaveDetail } from "./pages/StudentPage/LeaveDetail";
import { StudentMenu } from "./pages/StudentPage/StudentMenu";
import { LeaveList } from "./pages/StudentPage/LeaveList";
import { Absence } from "./pages/StudentPage/Absence";
import { AbsentAdd } from "./pages/StudentPage/Absence/AbsenceAdd";
import { ManagerIndex } from "./pages/ManagerPage";
import { RecordPage } from "./pages/ManagerPage/RecordPage";
import { ClassdayPage } from "./pages/ManagerPage/ClassdayPage";
import { ProcessPage } from "./pages/ManagerPage/ProcessPage";
import { AbsentTypePage } from "./pages/ManagerPage/AbsentTypePage";
import { OtherPage } from "./pages/ManagerPage/OtherPage";
import { TimePeriodPage } from "./pages/ManagerPage/TimePeriodPage";
import { TeacherApprove } from "./pages/TeacherPage/TeacherApprove";
import { TeacherMenu } from "./pages/TeacherPage/TeacherMenu";
import { SetAdminPage } from "./pages/ManagerPage/SetAdminPage";

export const useRouter = () => {

  return createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      errorElement: <RouteErrorPage />,
      children: [
        {
          path: 'student',
          element: <StudentPage />,
          children: [
            {
              path: '',
              element: <StudentMenu />
            },
            {
              path: 'absence',
              element: <Absence />,
            },
            {
              path: 'absence/add',
              element: <AbsentAdd />
            },
            {
              path: 'inadvance',
              element: <InAdvance />
            },
            {
              path: 'history',
              element: <LeaveList />
            },
          ]
        },

        {
          path: 'menu',
          element: <MenuPage />
        },
        {
          path: 'auth/not-signin',
          element: <NotSigninPage />
        },
        {
          path: 'teacher',
          element: <TeacherPage />,
          children: [
            {
              path: '',
              element: <TeacherApprove />
            },
            {
              path: 'menu',
              element: <TeacherMenu />,
            },
          ]
        },
        {
          path: 'manager',
          element: <ManagerIndex />,
          children: [
            {
              path: '',
              element: <RecordPage />
            },
            {
              path: 'record',
              element: <RecordPage />
            },
            {
              path: 'classday',
              element: <ClassdayPage />
            },
            {
              path: 'process',
              element: <ProcessPage />
            },
            {
              path: 'absenttype',
              element: <AbsentTypePage />
            },
            {
              path: 'time',
              element: <TimePeriodPage />
            },
            {
              path: 'other',
              element: <OtherPage />
            },
            {
              path: 'setadmin',
              element: <SetAdminPage />
            },
          ]
        },



      ]
    }
  ]);
}